import { APP_ID, Inject, NgModule, PLATFORM_ID, PlatformRef } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { DecimalPipe, isPlatformBrowser } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TransferHttpCacheModule } from "@nguniversal/common";

import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { CookieService } from 'ngx-cookie-service';

import { AppRoutingModule } from './app-routing.module';

import { ErrorInterceptor } from './shared/interceptor/error.interceptor';

import { TranslatePipe } from './pipes/translate.pipe';
import { SafeHtmlPipe } from './pipes/safeHtml.pipe';

import { BasketService } from './services/basket/basket.service';
import { ModalService } from './services/modal.service';
import { MetaTagService } from './services/metaTag.service';
import { PageService } from './services/page/page.service';
import { UrlService } from './services/url.service';
import { PriceService } from './services/price/price.service';
import { CurrencyService } from './services/currency.service';
import { MenuService } from './services/menu/menu.service';
import { DictionaryService } from './services/dictionary.service';
import { CMSService } from './services/CMS/cms.service';
import { LanguageService } from './services/language/language.service';

import { PanelListModule } from './components/panelList/panelList.module';
import { TableModule } from './components/tables/table.module';
import { SearchDomainsModule } from './components/searchDomains/searchDomains.module';
import { HeaderModule } from './components/header/header.module';
import { SharedModule } from './shared/shared.module';

import { LanguageResolver } from './shared/resolvers/language.resolver';
import { DictionaryResolver } from './shared/resolvers/dictionary.resolver';
import { ContentResolver } from './components/content/content.resolver';
import { ContentCategoryResolver } from './components/content/contentCategory/contentCategory.resolver';
import { TopLevelDomainResolver } from './components/content/topLevelDomain/topLevelDomain.resolver';
import { SearchDomainsResolver } from './components/searchDomains/searchDomains.resolver';

import { AppComponent } from './components/app/app.component';
import { HomeComponent } from './components/home/home.component';
import { FooterComponent } from './components/footer/footer.component';
import { PromoSliderComponent } from './components/home/promoSlider/promoslider.component';
import { ContentContainerComponent } from './components/content/contentContainer.component';
import { TextContentComponent } from './components/content/textContent/textContent.component';
import { ContentCategoryComponent } from './components/content/contentCategory/contentCategory.component';
import { SideMenuComponent } from './components/sideMenu/sideMenu.component';
import { TopLevelDomainComponent } from './components/content/topLevelDomain/topLevelDomain.component';
import { AllTldsTableComponent } from './components/content/allTlds/allTldsTable.component';
import { ContentComponent } from './components/content/contentComponent/content.component';
import { AllTldsComponent } from './components/content/allTlds/allTlds.component';
import { NewTldsComponent } from './components/content/newTlds/newTlds.component';
import { SwitchComponent } from './components/switch/switch.component';
import { PanelItemComponent } from './components/panelItem/panelItem.component';
import { LinkPanelItemComponent } from './components/panelItem/linkPanelItem/linkPanelItem.component';
import { NewsPanelComponent } from './components/home/newsPanel/newsPanel.component';
import { WhoIsPanel } from './components/home/whoIsPanel/whoIsPanel.component';
import { InfoPanelComponent } from './components/content/topLevelDomain/infoPanel/infoPanel.component';
import { ResellerInformationComponent } from './components/content/topLevelDomain/resellerInformation/resellerInformation.component';
import { SelectComponent } from './components/select/select.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { CheckoutTableRowComponent } from './components/checkout/checkoutTableRow/checkoutTableRow.component';
import { NewsPanelRow } from './components/home/newsPanel/newsPanelRow/newsPanelRow.component';
import { MainSliderComponent } from './components/home/main-slider/main-slider.component';
import { PageNotFoundComponent } from './components/error/pageNotFound/pageNotFound.component';
import { InternalServerErrorComponent } from './components/error/internalServerError/internalServerError.component';
import { NewTLDsTableComponent } from './components/content/newTlds/newTldsTable.component';
import { CustomerServiceComponent } from './components/content/customerService/customerService.component';
import { PunycodeConverterComponent } from './components/content/punycodeConverter/punycodeConverter.component';
import { WhoisLookupComponent } from './components/content/whoisLookup/whoisLookup.component';
import { WhoisSearchComponent } from './components/whoisSearch/whoisSearch.component';
import { BugReportComponent } from './components/bugReport/bugReport.component';

@NgModule({
    imports: [
        BrowserModule.withServerTransition({ appId: 'nameisp' }),
        TransferHttpCacheModule,
        FormsModule,
        AppRoutingModule,
        HttpClientModule,
        PanelListModule,
        TableModule,
        SearchDomainsModule,
        HeaderModule,
        SharedModule,
        ModalModule.forRoot(),
        TooltipModule.forRoot(),
        CollapseModule.forRoot()
    ],
    declarations: [
        AppComponent,
        HomeComponent,
        FooterComponent,
        TranslatePipe,
        PromoSliderComponent,
        ContentContainerComponent,
        TextContentComponent,
        ContentCategoryComponent,
        SideMenuComponent,
        TopLevelDomainComponent,
        AllTldsTableComponent,
        ContentComponent,
        AllTldsComponent,
        NewTldsComponent,
        SwitchComponent,
        PanelItemComponent,
        LinkPanelItemComponent,
        NewsPanelComponent,
        WhoIsPanel,
        InfoPanelComponent,
        ResellerInformationComponent,
        SelectComponent,
        BreadcrumbComponent,
        CheckoutComponent,
        CheckoutTableRowComponent,
        NewsPanelRow,
        MainSliderComponent,
        PageNotFoundComponent,
        InternalServerErrorComponent,
        NewTLDsTableComponent,
        SafeHtmlPipe,
        CustomerServiceComponent,
        PunycodeConverterComponent,
        WhoisLookupComponent,
        WhoisSearchComponent,
        BugReportComponent
    ],
    providers: [
        DictionaryService,
        CMSService,
        LanguageService,
        LanguageResolver,
        DictionaryResolver,
        ContentResolver,
        ContentCategoryResolver,
        MenuService,
        TopLevelDomainResolver,
        UrlService,
        PriceService,
        CurrencyService,
        SearchDomainsResolver,
        CookieService,
        BasketService,
        DecimalPipe,
        ModalService,
        MetaTagService,
        PageService,
        SafeHtmlPipe,
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
    ],
    entryComponents: [
        TextContentComponent,
        ContentCategoryComponent,
        AllTldsComponent,
        NewTldsComponent,
        CustomerServiceComponent,
        PunycodeConverterComponent,
        WhoisLookupComponent
    ],
    bootstrap: [AppComponent],
})
export class AppSharedModule {
    constructor() {

    }
}
