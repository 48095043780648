/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./popover.component";
import * as i3 from "../../services/dictionary.service";
var styles_PopoverComponent = [];
var RenderType_PopoverComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PopoverComponent, data: {} });
export { RenderType_PopoverComponent as RenderType_PopoverComponent };
export function View_PopoverComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "popover popover-alert cart-confirmation bottom fade"], ["role", "dialog"]], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(2, { in: 0, hide: 1 }), (_l()(), i0.ɵeld(3, 0, null, null, 0, "div", [["class", "arrow"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 6, "div", [["class", "popover-inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 3, "div", [["class", "popover-title"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "button", [["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00D7"])), i0.ɵncd(null, 0), (_l()(), i0.ɵeld(9, 0, null, null, 1, "div", [["class", "popover-content"]], null, null, null, null, null)), i0.ɵncd(null, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "popover popover-alert cart-confirmation bottom fade"; var currVal_1 = _ck(_v, 2, 0, _co.show, !_co.active); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_PopoverComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "popover", [], null, null, null, View_PopoverComponent_0, RenderType_PopoverComponent)), i0.ɵdid(1, 573440, null, 0, i2.PopoverComponent, [i3.DictionaryService], null, null)], null, null); }
var PopoverComponentNgFactory = i0.ɵccf("popover", i2.PopoverComponent, View_PopoverComponent_Host_0, { openEvent: "open", show: "show", active: "active" }, {}, ["[header]", "[body]"]);
export { PopoverComponentNgFactory as PopoverComponentNgFactory };
