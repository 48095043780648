<div class="panel" [ngClass]="getPanelClass(domain)">
    <header class="panel-header"></header>
    <div class="panel-body search-result-single">
        <h1 [ngClass]="getTextClass(domain)">
            <i [ngClass]="getGlyph(domain)"></i>
            {{domainText()}}
        </h1>
        <div class="search-result-single-controls" *ngIf="domain && !domain.invalid">
            <span class="price">{{domain?.price | number:"1.2-2"}} {{currency.current | async}}</span>
            <div class="btn-group">
                <div class="whois btn-group hidden-xs hidden-sm" *ngIf="domain.available === false">
                    <button class="btn btn-link btn-lg" tooltip="Whois Lookup" (click)="openWhoisModal()">
                        <i class="glyph-whois"></i>
                        <span class="hidden-md hidden-lg">{{dictionary?.Search_Domains_Whois}}</span>
                    </button>
                </div>
                <add-to-cart-button [domain]="domain"></add-to-cart-button>
            </div>
        </div> 
    </div>
</div>