import { LanguageResolver } from "../../shared/resolvers/language.resolver";
import { LanguageService } from "../../services/language/language.service";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { Injectable } from "@angular/core";
import { Location } from "@angular/common";

@Injectable()
export class SearchDomainsResolver extends LanguageResolver {
    constructor(languageService: LanguageService, router: Router, protected location: Location) {
        super(languageService, router, location);
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        super.resolve(route, state);
    }
}