import { Component, Input } from "@angular/core";
import { DescriptionList } from "../descriptionList.data";

@Component({
    selector: "reseller-information",
    templateUrl: "./resellerInformation.component.html"
})
export class ResellerInformationComponent {
    @Input()
    header: string;
    @Input()
    sections: DescriptionList[]

    public isCollapsed = true;
}

