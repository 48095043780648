
<div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
    <h2 class="modal-title" id="myModalLabel">{{title}}</h2>
</div>
<div class="modal-body">
    <ng-content select="[body]"></ng-content>
</div>
<div class="modal-footer">
    <ng-content select="[footer]"></ng-content>
</div>