import { Component, Input, OnInit } from "@angular/core";
import { Domain, getStateFunction, LoadableDomain } from "../domain.interface";
import { BasketService } from "../../../services/basket/basket.service";
import { CurrencyService } from "../../../services/currency.service";
import { domainStates } from "../domainStates.constant";
import { DictionaryService } from "../../../services/dictionary.service";
import { ModalService } from "../../../services/modal.service";


@Component({
    selector: "search-domains-promo",
    templateUrl: "./searchDomainsPromo.component.html"
})
export class SearchDomainsPromoComponent implements OnInit {
    @Input()
    domain?: LoadableDomain;
    loadedDomain?: Domain;

    getPromoClass: (domain?: Domain) => string;
    clickFunc: (domain?: Domain) => Function;
    getAddedToCartText: (domain?: Domain) => string = () => "";

    constructor(private basketService: BasketService, private modalService: ModalService, public currency: CurrencyService, dictionaryService: DictionaryService) {
        this.getPromoClass = getStateFunction(domainStates.promoClass);
        this.clickFunc = getStateFunction(domainStates.addToCartOnClick(basketService, modalService));

        dictionaryService.getDictionary().subscribe(dictionary => {
            let addedToCartText = {
                available: dictionary["Add_To_Cart_Button"],
                unavailable: dictionary["Add_To_Cart_Button"],
                inCart: dictionary["Added_To_Cart_Button"],
                transferInCart: dictionary["Added_To_Cart_Button"]
            }
            this.getAddedToCartText = getStateFunction(addedToCartText)
        });
    }

    ngOnInit() {
        if (this.domain) this.domain.domain.subscribe(dom => this.loadedDomain = dom)
    }

    onClicked() {
        if (!this.loadedDomain) return;
        this.clickFunc(this.loadedDomain)(this.loadedDomain, this.loadedDomain.priceSEK);

        this.loadedDomain.inCart = !this.loadedDomain.inCart
    }
}