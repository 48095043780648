<div class="table-filter-group tld-table">
    
    <ng-content select="table-controls"></ng-content>
    
    <div class="table-container table-responsive table-filter-table">
        <table class="table">
            <ng-content select="thead"></ng-content>
            <ng-content select="tbody"></ng-content>
        </table>
    </div> 
    <ng-content select="pagination"></ng-content>
</div>