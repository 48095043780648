<h1>{{contentCategory?.pageTitle}}</h1>
    <div innerHtml="{{contentCategory?.bodyText}}"></div>
    <panel-list class="row same-height gap-3">
        <link-panel-item *ngFor="let panel of contentCategory?.panelItems"
                    [header]="panel.customProperties.title"
                    [body]="panel.customProperties.body"
                    link="/{{languageService.current}}/{{panel.customProperties.link.url}}"
                    [footerText]="panel.customProperties.linkTitle" >
        </link-panel-item>
    </panel-list>
