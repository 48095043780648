/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@angular/router";
import * as i3 from "./breadcrumb.component";
import * as i4 from "../../services/language/language.service";
import * as i5 from "../../services/menu/menu.service";
import * as i6 from "../../services/dictionary.service";
var styles_BreadcrumbComponent = [];
var RenderType_BreadcrumbComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_BreadcrumbComponent, data: {} });
export { RenderType_BreadcrumbComponent as RenderType_BreadcrumbComponent };
function View_BreadcrumbComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" \u00BB "])), (_l()(), i0.ɵeld(2, 0, null, null, 5, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 5).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), i0.ɵpod(4, { "disabled": 0 }), i0.ɵdid(5, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i1.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 4, 0, (_v.context.index === (_co.links.length - 1))); _ck(_v, 3, 0, currVal_2); var currVal_3 = i0.ɵinlineInterpolate(2, "/", _co.languageService.current, "/", _v.context.$implicit.link, ""); _ck(_v, 5, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 5).target; var currVal_1 = i0.ɵnov(_v, 5).href; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_4 = ((_co.dictionary && _co.dictionary.hasOwnProperty(_v.context.$implicit.name)) ? _co.dictionary[_v.context.$implicit.name] : _v.context.$implicit.name); _ck(_v, 7, 0, currVal_4); }); }
export function View_BreadcrumbComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "breadcrumbs container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "span", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 5, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i1.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 0, "i", [["class", "glyph-home"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00A0"])), (_l()(), i0.ɵeld(6, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Home"])), (_l()(), i0.ɵeld(8, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BreadcrumbComponent_1)), i0.ɵdid(10, 802816, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = i0.ɵinlineInterpolate(1, "/", _co.languageService.current, ""); _ck(_v, 3, 0, currVal_2); var currVal_3 = _co.links; _ck(_v, 10, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 3).target; var currVal_1 = i0.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_0, currVal_1); }); }
export function View_BreadcrumbComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "breadcrumb", [], null, null, null, View_BreadcrumbComponent_0, RenderType_BreadcrumbComponent)), i0.ɵdid(1, 573440, null, 0, i3.BreadcrumbComponent, [i4.LanguageService, i5.MenuService, i6.DictionaryService], null, null)], null, null); }
var BreadcrumbComponentNgFactory = i0.ɵccf("breadcrumb", i3.BreadcrumbComponent, View_BreadcrumbComponent_Host_0, { contentId: "contentId", links: "links" }, {}, []);
export { BreadcrumbComponentNgFactory as BreadcrumbComponentNgFactory };
