import { enableProdMode } from '@angular/core';
import "./polyfills";
import "./app/assets/images/favicon.png";
import "../node_modules/bootstrap/js/dropdown.js";
import * as __NgCli_bootstrap_1 from "./app/app.browser.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
var config = window["config"];
if (config.ENVIRONMENT !== "development") {
    enableProdMode();
}
document.addEventListener("DOMContentLoaded", function () {
    __NgCli_bootstrap_2.platformBrowser()
        .bootstrapModuleFactory(__NgCli_bootstrap_1.AppBrowserModuleNgFactory)
        .catch(function (err) { return console.log(err); });
});
