/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../shared/directives/clickOutside.directive";
import * as i2 from "@angular/common";
import * as i3 from "../megaMenu/megaMenu.component.ngfactory";
import * as i4 from "../megaMenu/megaMenu.component";
import * as i5 from "../../../services/CMS/cms.service";
import * as i6 from "../../../services/language/language.service";
import * as i7 from "../../../services/menu/menu.service";
import * as i8 from "../../../services/url.service";
import * as i9 from "./search/headerSearch.component.ngfactory";
import * as i10 from "./search/headerSearch.component";
import * as i11 from "@angular/router";
import * as i12 from "../../../services/dictionary.service";
import * as i13 from "../../../services/modal.service";
import * as i14 from "./shoppingCartButton/shoppingCartButton.component.ngfactory";
import * as i15 from "./shoppingCartButton/shoppingCartButton.component";
import * as i16 from "../../../services/basket/basket.service";
import * as i17 from "./headerPrimary.component";
var styles_HeaderPrimaryComponent = [];
var RenderType_HeaderPrimaryComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_HeaderPrimaryComponent, data: {} });
export { RenderType_HeaderPrimaryComponent as RenderType_HeaderPrimaryComponent };
export function View_HeaderPrimaryComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 17, "div", [["class", "site-header-primary"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 16, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 15, "div", [["class", "site-header-row "]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 10, "nav", [["class", "site-menu"], ["id", "site-menu"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 9, "div", [["class", "btn-group nav-button"]], null, [[null, "clickOutside"], ["document", "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("document:click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 5).clickOutside($event) !== false);
        ad = (pd_0 && ad);
    } if (("clickOutside" === en)) {
        var pd_1 = (_co.CloseMegaMenu() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(5, 16384, null, 0, i1.ClickOutsideDirective, [i0.ElementRef], null, { hideCallback: "clickOutside" }), (_l()(), i0.ɵeld(6, 0, null, null, 5, "button", [["class", "btn btn-link"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.ToggleMegaMenu() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(7, 278528, null, 0, i2.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(8, { "active": 0 }), (_l()(), i0.ɵeld(9, 0, null, null, 0, "i", [["class", "nav-icon fa fa-bars"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 1, "span", [["class", "sr-only"]], null, null, null, null, null)), (_l()(), i0.ɵted(11, null, ["", ""])), (_l()(), i0.ɵeld(12, 0, null, null, 1, "mega-menu", [], [[8, "hidden", 0]], [[null, "toggle"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("toggle" === en)) {
        var pd_0 = (_co.CloseMegaMenu() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MegaMenuComponent_0, i3.RenderType_MegaMenuComponent)), i0.ɵdid(13, 49152, null, 0, i4.MegaMenuComponent, [i5.CMSService, i6.LanguageService, i7.MenuService, i8.UrlService], null, { toggle: "toggle" }), (_l()(), i0.ɵeld(14, 0, null, null, 1, "header-search", [["class", "header-search-field"]], null, null, null, i9.View_HeaderSearchComponent_0, i9.RenderType_HeaderSearchComponent)), i0.ɵdid(15, 49152, null, 0, i10.HeaderSearchComponent, [i11.Router, i11.ActivatedRoute, i6.LanguageService, i12.DictionaryService, i13.ModalService], null, null), (_l()(), i0.ɵeld(16, 0, null, null, 1, "shopping-cart-button", [["class", "btn-group nav-button"]], null, null, null, i14.View_ShoppingCartButtonComponent_0, i14.RenderType_ShoppingCartButtonComponent)), i0.ɵdid(17, 49152, null, 0, i15.ShoppingCartButtonComponent, [i16.BasketService, i12.DictionaryService, i6.LanguageService], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "btn btn-link"; var currVal_1 = _ck(_v, 8, 0, _co.showMegaMenu); _ck(_v, 7, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = ((_co.dictionary == null) ? null : _co.dictionary.Site_Header_Primary_Navigation_Sr); _ck(_v, 11, 0, currVal_2); var currVal_3 = !_co.showMegaMenu; _ck(_v, 12, 0, currVal_3); }); }
export function View_HeaderPrimaryComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "header-primary", [], null, null, null, View_HeaderPrimaryComponent_0, RenderType_HeaderPrimaryComponent)), i0.ɵdid(1, 49152, null, 0, i17.HeaderPrimaryComponent, [i11.Router, i6.LanguageService, i12.DictionaryService], null, null)], null, null); }
var HeaderPrimaryComponentNgFactory = i0.ɵccf("header-primary", i17.HeaderPrimaryComponent, View_HeaderPrimaryComponent_Host_0, {}, {}, []);
export { HeaderPrimaryComponentNgFactory as HeaderPrimaryComponentNgFactory };
