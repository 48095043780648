import { LanguageService } from "./language/language.service";
var UrlService = /** @class */ (function () {
    function UrlService(languageService) {
        this.languageService = languageService;
    }
    UrlService.prototype.buildUrl = function () {
        var params = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            params[_i] = arguments[_i];
        }
        var nonNull = params.filter(function (elem) { return elem !== null; });
        return nonNull.reduce(function (acc, curr) { return curr ? acc + curr + "/" : acc; }, "/");
    };
    UrlService.prototype.addLanguage = function (url) {
        return "/" + this.languageService.current + "/" + url;
    };
    return UrlService;
}());
export { UrlService };
