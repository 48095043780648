import { Component } from "@angular/core";
import { ApiService } from "../../../services/API/api.interface.service";
import { CurrencyService } from "../../../services/currency.service";
import { LanguageService } from "../../../services/language/language.service";
import { PaginationSettings } from "../../tables/pagination/paginationSettings.datatype";
import { DictionaryService } from "../../../services/dictionary.service";
import { Option } from "../../select/select.component";
import { zip } from "rxjs";
import { combineLatest, map } from "rxjs/operators";
import { PriceListTld } from "./PriceListTLD";

type filter = (list: any[]) => any[];

@Component({
    selector: "all-tlds-table",
    templateUrl: "./allTldsTable.component.html"
})
export class AllTldsTableComponent {
    public tlds: PriceListTld[] = [];
    public filteredTlds: PriceListTld[] = [];
    private paginationFilter: filter = (xs) => xs;
    private searchFilter: filter = (xs) => xs
    public paginationSettings: PaginationSettings = { total: 0, page: 1, pageSize: 25 }
    public currency: string = "SEK";
    public dictionary: any;
    public tldGroups: Option[] = [];
    public groupFilter: filter = (xs) => xs
    public isLoading = true;
    public showPagination = true;
    public noResults = false;

    public exchangerates: ExchangeRates = { USD: 1, EUR: 1 };

    constructor(api: ApiService,
                currencyService: CurrencyService,
                public languageService: LanguageService,
                dictionaryService: DictionaryService) {

        dictionaryService.getDictionary().subscribe(d => this.dictionary = d);

        zip(dictionaryService.getDictionary(), api.tldGroups())
            .subscribe(([dictionary, result]) => {
                this.tldGroups = result.tldgroups.map((groupName: string) => {
                    return { display: this.dictionary["Topdomain_Group_" + groupName] || groupName, value: groupName }
                });
                this.tldGroups = [{ display: this.dictionary["Topdomain_Group_ALL"], value: "" }, ...this.tldGroups]
            });

        api.pricelist()
            .pipe(
                combineLatest(currencyService.current),
                map(([response, currency]) => {
                    if(response.tlds){
                        this.currency = currency;
                        let exchangerate = this.getExchangeRate(currency, response.exchangerates);
                        return response.tlds.map((tld: any) => {
                            return {
                                name: tld.tld,
                                registration: tld.actions.Registration.price / exchangerate,
                                renew: tld.actions.Renew.price / exchangerate,
                                transfer: tld.actions.Transfer.price / exchangerate,
                                groups: tld.groups,
                                toString: function() {
                                    return this.name
                                }
                            }
                        })
                    }
                })
            )
            .subscribe(tlds => {
                if(tlds){
                    tlds.sort();
                    this.tlds = tlds;
                    this.filterList();
                    this.isLoading = false;
                }
            })

    }

    private getExchangeRate(currency: string, rates: any): number {
        switch (currency) {
            case "SEK":
                return 1;
            case "USD":
                return Number.parseFloat(rates.USD);
            case "EUR":
                return Number.parseFloat(rates.EUR);
            default:
                return 1;
        }
    }

    filterList() {
        let list = this.searchFilter(this.tlds)
        list = this.groupFilter(list);

        this.paginationSettings.total = list.length;
        list = this.paginationFilter(list);

        this.noResults = this.paginationSettings.total === 0;

        if (this.paginationSettings.total <= this.paginationSettings.pageSize) {
            this.filteredTlds = list;
            this.showPagination = false;
        } else {
            this.filteredTlds = this.padWithEmptyRows(list);
            this.showPagination = true;
        }

    }

    onSearch(filter: filter) {
        this.searchFilter = filter;
        this.paginationSettings.page = 1;
        this.filterList();
    }

    onPaginate(filter: filter) {
        this.paginationFilter = filter;
        this.filterList();
    }

    onGroupFilter(value: string) {
        this.groupFilter = (list: PriceListTld[]) => {
            return value === ""
                ? list
                : list.filter(tld => tld.groups && tld.groups.some(group => group === value))
        };
        this.paginationSettings.page = 1;
        this.filterList();
    }

    padWithEmptyRows(list: PriceListTld[]): PriceListTld[] {
        let missingRows = this.paginationSettings.pageSize - list.length;
        while (missingRows > 0) {
            list.push(new PriceListTld())
            missingRows--;
        }
        return list;
    }
}

interface ExchangeRates {
    USD: number;
    EUR: number;
}