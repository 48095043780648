import { Component, Input } from "@angular/core";
import { Domain, getStateFunction } from "../domain.interface";
import { DictionaryService } from "../../../services/dictionary.service";
import { CurrencyService } from "../../../services/currency.service";
import { domainStates } from "../domainStates.constant";
import { replaceToken } from "../../../shared/util";
import { ModalService } from "../../../services/modal.service";
import { WhoisModalComponent } from "../../../shared/whoisModal/whoisModal.component";

@Component({
    selector: "search-domains-header",
    templateUrl: "./searchDomainsHeader.component.html"
})
export class SearchDomainsHeader {
    @Input()
    domain?: Domain;

    getGlyph: (domain?: Domain) => any;
    getTextClass: (domain?: Domain) => any;
    getPanelClass: (domain?: Domain) => any;
    dictionary: any;

    constructor(dictionaryService: DictionaryService, public currency: CurrencyService, private modalService: ModalService) {
        dictionaryService.getDictionary().subscribe(d => this.dictionary = d)
        this.getPanelClass = getStateFunction(domainStates.availabilityPanelClass)
        this.getGlyph = getStateFunction(domainStates.availabiltyIcon)
        this.getTextClass = getStateFunction(domainStates.availabilityTextClass);
    }

    domainText() {
        if (!this.dictionary) return;
        if (!this.domain) return this.dictionary["Search_Domain_Searching"];

        if (this.domain.invalid) {
            return replaceToken(this.dictionary["Search_Domain_Domain_Status_Invalid"], "domainName", this.domain.fullName());
        }

        if(!this.domain.available) 
        {
            return this.domain.fullName();
        }
        let availableKey;
        if (this.domain.available) {
            availableKey = "Search_Domain_Status_Available";
        } 
        else
        {
            availableKey = "Search_Domain_Domain_Status unavailable";
        } 

        let text = replaceToken(this.dictionary["Search_Domain_Domain_Status"], "domainName", this.domain.fullName());
        return replaceToken(text, "status", this.dictionary[availableKey]);

    }

    openWhoisModal() {
        if (!this.domain) return;
        this.modalService.openModal(WhoisModalComponent, { domainName: this.domain.fullName() })
    }
}