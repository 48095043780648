import { DictionaryService } from "../../services/dictionary.service";
import { ActivatedRoute } from "@angular/router";
import { LanguageService } from "../../services/language/language.service";
import { Location } from "@angular/common";

export class ErrorPageComponent {
    public dictionary: any;

    constructor(dictionaryService: DictionaryService, route: ActivatedRoute, location: Location, public languageService: LanguageService) {
        dictionaryService.getDictionary().subscribe(dictionary => {
            this.dictionary = dictionary
        });
        let originalUrl = route.snapshot.queryParams["url"]
        if (originalUrl) {
            location.replaceState(originalUrl)
        }
    }
}