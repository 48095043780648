<popover [open]="openEvent">
    <ng-container header>
        <h3>
            <i class="glyph-error text-danger"></i>
            <span>{{dictionary?.Whois_Popover_Title}}</span>
        </h3>
    </ng-container>
    <div body>
        {{dictionary?.Whois_Popover_Description}}
    </div>
</popover>