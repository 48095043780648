/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./bugReport.component";
import * as i3 from "../../services/dictionary.service";
var styles_BugReportComponent = [];
var RenderType_BugReportComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_BugReportComponent, data: {} });
export { RenderType_BugReportComponent as RenderType_BugReportComponent };
function View_BugReportComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "a", [["class", "report-bug-button"], ["href", "#"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.reportBug($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.dictionary == null) ? null : _co.dictionary.Usersnap_Report_Bug_Button); _ck(_v, 1, 0, currVal_0); }); }
export function View_BugReportComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_BugReportComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isProduction; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_BugReportComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "bug-report", [], null, null, null, View_BugReportComponent_0, RenderType_BugReportComponent)), i0.ɵdid(1, 49152, null, 0, i2.BugReportComponent, [i3.DictionaryService, i0.PLATFORM_ID, i1.DOCUMENT, "ENVIRONMENT"], null, null)], null, null); }
var BugReportComponentNgFactory = i0.ɵccf("bug-report", i2.BugReportComponent, View_BugReportComponent_Host_0, {}, {}, []);
export { BugReportComponentNgFactory as BugReportComponentNgFactory };
