import { LanguageService } from "../../../services/language/language.service";
import { MenuService } from "../../../services/menu/menu.service";
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { DictionaryService } from "../../../services/dictionary.service";
import { UrlService } from "../../../services/url.service";
import { ApiService } from "../../../services/API/api.interface.service";
import { PriceService } from "../../../services/price/price.service";
import { combineLatest } from "rxjs";
import { CurrencyService } from "../../../services/currency.service";
import { CMSService } from "../../../services/CMS/cms.service";
import { DecimalPipe } from "@angular/common";
import { PageService } from "../../../services/page/page.service";
import { replaceToken } from "../../../shared/util";
import { Title } from "@angular/platform-browser";
import { combineLatest as combineLatestPipe, map, filter } from "rxjs/operators";
var TopLevelDomainComponent = /** @class */ (function () {
    function TopLevelDomainComponent(menuService, route, router, dictionaryService, api, cms, url, priceService, currencyService, languageService, decimalPipe, pageservice, titleService) {
        var _this = this;
        this.menuService = menuService;
        this.route = route;
        this.router = router;
        this.dictionaryService = dictionaryService;
        this.api = api;
        this.cms = cms;
        this.url = url;
        this.priceService = priceService;
        this.currencyService = currencyService;
        this.languageService = languageService;
        this.decimalPipe = decimalPipe;
        this.pageservice = pageservice;
        this.titleService = titleService;
        this.menuTitle = { name: "Title", link: "/" };
        this.menuLinks = [{ name: "Link", link: "/" }];
        this.searchDomainName = "";
        this.page = null;
        this.tldInfo = null;
        this.tldGroups = [];
        this.regulations = [];
        this.resellerInfo = [];
        this.agreementUrl = "";
        this.prices = [];
        this.isPricesLoading = true;
        this.isLoading = true;
        this.resellerData = [];
        this.countryFlagClass = "";
        this.currency = "";
        this.countryName = "";
        this.breadcrumbs = [];
        this.tldsOptions = [];
        this.isLoading = true;
        this.router.events
            .pipe(filter(function (event) { return event instanceof NavigationEnd; }))
            .subscribe(function () {
            _this.isLoading = true;
            _this.currencyService.current.subscribe(function (x) { return _this.currency = x; });
            _this.domain = _this.route.snapshot.params["domain"];
            _this.selectedTldOption = _this.domain;
            var dictionaryObservable = _this.dictionaryService.getDictionary();
            var pageObservable = _this.pageservice.getTopLevelDomain(_this.domain);
            var tldInfoObservable = _this.api.getTLDInfo(_this.domain);
            var countryObservable = _this.cms.getCountryInfo(_this.domain);
            combineLatest(dictionaryObservable, pageObservable)
                .subscribe(function (_a) {
                var dictionary = _a[0], page = _a[1];
                _this.dictionary = dictionary;
                _this.mapPageToProps(page, dictionary);
            });
            combineLatest(dictionaryObservable, countryObservable)
                .subscribe(function (_a) {
                var dictionary = _a[0], country = _a[1];
                if (country) {
                    if (country.ISO2Code) {
                        _this.countryFlagClass = "flag-" + country.ISO2Code.toLowerCase();
                    }
                    _this.countryName = country.name;
                    _this.countryInfo = [
                        { label: dictionary["Top_Level_Domain_Country_Capital"], data: country.capital },
                        { label: dictionary["Top_Level_Domain_Country_Language"], data: country.language },
                        { label: dictionary["Top_Level_Domain_Country_Population"], data: country.population },
                        { label: dictionary["Top_Level_Domain_Country_Area"], data: country.area },
                        { label: dictionary["Top_Level_Domain_Country_Timezone"], data: country.timezone },
                    ];
                }
            });
            combineLatest(dictionaryObservable, tldInfoObservable, _this.currencyService.current)
                .subscribe(function (_a) {
                var dictionary = _a[0], tld = _a[1], currency = _a[2];
                if (!tld)
                    return;
                _this.regulations = _this.getRules(tld);
                _this.agreementUrl = tld.agreement ? tld.agreement[0] : "";
                var prices = _this.priceService.getPrices(tld, currency);
                _this.prices = prices.map(function (p) {
                    return {
                        label: dictionary[p.name],
                        data: _this.decimalPipe.transform(p.value, '1.2-2') + " " + _this.currency + " " + _this.getYears(p.years, dictionary)
                    };
                });
                _this.isPricesLoading = false;
                _this.tldInfo = tld;
                _this.resellerData = _this.mapResellerInfo(tld, dictionary);
                _this.apiAdditionalInformation = tld.description ? tld.description[0] : null;
                if (!_this.additionalInformation) {
                    _this.additionalInformation = _this.apiAdditionalInformation;
                }
                _this.isLoading = false;
            });
            _this.menuService.getSubMenu(undefined, "domain-names")
                .pipe(combineLatestPipe(dictionaryObservable))
                .subscribe(function (val) {
                var menu = val[0], dictionary = val[1];
                if (menu) {
                    _this.menuTitle = _this.MenuItemToNamedLink(menu);
                    _this.breadcrumbs = [
                        { name: menu.pageTitle, link: menu.url },
                        { name: "Top_Level_Domain_Name", link: "/domain-names/all-top-level-domains" },
                        { name: "." + _this.domain, link: "/" }
                    ];
                    _this.menuLinks = menu.items.map(function (x) { return _this.MenuItemToNamedLink(x); });
                }
            });
            _this.api.pricelist()
                .pipe(map(function (response) {
                if (response.tlds) {
                    return response.tlds.map(function (tld) {
                        return {
                            display: tld.tld,
                            value: tld.tld
                        };
                    });
                }
            }))
                .subscribe(function (tldsOptions) {
                _this.tldsOptions = tldsOptions;
            });
        });
    }
    TopLevelDomainComponent.prototype.mapPageToProps = function (page, dictionary) {
        this.heading = "." + this.domain;
        this.introText = dictionary["Top_Level_Domain_Default_Subheader"];
        this.bodyText = dictionary["Top_Level_Domain_Default_Body"];
        if (!page) {
            this.titleService.setTitle(this.domain + " | Name ISP");
            return;
        }
        if (page.heading && page.heading.length > 0) {
            this.heading = page.heading;
        }
        if (page.subHeading && page.subHeading.length > 0) {
            this.introText = page.subHeading;
        }
        if (page.bodyText && page.bodyText.length > 0) {
            this.bodyText = page.bodyText;
        }
        this.additionalInformation = page.additionalInformation
            ? page.additionalInformation
            : this.apiAdditionalInformation;
    };
    TopLevelDomainComponent.prototype.MenuItemToNamedLink = function (menu) {
        return { name: menu.pageTitle, link: this.url.addLanguage(menu.url) };
    };
    TopLevelDomainComponent.prototype.getRules = function (tld) {
        var result = [];
        var addRule = function (name, property) {
            if (property) {
                property.forEach(function (x) {
                    if (x !== "") {
                        result.push(name + x);
                    }
                });
            }
        };
        addRule("Top_Level_Domain_Rules_Country_Connection_", tld.countryconnection);
        addRule("Top_Level_Domain_Rules_Name_Similarity_", tld.namesimilarity);
        addRule("Top_Level_Domain_Rules_Idn_Allowed_", tld.idn);
        return result;
    };
    TopLevelDomainComponent.prototype.getYears = function (years, dictionary) {
        if (years === 0)
            return "";
        if (years === 1)
            return "(" + years + " " + dictionary["Top_Level_Domain_Prices_Year"] + ")";
        if (years > 1)
            return "(" + years + " " + dictionary["Top_Level_Domain_Prices_Years"] + ")";
        return "";
    };
    TopLevelDomainComponent.prototype.isPage = function (obj) {
        return obj.customProperties !== undefined;
    };
    TopLevelDomainComponent.prototype.isFallbackCountry = function (obj) {
        return obj.alpha2Code !== undefined;
    };
    TopLevelDomainComponent.prototype.mapResellerInfo = function (tld, dictionary) {
        var _this = this;
        var row = function (label, data) {
            return { label: label, data: data };
        };
        var dictionaryReplaceDomain = function (dictionaryKey) { return replaceToken(dictionary[dictionaryKey], "domain", "." + _this.domain); };
        var yes = dictionary ? dictionary["Misc_Yes"] : "Yes";
        var no = dictionary ? dictionary["Misc_No"] : "No";
        var first = function (arr) { return (arr && arr.length > 0) ? arr[0] : ""; };
        var firstAsWord = function (arr) { return firstCharUpperCase(first(arr)); };
        var firstCharUpperCase = function (str) { return str.charAt(0).toUpperCase() + str.slice(1); };
        var yesNo = function (arr) { return first(arr) === "1" ? yes : no; };
        var status = function (s) { return s === "200" ? dictionary["Misc_Available"] : dictionary["Misc_Unavailable"]; };
        var authcode = function (arr) { return first(arr) === "1" ? dictionary["Top_Level_Domain_Authcode"] : dictionary["Misc_Unavailable"]; };
        var years = function (arr) { return (arr && arr.length > 0) ? (arr.join(", ") + " " + dictionary["Top_Level_Domain_Prices_Years"]) : dictionary["Misc_Unavailable"]; };
        var trustee = function (arr) {
            return arr.map(function (s) {
                switch (s) {
                    case "10":
                        return dictionary["TLD_Reseller_Trustee_Required_Company"];
                    case "20":
                        return dictionary["TLD_Reseller_Trustee_Required_Admin"];
                    case "30":
                        return dictionary["TLD_Reseller_Trustee_Required_Europe"];
                    case "40":
                        return dictionary["TLD_Reseller_Trustee_Required_Trademark"];
                    case "50":
                        return dictionary["TLD_Reseller_Trustee_Required_Trademark_Or_Company"];
                    default:
                        return dictionary["Misc_No"];
                }
            }).join(", ");
        };
        return [
            {
                header: null,
                rows: [
                    row(dictionaryReplaceDomain("Top_Level_Domain_Reseller_API_Documentation"), "http://api3.nameisp.com"),
                    row(dictionaryReplaceDomain("Top_Level_Domain_Reseller_TLDStatus"), status(tld.status)),
                    row(dictionaryReplaceDomain("Top_Level_Domain_Reseller_TLDType"), first(tld.tldtype)),
                    row(dictionaryReplaceDomain("Top_Level_Domain_Reseller_RootServer"), first(tld.root_server)),
                    row(dictionaryReplaceDomain("Top_Level_Domain_Reseller_TMCH"), yesNo(tld.tmch)),
                    row(dictionaryReplaceDomain("Top_Level_Domain_Reseller_ShieldWhois"), yesNo(tld.shieldwhois)),
                    row(dictionaryReplaceDomain("Top_Level_Domain_Reseller_DNSSEC"), yesNo(tld.dnssec)),
                    row(dictionaryReplaceDomain("Top_Level_Domain_Reseller_IDN"), yesNo(tld.idn)),
                    row(dictionaryReplaceDomain("Top_Level_Domain_Reseller_Trustee_Required"), trustee(tld.countryconnection)),
                    row(dictionaryReplaceDomain("Top_Level_Domain_Reseller_Trustee"), yesNo(tld.trustee))
                ]
            },
            {
                header: dictionaryReplaceDomain("Top_Level_Domain_Reseller_Create_Domain_Registration"),
                rows: [
                    //row("Average turnover time", "7 days"),
                    row(dictionaryReplaceDomain("Top_Level_Domain_Reseller_Allowed_Domain_Length"), first(tld.minLen) + " - " + first(tld.maxLen)),
                    row(dictionaryReplaceDomain("Top_Level_Domain_Reseller_Allowed_Registration_Periods"), years(tld.regY))
                ]
            },
            {
                header: dictionaryReplaceDomain("Top_Level_Domain_Reseller_Create_Domain_Transfer"),
                rows: [
                    //row("Average turnover time", "7 days"),
                    row(dictionaryReplaceDomain("Top_Level_Domain_Reseller_Transfer_Method"), firstAsWord(tld.transferMethod)),
                    row(dictionaryReplaceDomain("Top_Level_Domain_Reseller_Transfer_Approval_Method"), firstAsWord(tld.transferVerificationMethod)),
                    row(dictionaryReplaceDomain("Top_Level_Domain_Reseller_Renewal_Included"), yesNo(tld.renewontransfer)),
                ]
            },
            {
                header: dictionaryReplaceDomain("Top_Level_Domain_Reseller_Update_Domain_Renew"),
                rows: [
                    //row("Average turnover time", "1 - 3 days"),
                    row(dictionaryReplaceDomain("Top_Level_Domain_Reseller_Available_Renewal_Periods"), years(tld.renewY)),
                ]
            },
            {
                header: dictionaryReplaceDomain("Top_Level_Domain_Reseller_Update_Domain_DNS"),
                rows: [
                    //row("Average turnover time", "1 - 3 days"),
                    row(dictionaryReplaceDomain("Top_Level_Domain_Reseller_Number_Of_DNS_Hosts_Allowed"), first(tld.minNS) + " - " + first(tld.maxNS)),
                ]
            },
            {
                header: dictionaryReplaceDomain("Top_Level_Domain_Reseller_Update_Domain_Owner_Transfer"),
                rows: [
                    //row("Average turnover time", "1 - 30 days"),
                    row(dictionaryReplaceDomain("Top_Level_Domain_Reseller_Supported_Via_Registrar"), yes)
                ]
            },
            {
                header: dictionaryReplaceDomain("Top_Level_Domain_Reseller_Update_Domain_Contact"),
                rows: [
                    //row("Average turnover time", "1 - 30 days"),
                    row(dictionaryReplaceDomain("Top_Level_Domain_Reseller_Supported_Via_Registrar"), yes)
                ]
            }
        ];
    };
    TopLevelDomainComponent.prototype.onDomainSearch = function () {
        var url = "/" + this.languageService.current + "/searchdomains?domainname=" + this.searchDomainName;
        if (!this.searchDomainName.includes(".")) {
            url += "." + this.domain;
        }
        this.router.navigateByUrl(url);
    };
    TopLevelDomainComponent.prototype.onSelectTld = function (tld) {
        this.router.navigateByUrl("/" + this.languageService.current + "/domain-names/top-level-domain/" + tld);
    };
    return TopLevelDomainComponent;
}());
export { TopLevelDomainComponent };
