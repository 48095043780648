import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { LanguageService } from "../../../services/language/language.service";
import { DictionaryService } from "../../../services/dictionary.service";
import { ApiService } from "../../../services/API/api.interface.service";
import { CurrencyService } from "../../../services/currency.service";
import { PriceService } from "../../../services/price/price.service";
import { startWith } from "rxjs/operators";
import * as rxjs from "rxjs";
import { PriceType } from "../../../services/price/priceclass.enum";

@Component({
    selector: "promo-slider",
    templateUrl: "./promoslider.component.html"
})
export class PromoSliderComponent implements OnChanges {
    @Input() public tlds: string[];
    public dictionary: any;
    public promoTlds: TopLevelDomain[];
    public currency: string;
    public promoslider: HTMLElement | null;
    public addShadowLeft: boolean;
    public addShadowRight: boolean;
    public addShadowBoth: boolean;

    constructor(public languageService: LanguageService,
                dictionaryService: DictionaryService,
                private api: ApiService,
                public currencyService: CurrencyService,
                private priceService: PriceService) {
        dictionaryService.getDictionary().subscribe(x => (this.dictionary = x));
        this.promoTlds = [];

        currencyService.current.subscribe(x => (this.currency = x));
    }

    ngOnChanges(changes: SimpleChanges): void {
        let tlds = changes["tlds"].currentValue;
        if (!tlds) return;
        let observables = this.tlds.map(tld => {
            return rxjs.combineLatest(
                this.api.getTLDInfo(tld),
                this.currencyService.current,
                (info: any, currency: string) => {
                    if (!info) return null;
                    return new TopLevelDomain(
                        tld,
                        this.priceService.getPrice(
                            info.prices,
                            PriceType.Registration,
                            currency
                        )
                    );
                }
            ).pipe(
                startWith(new TopLevelDomain(tld, null))
            );
        });

        for (let i = 0; i < observables.length; i++) {
            observables[i].subscribe(tld => {
                if (!tld) return;
                this.promoTlds[i] = tld as TopLevelDomain;
            });
        }
    }

}

export class TopLevelDomain {
    priceIsLoading: boolean = true;

    constructor(public domain: string, public price: number | null) {
        this.domain = domain;
        this.priceIsLoading = price === null;
    }
}
