import { OnChanges, SimpleChanges } from "@angular/core";
import { LanguageService } from "../../../services/language/language.service";
import { DictionaryService } from "../../../services/dictionary.service";
import { ApiService } from "../../../services/API/api.interface.service";
import { CurrencyService } from "../../../services/currency.service";
import { PriceService } from "../../../services/price/price.service";
import { startWith } from "rxjs/operators";
import * as rxjs from "rxjs";
import { PriceType } from "../../../services/price/priceclass.enum";
var PromoSliderComponent = /** @class */ (function () {
    function PromoSliderComponent(languageService, dictionaryService, api, currencyService, priceService) {
        var _this = this;
        this.languageService = languageService;
        this.api = api;
        this.currencyService = currencyService;
        this.priceService = priceService;
        dictionaryService.getDictionary().subscribe(function (x) { return (_this.dictionary = x); });
        this.promoTlds = [];
        currencyService.current.subscribe(function (x) { return (_this.currency = x); });
    }
    PromoSliderComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        var tlds = changes["tlds"].currentValue;
        if (!tlds)
            return;
        var observables = this.tlds.map(function (tld) {
            return rxjs.combineLatest(_this.api.getTLDInfo(tld), _this.currencyService.current, function (info, currency) {
                if (!info)
                    return null;
                return new TopLevelDomain(tld, _this.priceService.getPrice(info.prices, PriceType.Registration, currency));
            }).pipe(startWith(new TopLevelDomain(tld, null)));
        });
        var _loop_1 = function (i) {
            observables[i].subscribe(function (tld) {
                if (!tld)
                    return;
                _this.promoTlds[i] = tld;
            });
        };
        for (var i = 0; i < observables.length; i++) {
            _loop_1(i);
        }
    };
    return PromoSliderComponent;
}());
export { PromoSliderComponent };
var TopLevelDomain = /** @class */ (function () {
    function TopLevelDomain(domain, price) {
        this.domain = domain;
        this.price = price;
        this.priceIsLoading = true;
        this.domain = domain;
        this.priceIsLoading = price === null;
    }
    return TopLevelDomain;
}());
export { TopLevelDomain };
