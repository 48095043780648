import { Inject, Injectable } from "@angular/core";
import {
    HttpHandler,
    HttpHeaderResponse,
    HttpInterceptor,
    HttpProgressEvent,
    HttpRequest,
    HttpResponse,
    HttpSentEvent,
    HttpUserEvent
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { Router } from "@angular/router";
import { catchError } from "rxjs/operators";


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(private router: Router, @Inject("CMS_URL") public cmsUrl: string) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
        return next.handle(req)
            .pipe(
                catchError(error => {
                    if (req.url.indexOf(this.cmsUrl) === 0) {
                        this.router.navigate(["/en/internalservererror"]);
                    }
                    return throwError(error.message);
                })
            )
    }
}