/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "./megaMenu.component";
import * as i4 from "../../../services/CMS/cms.service";
import * as i5 from "../../../services/language/language.service";
import * as i6 from "../../../services/menu/menu.service";
import * as i7 from "../../../services/url.service";
var styles_MegaMenuComponent = [];
var RenderType_MegaMenuComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MegaMenuComponent, data: {} });
export { RenderType_MegaMenuComponent as RenderType_MegaMenuComponent };
function View_MegaMenuComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "li", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.OnClickLink($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵted(3, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.url.addLanguage(_v.context.$implicit.url); _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).target; var currVal_1 = i0.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = _v.context.$implicit.pageTitle; _ck(_v, 3, 0, currVal_3); }); }
function View_MegaMenuComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "col-sm-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.OnClickLink($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), (_l()(), i0.ɵeld(5, 0, null, null, 2, "ul", [["class", "navigation"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MegaMenuComponent_2)), i0.ɵdid(7, 802816, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.url.addLanguage(_v.context.$implicit.url); _ck(_v, 2, 0, currVal_2); var currVal_4 = _v.context.$implicit.items; _ck(_v, 7, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).target; var currVal_1 = i0.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = _v.context.$implicit.pageTitle; _ck(_v, 4, 0, currVal_3); }); }
export function View_MegaMenuComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "mega-menu container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "public-navigation"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "div", [["class", "public-navigation-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "public-primary-navigation"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MegaMenuComponent_1)), i0.ɵdid(5, 802816, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.menuItems; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_MegaMenuComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mega-menu", [], null, null, null, View_MegaMenuComponent_0, RenderType_MegaMenuComponent)), i0.ɵdid(1, 49152, null, 0, i3.MegaMenuComponent, [i4.CMSService, i5.LanguageService, i6.MenuService, i7.UrlService], null, null)], null, null); }
var MegaMenuComponentNgFactory = i0.ɵccf("mega-menu", i3.MegaMenuComponent, View_MegaMenuComponent_Host_0, {}, { toggle: "toggle" }, []);
export { MegaMenuComponentNgFactory as MegaMenuComponentNgFactory };
