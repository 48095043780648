<div class="input-group table-search-field">
    <input class="form-control" placeholder="{{dictionary?.Index_Search_Button}}" [ngModel]="search" (ngModelChange)="onSearch($event)" />
    <button class="button search" type="submit">
        <span class="button-inner">
            <span class="button-icon">
                <i class="fa fa-search"></i>
                <span class="sr-only">{{dictionary?.Index_Search_Button}}</span>
            </span>
        </span>
    </button>
    {{search}}
</div>