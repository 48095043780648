import { Injectable } from '@angular/core';
import { CMSService } from './CMS/cms.service'
import { Observable, ReplaySubject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http/src/response';
import { LanguageService } from './language/language.service';

@Injectable()
export class DictionaryService {

    private subject: ReplaySubject<any>;

    constructor(private cms: CMSService, languageService: LanguageService) {
        this.cms = cms;
        this.subject = new ReplaySubject<any>(1);


        cms.getDictionary()
            .subscribe(dictionary => this.subject.next(dictionary))
    }

    public getDictionary(): Observable<any> {
        return new Observable(observer => {
            this.subject.subscribe(data => {
                observer.next(data);
            }, (error: HttpErrorResponse) => {
                observer.next({});
                console.log(error.message)
            })
        })
    }
}