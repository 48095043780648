import { Component, Inject } from '@angular/core';
import { LanguageService } from '../../../services/language/language.service';
import { DictionaryService } from '../../../services/dictionary.service';

@Component({
    selector: 'header-top',
    templateUrl: './headerTop.component.html'
})
export class HeaderTopComponent {
    public dictionary: any;

    constructor(public languageService: LanguageService, dictionaryService: DictionaryService, @Inject("CP_URL") public cpUrl: string) {
        dictionaryService.getDictionary().subscribe(d => this.dictionary = d);
    }
}
