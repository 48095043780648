/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./addToCartButton.component";
import * as i3 from "../../../services/dictionary.service";
import * as i4 from "../../../services/basket/basket.service";
import * as i5 from "../../../services/modal.service";
var styles_AddToCartButton = [];
var RenderType_AddToCartButton = i0.ɵcrt({ encapsulation: 2, styles: styles_AddToCartButton, data: {} });
export { RenderType_AddToCartButton as RenderType_AddToCartButton };
export function View_AddToCartButton_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "btn-group"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "button", [["class", "btn btn-lg"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClicked() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "i", [], null, null, null, null, null)), i0.ɵdid(4, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), (_l()(), i0.ɵted(-1, null, ["\u00A0"])), (_l()(), i0.ɵeld(6, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "btn btn-lg"; var currVal_1 = _co.getButtonClass(_co.domain); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.getGlyph(_co.domain); _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.getText(); _ck(_v, 7, 0, currVal_3); }); }
export function View_AddToCartButton_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "add-to-cart-button", [], null, null, null, View_AddToCartButton_0, RenderType_AddToCartButton)), i0.ɵdid(1, 49152, null, 0, i2.AddToCartButton, [i3.DictionaryService, i4.BasketService, i5.ModalService], null, null)], null, null); }
var AddToCartButtonNgFactory = i0.ɵccf("add-to-cart-button", i2.AddToCartButton, View_AddToCartButton_Host_0, { domain: "domain" }, {}, []);
export { AddToCartButtonNgFactory as AddToCartButtonNgFactory };
