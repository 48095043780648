import { Inject, Injectable, PLATFORM_ID, PlatformRef } from '@angular/core'
import { CookieService } from 'ngx-cookie-service';
import { Observable, ReplaySubject } from 'rxjs';
import { Languages } from './language.constants';
import { Localization } from './localization.model';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class LanguageService {

    public languages: Localization[] = Languages;

    private isBrowser: boolean;

    private cookieName = "nisp.lang";
    private cookieDays = 400;

    public default: string = "en";

    private subject: ReplaySubject<string>;
    public current: string;

    constructor(private cookieService: CookieService, @Inject(PLATFORM_ID) platformId: PlatformRef) {
        this.subject = new ReplaySubject<string>(1);
        this.subject.subscribe(lang => this.current = lang);

        this.isBrowser = isPlatformBrowser(platformId);

        if (this.isBrowser) {
            let lang = cookieService.get(this.cookieName);

            if (!lang) {
                lang = this.getBrowserLanguage();
            }

            if (this.valid(lang)) {
                this.default = lang;
            }
        }
    }

    public setLanguage(lang: string) {
        if (this.current !== lang) {
            this.subject.next(lang);

            this.saveLanguage(lang);
        }
    }

    public getLanguage(): Observable<string> {
        return this.subject.asObservable();
    }

    public valid(lang: string) {
        return this.languages.reduce((acc, val) => acc || val.code === lang, false)
    }

    private saveLanguage(lang: string) {
        if (this.isBrowser) {
            this.cookieService.set(this.cookieName, lang, this.cookieDays, "/");
        }
    }

    private getBrowserLanguage(): string {
        if (this.isBrowser) {
            // New browsers
            if (navigator.languages) {
                for (let i = 0; i < navigator.language.length; i++) {
                    let language = navigator.languages[i].substr(0, 2);
                    if (this.valid(language)) {
                        return language;
                    }
                }
            }

            // Older browsers
            if (navigator.language) return navigator.language;

            // IE <= 10
            if ((navigator as any).userLanguage) return (navigator as any).userLanguage;
        }
        return "";
    }
}
