/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../panelItem.component.ngfactory";
import * as i2 from "../panelItem.component";
import * as i3 from "@angular/router";
import * as i4 from "@angular/common";
import * as i5 from "./linkPanelItem.component";
var styles_LinkPanelItemComponent = [];
var RenderType_LinkPanelItemComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LinkPanelItemComponent, data: {} });
export { RenderType_LinkPanelItemComponent as RenderType_LinkPanelItemComponent };
export function View_LinkPanelItemComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "panel-item", [["class", "panel-item"]], null, null, null, i1.View_PanelItemComponent_0, i1.RenderType_PanelItemComponent)), i0.ɵdid(1, 49152, null, 0, i2.PanelItemComponent, [], null, null), (_l()(), i0.ɵeld(2, 0, null, 0, 3, "a", [["header", ""]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i4.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 1, "h2", [["class", "panel-heading"]], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", ""])), (_l()(), i0.ɵeld(6, 0, null, 1, 0, "div", [["body", ""]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, 2, 3, "a", [["class", "btn btn-link pull-right"], ["footer", ""]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 8).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(8, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i4.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵted(9, null, [" ", "\u00A0"])), (_l()(), i0.ɵeld(10, 0, null, null, 0, "i", [["class", "fa fa-angle-double-right"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.link; _ck(_v, 3, 0, currVal_2); var currVal_7 = _co.link; _ck(_v, 8, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 3).target; var currVal_1 = i0.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = _co.header; _ck(_v, 5, 0, currVal_3); var currVal_4 = _co.body; _ck(_v, 6, 0, currVal_4); var currVal_5 = i0.ɵnov(_v, 8).target; var currVal_6 = i0.ɵnov(_v, 8).href; _ck(_v, 7, 0, currVal_5, currVal_6); var currVal_8 = _co.footerText; _ck(_v, 9, 0, currVal_8); }); }
export function View_LinkPanelItemComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "link-panel-item", [], null, null, null, View_LinkPanelItemComponent_0, RenderType_LinkPanelItemComponent)), i0.ɵdid(1, 49152, null, 0, i5.LinkPanelItemComponent, [], null, null)], null, null); }
var LinkPanelItemComponentNgFactory = i0.ɵccf("link-panel-item", i5.LinkPanelItemComponent, View_LinkPanelItemComponent_Host_0, { header: "header", footerText: "footerText", link: "link", body: "body" }, {}, []);
export { LinkPanelItemComponentNgFactory as LinkPanelItemComponentNgFactory };
