/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../shared/directives/dynamicRouterLink.directive";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./content.component";
var styles_ContentComponent = [];
var RenderType_ContentComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ContentComponent, data: {} });
export { RenderType_ContentComponent as RenderType_ContentComponent };
export function View_ContentComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "main", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 8, "article", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "header", [], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "div", [["class", "intro"], ["dynamicRouterLink", ""]], [[8, "innerHTML", 1]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 6).onClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(6, 16384, null, 0, i1.DynamicRouterLinkDirective, [i2.Router, i3.Location], null, null), (_l()(), i0.ɵeld(7, 0, null, null, 1, "div", [["class", "content"], ["dynamicRouterLink", ""]], [[8, "innerHTML", 1]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 8).onClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(8, 16384, null, 0, i1.DynamicRouterLinkDirective, [i2.Router, i3.Location], null, null), i0.ɵncd(null, 0)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getHeading(); _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.subHeading; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.bodyText; _ck(_v, 7, 0, currVal_2); }); }
export function View_ContentComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "content-component", [], null, null, null, View_ContentComponent_0, RenderType_ContentComponent)), i0.ɵdid(1, 114688, null, 0, i4.ContentComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ContentComponentNgFactory = i0.ɵccf("content-component", i4.ContentComponent, View_ContentComponent_Host_0, { pageTitle: "pageTitle", heading: "heading", subHeading: "subHeading", bodyText: "bodyText" }, {}, ["*"]);
export { ContentComponentNgFactory as ContentComponentNgFactory };
