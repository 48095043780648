/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./pagination.component";
import * as i3 from "../../../services/dictionary.service";
var styles_PaginationComponent = [];
var RenderType_PaginationComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PaginationComponent, data: {} });
export { RenderType_PaginationComponent as RenderType_PaginationComponent };
function View_PaginationComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "button", [["class", "first pagination-pages desktop"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.buttonClick(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(2, { "active": 0 }), (_l()(), i0.ɵted(3, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "first pagination-pages desktop"; var currVal_2 = _ck(_v, 2, 0, (_v.parent.context.$implicit.value() === _co.settings.page)); _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = (_v.parent.context.$implicit.notClickable && _v.parent.context.$implicit.notClickable()); _ck(_v, 0, 0, currVal_0); var currVal_3 = _v.parent.context.$implicit.content(); _ck(_v, 3, 0, currVal_3); }); }
function View_PaginationComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PaginationComponent_2)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.show(); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_PaginationComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 21, "div", [["class", "pagination table-pagination"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 20, "div", [["class", "pagination-buttons text-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 17, "div", [["class", "btn-group page-numbers"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 5, "button", [["class", "prev-button"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.prev() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(4, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(5, { "disabled": 0 }), (_l()(), i0.ɵeld(6, 0, null, null, 0, "i", [["class", "glyph-previous"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "span", [["class", "paging-btn-label"]], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PaginationComponent_1)), i0.ɵdid(10, 802816, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(11, 0, null, null, 2, "p", [["class", "btn first pagination-pages mobile pagination-page"], ["type", "button"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(13, null, ["", " of ", ""])), (_l()(), i0.ɵeld(14, 0, null, null, 5, "button", [["class", "next-button"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.next() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(15, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(16, { "disabled": 0 }), (_l()(), i0.ɵeld(17, 0, null, null, 1, "span", [["class", "paging-btn-label"]], null, null, null, null, null)), (_l()(), i0.ɵted(18, null, ["", ""])), (_l()(), i0.ɵeld(19, 0, null, null, 0, "i", [["class", "glyph-next"]], null, null, null, null, null)), (_l()(), i0.ɵeld(20, 0, null, null, 1, "span", [["class", "static"]], null, null, null, null, null)), (_l()(), i0.ɵted(21, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "prev-button"; var currVal_1 = _ck(_v, 5, 0, (_co.settings.page === 1)); _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_3 = _co.buttons; _ck(_v, 10, 0, currVal_3); var currVal_6 = "next-button"; var currVal_7 = _ck(_v, 16, 0, (_co.settings.page === _co.maxPages())); _ck(_v, 15, 0, currVal_6, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = ((_co.dictionary == null) ? null : _co.dictionary.Paging_Previous); _ck(_v, 8, 0, currVal_2); var currVal_4 = _co.settings.page; var currVal_5 = _co.maxPages(); _ck(_v, 13, 0, currVal_4, currVal_5); var currVal_8 = ((_co.dictionary == null) ? null : _co.dictionary.Paging_Next); _ck(_v, 18, 0, currVal_8); var currVal_9 = _co.pageText(); _ck(_v, 21, 0, currVal_9); }); }
export function View_PaginationComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "pagination", [], null, null, null, View_PaginationComponent_0, RenderType_PaginationComponent)), i0.ɵdid(1, 114688, null, 0, i2.PaginationComponent, [i3.DictionaryService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PaginationComponentNgFactory = i0.ɵccf("pagination", i2.PaginationComponent, View_PaginationComponent_Host_0, { settings: "settings" }, { filter: "filter" }, []);
export { PaginationComponentNgFactory as PaginationComponentNgFactory };
