import { Injectable } from "@angular/core";
import * as rxjs from "rxjs";
import { Observable, ReplaySubject } from "rxjs";
import { CMSService } from "../../services/CMS/cms.service";
import { MenuItem } from "./menuItem.data";
import { Response } from '../CMS/response.datatype'
import { NamedLink } from "../../shared/datatypes/namedlink.datatype";
import { map } from "rxjs/operators";


@Injectable()
export class MenuService {

    private subject: ReplaySubject<MenuItem[]>;

    constructor(private cms: CMSService) {
        this.subject = new ReplaySubject<MenuItem[]>(1);

        cms.getMainMenu().subscribe((response: Response<MenuItem[]>) => this.subject.next(response.data))
    }

    getMainMenu(): Observable<MenuItem[]> {
        return this.subject.asObservable()
            .pipe(
                map(items => this.filter(x => !x.hideInMainMenu, items))
            );
    }

    filter(predicate: (menu: MenuItem) => boolean, items: MenuItem[]) {
        let result = items.filter(predicate)
        result.forEach(x => x.items = this.filter(predicate, x.items))
        return result;
    }

    getSubMenu(id?: number, url?: string): Observable<MenuItem | null> {
        if (id) {
            return this.hasSubMenuWhere(menu => menu.id === id);
        } else if (url) {
            return this.hasSubMenuWhere(menu => menu.url.includes(url));
        }
        return rxjs.empty()
    }

    getBreadCrumbs(id: number): Observable<NamedLink[]> {
        return this.subject
            .pipe(
                map(menu => {
                    let result: NamedLink[] = []

                    let getItems = (item: MenuItem, parents: MenuItem[]) => {
                        if (item.id === id) {
                            parents.push(item)
                            result = parents.map((x: MenuItem) => new NamedLink(x.pageTitle, x.url))
                            return;
                        }
                        if (item.items.length === 0) {
                            return;
                        }
                        item.items.forEach((child: MenuItem) => {
                            getItems(child, [...parents, item])
                        });
                    }
                    menu.forEach(item => getItems(item, []));
                    return result;
                })
            );
    }

    private hasSubMenuWhere(predicate: (menu: MenuItem) => boolean): Observable<MenuItem | null> {
        return this.subject
            .pipe(
                map(menu => {

                    let containsSubmenu = (item: MenuItem): boolean => {
                        if (predicate(item)) {
                            return true;
                        } else {
                            return item.items.reduce((acc, val) => acc ? acc : containsSubmenu(val), false);
                        }
                    }
                    return menu.reduce((acc: MenuItem | null, val) => {
                        if (acc !== null) {
                            return acc;
                        } else if (containsSubmenu(val)) {
                            return val;
                        } else {
                            return null;
                        }
                    }, null)
                })
            );
    }
}