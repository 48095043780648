export class PriceListTld {
    name: string;
    registration: number;
    renew: number;
    transfer: number;
    groups: string[];

    constructor() {
        this.name = "";
        this.registration = 0;
        this.renew = 0;
        this.transfer = 0;
        this.groups = [];
    }
}