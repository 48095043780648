import { ActivatedRouteSnapshot, NavigationEnd, Router, RouterStateSnapshot } from "@angular/router";
import { LanguageService } from "../../services/language/language.service";
import { Injectable } from "@angular/core";
import { Location } from "@angular/common";


// This resolver reads the language part of the url and gives it to the language service, 
// spreading it to all the parts of the app automatically. 
// IMPORTANT! This has to run before every page load, otherwise there might not be any language 
// set and all kinds of things break. The best way for now is to use resolvers that inherit
// from LanguageResolver.
@Injectable()
export class LanguageResolver {
    constructor(protected languageService: LanguageService, protected router: Router, protected location: Location) {
    }

    protected validLanguage: boolean = true;

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        let language = route.params["lang"] || this.languageService.current || this.languageService.default;

        this.languageService.setLanguage(language);

        if (!route.params["lang"]) {
            let fixUrl = this.router.events.subscribe((ev: any) => {
                if (ev instanceof NavigationEnd) {
                    let parts = [language].concat(route.url.map(x => x.path));
                    let url = "/" + parts.join("/");

                    this.router.navigateByUrl(url, { replaceUrl: true });

                    fixUrl.unsubscribe();
                }
            });
        }

    }
}