<content-component [pageTitle]="pageTitle" [heading]="heading" [subHeading]="subHeading" [bodyText]="bodyText">
    <h2 #faq>{{dictionary?.Customer_Service_FAQ_Header}}</h2>

    <div class="widget">
        <form novalidate (ngSubmit)="faqSearch()">
            <div class="input-group input-group-fw">
                <label class="sr-only">{{dictionary?.Customer_Service_FAQ_Header}}</label>
                <div class="input-prepend">
                    <i class="glyph-help"></i>
                </div>
                <input [(ngModel)]="faqModel" name="faqSearch" type="text" class="form-control" placeholder="{{dictionary?.FAQ_Search_Placeholder}}" required/>
                <div class="btn-group input-inner">
                    <button type="submit" class="btn" ng-disabled="faqForm.$invalid" ng-click="search(question)">
                        <i class="fa fa-search"></i>
                        <span class="sr-only" translate="Search_Button"></span>
                    </button>
                </div>
            </div>
        </form>
    </div>

    <iframe *ngIf="faqUrl" [src]="faqUrl" width="100%" height="800px" frameborder="0" scrolling="yes" marginheight="0" marginwidth="0"></iframe>
</content-component>