import { Directive, HostListener } from "@angular/core";
import { Router } from "@angular/router";
import { Location } from '@angular/common';


@Directive({
    selector: "[dynamicRouterLink]"
})
export class DynamicRouterLinkDirective {

    constructor(private router: Router, private location: Location) {
    }

    @HostListener('click', ['$event'])
    onClick(event: any) {
        let target = event.target;
        if (this.isInternalLink(target)) {
            event.preventDefault();
            let path: string = event.target.pathname;
            path = path.replace(/\/$/, "");
            this.router.navigateByUrl(path);
        }
    }

    isInternalLink(target: any) {
        return target
            && target.target !== "_blank"
            && /^https?:/.test(target.protocol)
            && target.host.length > 0
            && target.baseURI.indexOf(target.host) >= 0
    }
}