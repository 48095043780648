<div class="hero error-page">
    <div class="inner text-center">
        <h1>{{dictionary?.Error_404_Title}}</h1>
        <p [innerHTML]="dictionary?.Error_404_Description"></p>
        <p>
            <a routerLink="/{{languageService.current}}">
                <span>{{dictionary?.Error_404_Home_Page_Link}}</span> &rarr;
            </a>
        </p>
    </div>
</div>