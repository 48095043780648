import { Component, HostListener, ChangeDetectorRef } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { DictionaryService } from "../../services/dictionary.service";
import { ApiService } from "../../services/API/api.interface.service";
import { Domain, LoadableDomain, LoadableTldGroup } from "./domain.interface";
import { CurrencyService } from "../../services/currency.service";
import { BasketService } from "../../services/basket/basket.service";
import { forkJoin, Observable, of } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { SearchDomainsService } from "./searchDomains.service";

@Component({
    templateUrl: "./searchDomains.component.html"
})
export class SearchDomainsComponent {
    public dictionary: any;
    public defaultTldGroups: string[] =  ["POPULAR"];
    public domainMap: { [index: string]: LoadableDomain } = {};
    public isLoading: boolean = true;

    public tldGroupsList: string[] = [] ;
    public recommended: LoadableDomain[] = [];
    public popular: LoadableDomain[] = [];
    public numberOfPromos = 5;
    public promos: (LoadableDomain | null)[] = Array(this.numberOfPromos).fill(null);
    public headerDomain: Domain | null = null;

    public tldGroupCounter = 0;
    public loadedTlds = 0;
    public loadMoreIncrement = 15;
    public loadingTldGroups: boolean = false;
    public allTldsInGroupLoaded: boolean = false;
    public domainName: string;
    public tldGroups: LoadableTldGroup[] = [];
    public tldGroupsLoaded : LoadableTldGroup[] = [];

    @HostListener("window:scroll", ["$event"])
    onWindowScroll() {
        //In chrome and some browser scroll is given to body tag
        let pos = window.pageYOffset;
        let max = document.body.offsetHeight - window.innerHeight;
        // pos/max will give you the distance between scroll bottom and and bottom of screen in percentage.
        if(max - pos < 1000)   {
            this.loadMore();
        }
    }

    constructor(public route: ActivatedRoute,
                public api: ApiService,
                public searchDomainsService: SearchDomainsService,
                dictionaryService: DictionaryService,
                currencyService: CurrencyService,
                basketService: BasketService,
                ) 
    {

        dictionaryService.getDictionary().subscribe(d => {
            this.dictionary = d;
        })

        route.queryParams.subscribe(params => {
            if (params && params.domainname) {
                this.resetSearch()
                basketService.setBasketData();

                this.isLoading = true;
                let query: string = params.domainname.toLowerCase();
                let domainParts = query.split(".");
                this.domainName = domainParts[0];
                let queryTld = domainParts.slice(1, domainParts.length).join(".");
                this.headerDomain = null;

                forkJoin([api.getGroup(), api.getGroups(this.defaultTldGroups)])
                    .subscribe(responseList => {

                        let tldGroups = responseList[0].tldgroups;
                        let recommended = responseList[0].tldgroups.RECOMMENDED
                        let [popular] = responseList[1];

                        //Set header domain
                        currencyService.current.subscribe(() => {
                                this.headerDomain = null;

                                api.getDomainPrice(this.getHeaderDomainTld(queryTld, recommended))
                                    .pipe(
                                        mergeMap(price => {
                                            return searchDomainsService.mapDomain(this.domainName, this.getHeaderDomainTld(queryTld, recommended) , price)
                                        })
                                    )
                                    .subscribe(dom => this.headerDomain = dom);
                        });

                        this.tldGroupsList = searchDomainsService.getTldGroupsOrder(Object.keys(tldGroups));

                        this.tldGroupsList = this.tldGroupsList.filter(tldGroup => this.defaultTldGroups.includes(tldGroup) === false);
                        this.tldGroupsList.forEach(tldName => {
                            this.tldGroups.push(
                                {
                                    name: tldName,
                                    data: [],
                                    tldGroupResponse: {}
                                });
                        });

                        this.isLoading = false;
                        this.domainMap = {};

                        this.recommended = searchDomainsService.mapTldSubjects(this.domainMap, recommended, this.domainName);
                        this.popular = searchDomainsService.mapTldSubjects(this.domainMap, popular, this.domainName);

                        this.loadedTlds += this.loadMoreIncrement;
                        this.promos = this.recommended.slice(0, this.numberOfPromos);
                    }); 
            }
        });
    }

    resetSearch() {
        this.domainMap = {};
        this.tldGroups = [];
        this.loadedTlds = 0;
        this.loadMoreIncrement = 15;
        this.tldGroupCounter = 0;
        this.tldGroupsLoaded = [];
    }

    tldGroupIsLoaded(tldGroup: LoadableTldGroup){
        return this.tldGroupsLoaded.find(function(loadedTldGroup){
            return loadedTldGroup.name === tldGroup.name;
        });
    }
    
    getHeaderDomainTld(queryTld: string, recommended: any) {
        if(queryTld.length > 0) 
        {
            return queryTld;
        }

        return Object.keys(recommended)[0];
    }

    getRange(object: any, from: number, to: number): any {
        let result:any = {};

        let keys = Object.keys(object);
        let length = keys.length;

        if(to > length){
            to = length;
        }

        for (let index = from; index < to; index++) {
            let key = keys[index];
            result[key] = object[key];
          }

          return result;
    }

    isEmpty(obj:any): boolean {
        return Object.keys(obj).length === 0;
    }

    loadTldGroup(tldGroupToLoad: LoadableTldGroup) {
        if(!this.loadingTldGroups) {
            this.allTldsInGroupLoaded = false;
            this.loadingTldGroups = true;
            this.tldGroupsLoaded.push(tldGroupToLoad);

            this.api.getGroups([tldGroupToLoad.name])
                .subscribe(groupList => {
                    let group = groupList[0]

                    this.loadedTlds = 0;
                    let currentTldGroup = this.getCurrenTldGroup();
                    let currentTldGroupRange = this.getRange(group, this.loadedTlds, this.loadedTlds + this.loadMoreIncrement);
                    this.loadedTlds += Object.keys(currentTldGroupRange).length;
                    currentTldGroup.data = this.searchDomainsService.mapTldSubjects(this.domainMap, currentTldGroupRange , this.domainName);
                    currentTldGroup.tldGroupResponse = group;

                    this.loadingTldGroups = false;
                });

            this.tldGroupCounter++;
        }
    }

    getCurrenTldGroup(): LoadableTldGroup {
        return this.tldGroupsLoaded[this.tldGroupsLoaded.length - 1];   
    }

    loadMore() {
        if(this.tldGroupsLoaded.length === 0) return;
        if(this.allTldsInGroupLoaded) return;

        let currentTldGroup = this.getCurrenTldGroup();
        let nextTlds = this.getRange(currentTldGroup.tldGroupResponse, this.loadedTlds, this.loadedTlds + this.loadMoreIncrement);
        let mappedNextTlds = this.searchDomainsService.mapTldSubjects(this.domainMap, nextTlds, this.domainName);

        var anyTldsToAdd = mappedNextTlds.length > 0;

        if(anyTldsToAdd) {
            var loadMoreTldsThanAvailable = mappedNextTlds.length < this.loadMoreIncrement;

            if(loadMoreTldsThanAvailable) {
                this.allTldsInGroupLoaded = true;
            }

            currentTldGroup.data = [ ...currentTldGroup.data, ...mappedNextTlds];
            this.loadedTlds += mappedNextTlds.length;
        }
        
    }
} 

