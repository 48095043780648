<!-- <div class="hero" [style.background]="backgroundImage">  -->
<div class="carousel-wrapper">
    <div class="carousel-slider">
        <main-slider *ngIf="carouselItems && carouselItems.length > 0" [items]="carouselItems"></main-slider>
    </div>
    <div class="promo-slider-container">
        <promo-slider [tlds]="promosliderTlds"></promo-slider>
    </div>
</div>


<div class="container">
    <panel-list id="services" class="same-height">
        <div class="row">
            <news-panel></news-panel>
            <who-is-panel></who-is-panel>
            <link-panel-item *ngFor="let panel of getPanelItems()" [header]="panel.title" [body]="panel.body" link="/{{languageService.current}}/{{panel.link.url}}"
                [footerText]="panel.linkTitle">
            </link-panel-item>
            <panel-expand-button (onExpand)="showAllPanelItems = true" (onCollapse)="showAllPanelItems = false"></panel-expand-button>
        </div>
    </panel-list>
</div>