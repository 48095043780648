///<reference path="../../../../../node_modules/@angular/core/src/metadata/directives.d.ts"/>
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";
import { LanguageService } from "../../../services/language/language.service";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { IContentComponent } from "../contentComponent/IContentComponent.interface";
import { ContentComponent } from "../contentComponent/content.component";
import { DictionaryService } from "../../../services/dictionary.service";
import { combineLatest } from "rxjs/operators";

@Component({
    templateUrl: "./customerService.component.html"
})
export class CustomerServiceComponent extends ContentComponent implements IContentComponent, AfterViewInit {
    pageTitle: string;
    heading: string;
    subHeading: string;
    bodyText: string;
    faqUrl: SafeResourceUrl;
    urls: any = {
        "sv": "https://nameispswe.imbox.io",
        "en": "https://nameispeng.imbox.io",
        "es": "https://nameispeng.imbox.io"
    };
    faqModel: string;
    dictionary: any;
    @ViewChild("faq") faqElem: ElementRef;

    constructor(private changeRef: ChangeDetectorRef,
                route: ActivatedRoute,
                private languageService: LanguageService,
                private sanitizer: DomSanitizer,
                private location: Location,
                dictionaryService: DictionaryService) {
        super();
        dictionaryService.getDictionary().subscribe(dictionary => this.dictionary = dictionary);
        languageService.getLanguage()
            .pipe(combineLatest(route.queryParams))
            .subscribe(items => {
                let [lang, params] = items;
                let question = params["question"];
                if (question) {
                    this.scrollToFaq();
                }
                this.setFaqUrl(lang, question);
                this.faqModel = question;
            })
    }

    sideMenu: boolean;

    setData(data: any): void {
        this.pageTitle = data.pageTitle;
        this.bodyText = data.bodyText;
        this.heading = data.heading;
        this.subHeading = data.subHeading;
        this.sideMenu = false;
        this.changeRef.detectChanges();
    }

    ngAfterViewInit(): void {
        if (this.faqModel) {
            this.scrollToFaq();
        }
    }

    scrollToFaq() {
        if (!this.faqElem || typeof window === "undefined") return;
        this.faqElem.nativeElement.scrollIntoView();
        window.scrollBy(0, -80) //Offset because of header
    }

    faqSearch() {
        if (!this.faqModel || this.faqModel.trim() === "") return;
        this.location.go(location.pathname, "?question=" + this.faqModel);
        this.setFaqUrl(this.languageService.current, this.faqModel)
    }

    setFaqUrl(lang: string, question?: string) {
        let query = question ? "/search/" + encodeURIComponent(question as string) : "";
        let url = this.urls[lang] + query;
        this.faqUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
}