var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
///<reference path="../../../../../node_modules/@angular/core/src/metadata/directives.d.ts"/>
import { AfterViewInit, ChangeDetectorRef, ElementRef } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";
import { LanguageService } from "../../../services/language/language.service";
import { DomSanitizer } from "@angular/platform-browser";
import { ContentComponent } from "../contentComponent/content.component";
import { DictionaryService } from "../../../services/dictionary.service";
import { combineLatest } from "rxjs/operators";
var CustomerServiceComponent = /** @class */ (function (_super) {
    __extends(CustomerServiceComponent, _super);
    function CustomerServiceComponent(changeRef, route, languageService, sanitizer, location, dictionaryService) {
        var _this = _super.call(this) || this;
        _this.changeRef = changeRef;
        _this.languageService = languageService;
        _this.sanitizer = sanitizer;
        _this.location = location;
        _this.urls = {
            "sv": "https://nameispswe.imbox.io",
            "en": "https://nameispeng.imbox.io",
            "es": "https://nameispeng.imbox.io"
        };
        dictionaryService.getDictionary().subscribe(function (dictionary) { return _this.dictionary = dictionary; });
        languageService.getLanguage()
            .pipe(combineLatest(route.queryParams))
            .subscribe(function (items) {
            var lang = items[0], params = items[1];
            var question = params["question"];
            if (question) {
                _this.scrollToFaq();
            }
            _this.setFaqUrl(lang, question);
            _this.faqModel = question;
        });
        return _this;
    }
    CustomerServiceComponent.prototype.setData = function (data) {
        this.pageTitle = data.pageTitle;
        this.bodyText = data.bodyText;
        this.heading = data.heading;
        this.subHeading = data.subHeading;
        this.sideMenu = false;
        this.changeRef.detectChanges();
    };
    CustomerServiceComponent.prototype.ngAfterViewInit = function () {
        if (this.faqModel) {
            this.scrollToFaq();
        }
    };
    CustomerServiceComponent.prototype.scrollToFaq = function () {
        if (!this.faqElem || typeof window === "undefined")
            return;
        this.faqElem.nativeElement.scrollIntoView();
        window.scrollBy(0, -80); //Offset because of header
    };
    CustomerServiceComponent.prototype.faqSearch = function () {
        if (!this.faqModel || this.faqModel.trim() === "")
            return;
        this.location.go(location.pathname, "?question=" + this.faqModel);
        this.setFaqUrl(this.languageService.current, this.faqModel);
    };
    CustomerServiceComponent.prototype.setFaqUrl = function (lang, question) {
        var query = question ? "/search/" + encodeURIComponent(question) : "";
        var url = this.urls[lang] + query;
        this.faqUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    };
    return CustomerServiceComponent;
}(ContentComponent));
export { CustomerServiceComponent };
