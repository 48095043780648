import { ChangeDetectorRef, Component } from "@angular/core";
import { ContentComponent } from "../contentComponent/content.component";
import { IContentComponent } from "../contentComponent/IContentComponent.interface";


@Component({
    templateUrl: "./allTlds.component.html"
})
export class AllTldsComponent extends ContentComponent implements IContentComponent {
    public sideMenu: boolean;

    constructor(private changeRef: ChangeDetectorRef) {
        super()
    }

    setData(data: any): void {
        this.pageTitle = data.pageTitle;
        this.bodyText = data.bodyText;
        this.heading = data.heading
        this.subHeading = data.subHeading;
        this.sideMenu = data.sideMenu;
        this.changeRef.detectChanges();
    }
}