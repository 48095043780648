import { Injectable } from "@angular/core";
import { CMSService } from "../CMS/cms.service";
import { Observable, throwError } from "rxjs";
import { Page } from "./page.datatype";
import { HomePage } from "../../components/home/homePage.datatype";
import { UrlService } from "../url.service";
import { ContentCategoryPage } from "../../components/content/contentCategory/ContentCategoryPage.data";
import { ContentPage } from "../../shared/datatypes/contentPage.data";
import { BasePage } from "../../shared/datatypes/basePage.data";
import { MetaTagService } from "../metaTag.service";
import { TopLevelDomainPage } from "../../components/content/topLevelDomain/topLevelDomainPage.data";
import { catchError, map, tap } from "rxjs/operators";

@Injectable()
export class PageService {
    constructor(private cms: CMSService, private url: UrlService, private metaTagService: MetaTagService) {
    }

    public getHomePage(): Observable<Page<HomePage>> {
        return this.getPage<HomePage>(null, "/", {
            panelItems: { take: 100, evaluate: true },
            mainSlider: { take: 10, evaluate: true }
        })
    }

    public getFooter(): Observable<Page<any>> {
        return this.getPage(null, "/footer")
    }

    public getContentCategory(category: string | null): Observable<Page<ContentCategoryPage>> {
        return this.getPage<ContentCategoryPage>(null, this.url.buildUrl(category), {
            panelItems: {
                take: 100,
                evaluate: true
            }
        });
    }

    public getContent(category: string | null, content: string | null): Observable<Page<any>> {
        let url = this.url.buildUrl(category, content)
        return this.getPage<any>(null, url, { panelItems: { take: 100, evaluate: true } });
    }

    public getCustomerService(): Observable<Page<ContentPage>> {
        let url = this.url.buildUrl("customer-service");
        return this.getPage<ContentPage>(null, url, null);
    }

    public getTopLevelDomain(domain: string): Observable<TopLevelDomainPage | null> {
        let url = this.url.buildUrl("domain-names", "top-level-domain", domain);
        return this.cms.getPages<TopLevelDomainPage>(null, url, null)
            .pipe(
                map(response => {
                    if (response.success) {
                        let data = response.data[0];
                        delete data.customProperties.country;
                        this.metaTagService.addMetaTags(data.customProperties);
                        return data.customProperties;
                    }
                    return null
                })
            );
    }

    public getPage<T extends BasePage>(id?: any, url?: any, custom?: any): Observable<Page<T>> {
        return this.cms.getPages<T>(id, url, custom)
            .pipe(
                catchError(error => {
                    console.log(error);
                    return throwError(error);
                }),
                map(response => {
                    if (!response.data) {
                        return throwError("No page found") as any;
                    }
                    return response.data[0]
                }),
                tap(data => {
                    this.metaTagService.addMetaTags(data.customProperties);
                })
            )
    }
}