import { Component, OnInit } from "@angular/core";
import { ModalComponent } from "../modal/modal.component";
import { ApiService } from "../../services/API/api.interface.service";
import { DictionaryService } from "../../services/dictionary.service";
import { replaceToken } from "../util";
import { BsModalRef } from "ngx-bootstrap/modal";

@Component({
    selector: "whois-modal",
    templateUrl: "./whoisModal.component.html"
})
export class WhoisModalComponent extends ModalComponent implements OnInit {
    // @Input()
    domainName: string;

    dictionary: any;
    loaded = false;
    content: string | null;
    title: string;

    constructor(public bsModalRef: BsModalRef, private api: ApiService, dictionaryService: DictionaryService) {
        super(bsModalRef);
        this.modalId = "whois-modal";
        dictionaryService.getDictionary().subscribe(dictionary => {
            this.dictionary = dictionary;
        })
    }

    ngOnInit() {
        this.api.whois(this.domainName).subscribe(text => {
            this.loaded = true;
            if (text) {
                text = text.replace(new RegExp('\n', 'g'), "<br />");
            }
            this.content = text;
            this.title = replaceToken(this.dictionary.Whois_Modal_Title, "domainName", this.domainName)
        })
    }

    onClose() {
        this.loaded = false;
        this.content = null;
    }
}