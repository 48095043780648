import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { PaginationSettings } from "./paginationSettings.datatype";
import { DictionaryService } from "../../../services/dictionary.service";

@Component({
    selector: "pagination",
    templateUrl: "./pagination.component.html"
})
export class PaginationComponent implements OnInit {

    @Input() settings: PaginationSettings
    @Output() filter = new EventEmitter<Function>()

    buttons: PaginationButton[]

    public dictionary: any;

    constructor(dictionaryService: DictionaryService) {
        dictionaryService.getDictionary().subscribe(d => {
            this.dictionary = d;
        })
    }

    constructButtons(): PaginationButton[] {

        type display = number | string;

        let thresholdValues = (lower: any, between: any, higher: any) => {
            if (this.settings.page < 6 || this.maxPages() < 10) {
                return lower;
            } else if (this.maxPages() - 5 < this.settings.page) {
                return higher;
            } else {
                return between;
            }
        }

        return [{
            value: () => 1,
            content: () => "1",
            show: () => true,
        },
            {
                value: () => thresholdValues(2, null, null),
                content: () => thresholdValues(2, "...", "..."),
                show: () => this.maxPages() > 1,
                notClickable: () => thresholdValues(false, true, true)
            },
            {
                value: () => thresholdValues(3, this.settings.page - 2, this.maxPages() - 6),
                content: () => thresholdValues(3, this.settings.page - 2, this.maxPages() - 6),
                show: () => this.maxPages() > 2
            },
            {
                value: () => thresholdValues(4, this.settings.page - 1, this.maxPages() - 5),
                content: () => thresholdValues(4, this.settings.page - 1, this.maxPages() - 5),
                show: () => this.maxPages() > 3
            },
            {
                value: () => thresholdValues(5, this.settings.page, this.maxPages() - 4),
                content: () => thresholdValues(5, this.settings.page, this.maxPages() - 4),
                show: () => this.maxPages() > 4
            },
            {
                value: () => thresholdValues(6, this.settings.page + 1, this.maxPages() - 3),
                content: () => thresholdValues(6, this.settings.page + 1, this.maxPages() - 3),
                show: () => this.maxPages() > 5
            },
            {
                value: () => thresholdValues(7, this.settings.page + 2, this.maxPages() - 2),
                content: () => thresholdValues(7, this.settings.page + 2, this.maxPages() - 2),
                show: () => this.maxPages() > 6
            },
            {
                value: () => this.maxPages() > 9 ? thresholdValues(null, null, this.maxPages() - 1) : 8,
                content: () => this.maxPages() > 9 ? thresholdValues("...", "...", this.maxPages() - 1) : 8,
                show: () => this.maxPages() > 7,
                notClickable: () => this.maxPages() > 9 ? thresholdValues(true, true, false) : false,
            },
            {
                value: () => this.maxPages(),
                content: () => this.maxPages(),
                show: () => this.maxPages() > 8
            }]
    }

    constructMobileButtons(): PaginationButton[] {
        return [];
    }

    ngOnInit() {
        this.filter.emit(this.pagingFunction())
        this.buttons = this.constructButtons();
    }

    pagingFunction() {
        return (list: any[]) => {
            list = list.slice(this.settings.pageSize * (this.settings.page - 1))
            list = list.slice(0, this.settings.pageSize)
            return list;
        }
    }

    prev() {
        if (this.settings.page > 1) {
            this.settings.page--;
            this.filter.emit(this.pagingFunction());
        }
    }

    next() {
        if (this.settings.page < this.maxPages()) {
            this.settings.page++;
            this.filter.emit(this.pagingFunction());
        }
    }

    goToPage(page: number) {
        this.settings.page = page;
        this.filter.emit(this.pagingFunction());
    }

    buttonClick(button: PaginationButton) {
        if (button.notClickable && button.notClickable()) {
            return;
        } else {
            this.goToPage(button.value() as number)
        }
    }

    pageText() {
        let lower = this.settings.pageSize * (this.settings.page - 1);
        let higher = lower + this.settings.pageSize;
        if (this.settings.total > 0) {
            lower += 1;
        }
        higher = higher > this.settings.total ? this.settings.total : higher;
        return `${lower}-${higher} of ${this.settings.total}`
    }

    public maxPages() {
        return Math.ceil(this.settings.total / this.settings.pageSize);
    }
}

interface PaginationButton {
    value: () => number | string;
    content: () => string | number;
    show: () => boolean;
    notClickable?: () => boolean
}