import { NgModule } from '@angular/core';
import { Route, RouterModule, Routes, UrlMatchResult, UrlSegment, UrlSegmentGroup } from '@angular/router';

import { HomeComponent } from './components/home/home.component';
import { DictionaryResolver } from './shared/resolvers/dictionary.resolver';
import { PageNotFoundComponent } from './components/error/pageNotFound/pageNotFound.component';
import { Languages } from './services/language/language.constants';
import { InternalServerErrorComponent } from './components/error/internalServerError/internalServerError.component';
import { SearchDomainsComponent } from './components/searchDomains/searchDomains.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { SearchDomainsResolver } from './components/searchDomains/searchDomains.resolver';
import { LanguageResolver } from './shared/resolvers/language.resolver';
import { TopLevelDomainComponent } from './components/content/topLevelDomain/topLevelDomain.component';
import { TopLevelDomainResolver } from './components/content/topLevelDomain/topLevelDomain.resolver';
import { ContentContainerComponent } from './components/content/contentContainer.component';
import { ContentResolver } from './components/content/content.resolver';

function isLangInUrl(urlSegments: UrlSegment[]) {

    let languages = Languages;
    let urlLang = urlSegments.length > 0 ? urlSegments[0].path : "";

    if (languages.map(l => l.code).indexOf(urlLang) > -1) {
        return true;
    }

    return false;

}

function buildRouteMatcher(path: string) {

    var pathParts = path == "" ? [] : path.split("/");
    var paramSegment = (x: any) => new UrlSegment(x, {});

    return function (urlSegments: UrlSegment[], group: UrlSegmentGroup, route: Route): UrlMatchResult {

        let posParams: any = {};
        let urlParts = urlSegments.map(x => x.path);

        if (isLangInUrl(urlSegments)) {
            posParams.lang = paramSegment(urlParts.shift());
        }

        if (pathParts.length !== urlParts.length) return null as any;

        for (let i = 0; i < pathParts.length; i++) {
            if (pathParts[i][0] == ":") {
                let param = pathParts[i].substr(1);
                posParams[param] = paramSegment(urlParts[i]);
            } else {
                if (pathParts[i] !== urlParts[i]) {
                    return null as any;
                }
            }
        }

        return { consumed: urlSegments, posParams: posParams };

    }

}

export function HomeRouteMatcher(urlSegments: UrlSegment[], group: UrlSegmentGroup, route: Route): UrlMatchResult {
    return buildRouteMatcher("")(urlSegments, group, route);
}

export function PageNotFoundMatcher(urlSegments: UrlSegment[], group: UrlSegmentGroup, route: Route): UrlMatchResult {
    return buildRouteMatcher("pagenotfound")(urlSegments, group, route);
}

export function InternalServerErrorMatcher(urlSegments: UrlSegment[], group: UrlSegmentGroup, route: Route): UrlMatchResult {
    return buildRouteMatcher("internalservererror")(urlSegments, group, route);
}

export function SearchDomainsMatcher(urlSegments: UrlSegment[], group: UrlSegmentGroup, route: Route): UrlMatchResult {
    return buildRouteMatcher("searchdomains")(urlSegments, group, route);
}

export function CheckoutMatcher(urlSegments: UrlSegment[], group: UrlSegmentGroup, route: Route): UrlMatchResult {
    return buildRouteMatcher("checkout")(urlSegments, group, route);
}

export function TopLevelDomainMatcher(urlSegments: UrlSegment[], group: UrlSegmentGroup, route: Route): UrlMatchResult {
    return buildRouteMatcher("domain-names/top-level-domain/:domain")(urlSegments, group, route);
}

export function FirstLevelPageMatcher(urlSegments: UrlSegment[], group: UrlSegmentGroup, route: Route): UrlMatchResult {
    return buildRouteMatcher(":first")(urlSegments, group, route);
}

export function SecondLevelPageMatcher(urlSegments: UrlSegment[], group: UrlSegmentGroup, route: Route): UrlMatchResult {
    return buildRouteMatcher(":first/:second")(urlSegments, group, route);
}

export const routes: Routes = [
    {
        matcher: HomeRouteMatcher,
        component: HomeComponent,
        pathMatch: 'full',
        resolve: { dictionary: DictionaryResolver },
        loadChildren: ''
    },
    {
        matcher: PageNotFoundMatcher,
        component: PageNotFoundComponent,
        pathMatch: 'full',
        resolve: { dictionary: DictionaryResolver }
    },
    {
        matcher: InternalServerErrorMatcher,
        component: InternalServerErrorComponent,
        pathMatch: 'full',
        resolve: { dictionary: DictionaryResolver }
    },
    {
        matcher: SearchDomainsMatcher,
        component: SearchDomainsComponent,
        pathMatch: 'full',
        resolve: { data: SearchDomainsResolver }
    },
    {
        matcher: CheckoutMatcher,
        component: CheckoutComponent,
        pathMatch: 'full',
        resolve: { data: LanguageResolver } },
    {
        matcher: TopLevelDomainMatcher,
        component: TopLevelDomainComponent,
        pathMatch: "full",
        resolve: { domain: TopLevelDomainResolver }
    },
    {
        matcher: FirstLevelPageMatcher,
        component: ContentContainerComponent,
        pathMatch: "full",
        resolve: { content: ContentResolver }
    },
    {
        matcher: SecondLevelPageMatcher,
        component: ContentContainerComponent,
        pathMatch: "full",
        resolve: { content: ContentResolver }
    },
    { path: '**', redirectTo: "/404"}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
