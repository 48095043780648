import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PanelListComponent } from "./panelList.component";
import { RouterModule } from "@angular/router";
import { PanelExpandButtonComponent } from "./panelExpandButton/panelExpandButton.component";


@NgModule({
    declarations: [
        PanelListComponent,
        PanelExpandButtonComponent
    ],
    imports: [
        CommonModule,
        RouterModule
    ],
    exports: [
        PanelListComponent,
        PanelExpandButtonComponent
    ]
})
export class PanelListModule {

}