/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./newsPanelRow.component";
var styles_NewsPanelRow = [];
var RenderType_NewsPanelRow = i0.ɵcrt({ encapsulation: 2, styles: styles_NewsPanelRow, data: {} });
export { RenderType_NewsPanelRow as RenderType_NewsPanelRow };
function View_NewsPanelRow_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-bell"]], null, null, null, null, null))], null, null); }
export function View_NewsPanelRow_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "td", [["class", "date max-3"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""])), (_l()(), i0.ɵeld(2, 0, null, null, 2, "td", [["class", "max-1"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NewsPanelRow_1)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.item.isRecent; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item.dateString; _ck(_v, 1, 0, currVal_0); var currVal_2 = _co.item.title; _ck(_v, 6, 0, currVal_2); }); }
export function View_NewsPanelRow_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["news-panel-row", ""]], null, null, null, View_NewsPanelRow_0, RenderType_NewsPanelRow)), i0.ɵdid(1, 49152, null, 0, i2.NewsPanelRow, [], null, null)], null, null); }
var NewsPanelRowNgFactory = i0.ɵccf("[news-panel-row]", i2.NewsPanelRow, View_NewsPanelRow_Host_0, { item: "item" }, {}, []);
export { NewsPanelRowNgFactory as NewsPanelRowNgFactory };
