import { Injectable } from "@angular/core";
import { TLDInfo } from "../API/tldInfo.data";
import { precisionRound } from "../../shared/util";
import { PriceType } from "./priceclass.enum";

@Injectable()
export class PriceService {

    public vatRate = 0.2

    public getPrice(prices: any, priceType: PriceType, currency: string) {
        var price = this.searchOjectWithKey(prices, priceType);

        if (!price) return 0;
        let result = 0;
        if (currency === "SEK") {
            result = price.total;
        } else {
            result = price.currencies[currency].total;
        }
        return precisionRound(result, 2)
    }

    public getPrices(tld: TLDInfo, currency: string): Price[] {
        let result: Price[] = [];
        if (!tld.prices) return [];
        let prices = tld.prices;
        let regY = tld.regY ? Number(tld.regY[0]) : 0;
        let renewY = tld.renewY ? Number(tld.renewY[0]) : 0;

        let addPrice = (name: string, prices: any, priceType: PriceType, years: number) => {
            if (prices) {
                let price = new Price(name, this.getPrice(prices, priceType, currency), years);
                result.push(price);
            }
        }

        addPrice("Top_Level_Domain_Prices_Registration", prices, PriceType.Registration, regY);
        addPrice("Top_Level_Domain_Prices_Renewal", prices, PriceType.Renew, renewY);
        addPrice("Top_Level_Domain_Prices_Owner_Transfer", prices, PriceType.OwnerChange, 0);
        addPrice("Top_Level_Domain_Prices_Transfer", prices, PriceType.Transfer, 0);
        addPrice("Top_Level_Domain_Prices_Restore", prices, PriceType.Restore, 0);
        addPrice("Top_Level_Domain_Prices_Trustee", prices, PriceType.Trustee, 0);

        return result;
    }

    public searchOjectWithKey(obj: any, wantedKey:string): any {

        if(obj.hasOwnProperty(wantedKey)) {
            return obj[wantedKey];
        }

        var keys = Object.keys(obj);
        if(keys.length === 0) {
            return null
        }

        for(let key of keys) {
            if(typeof(obj[key]) === "object"){
                return this.searchOjectWithKey(obj[key], wantedKey); 
            }
            
        }

        return null;
    }
}

export class Price {
    constructor(public name: string, public value: number, public years: number) {
    }
}