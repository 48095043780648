import { Component, EventEmitter, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { LanguageService } from "../../../../services/language/language.service";
import { DictionaryService } from "../../../../services/dictionary.service";
import { ModalService } from "../../../../services/modal.service";
import { WhoisModalComponent } from "../../../../shared/whoisModal/whoisModal.component";
import { simpleDomainPattern } from "../../../../shared/validationPatterns.constants";
import { NgForm } from "@angular/forms";

@Component({
    selector: "header-search",
    templateUrl: "./headerSearch.component.html"
})
export class HeaderSearchComponent {
    @ViewChild("whoisform")
    form: NgForm
    dictionary: any;
    public model: string = "";
    public Search = {
        Domain: 1,
        WhoIs: 2,
        FAQ: 3
    }
    public showOptions: boolean = false;
    public searchOptions: SearchOption[] = [
        {
            id: this.Search.Domain,
            label: "Header_Search_Domain",
            placeholder: "Header_Search_Domain_Placeholder",
            glyph: "glyph-domains"
        },
        {
            id: this.Search.WhoIs,
            label: "Header_Search_Whois",
            placeholder: "Header_Search_Whois_Placeholder",
            glyph: "glyph-whois"
        },
        {
            id: this.Search.FAQ,
            label: "Header_Search_FAQ",
            placeholder: "Header_Search_FAQ_Placeholder",
            glyph: "glyph-help"
        }
    ]
    public selectedOption = this.searchOptions[0];
    public whoisPattern = simpleDomainPattern;
    openWhoisPopover = new EventEmitter<void>()

    constructor(private router: Router,
                route: ActivatedRoute,
                private languageService: LanguageService,
                dictionaryService: DictionaryService,
                private modalService: ModalService) {
        dictionaryService.getDictionary().subscribe(d => this.dictionary = d);
        route.queryParams.subscribe(params => {
            if (params["domainname"]) {
                this.model = params["domainname"];
            }
            if (params["question"]) {
                this.model = params["question"];
            }
        });
    }

    getTranslation(key: string) {
        return this.dictionary ? this.dictionary[key] : "";
    }

    setOption(option: SearchOption) {
        this.selectedOption = option;
        this.onSubmitSearch();
        this.toggleOptions();
    }

    toggleOptions() {
        this.showOptions = !this.showOptions;
    }

    isActive(option: SearchOption): string {
        return option.id === this.selectedOption.id ? "active" : "";
    }

    clickOutside() {
        this.showOptions = false;
    }

    onSubmitSearch() {
        this.model = this.stripWWW(this.model)
        if (this.model === "") return;
        switch (this.selectedOption.id) {
            case this.Search.Domain:
                let url = `/${this.languageService.current}/searchdomains?domainname=${encodeURIComponent(this.model)}`;
                this.router.navigateByUrl(url);
                break;
            case this.Search.WhoIs:
                if (this.form.invalid) {
                    this.openWhoisPopover.emit();
                    return;
                }
                ;
                let modalOptions = {
                    domainName: this.model
                }
                this.modalService.openModal(WhoisModalComponent, modalOptions)
                return
            case this.Search.FAQ:
                url = `/${this.languageService.current}/customer-service?question=${encodeURIComponent(this.model)}`;
                this.router.navigateByUrl(url);
                return;
            default:
                return;
        }
    }

    stripWWW(fullDomain: string) {
        if (!fullDomain) return "";
        var httpFilter = /^(https?:\/\/)?(.*?)(\/)?$/; // Strip leading [http(s)://] and trailing /
        var wwwFilter = /^(www\.+)(.*?)$/; // Strip leading [www.]
        return fullDomain.replace(httpFilter, "$2").replace(wwwFilter, "$2");
    }
}

interface SearchOption {
    id: number;
    label: string;
    placeholder: string;
    glyph: string;
}