import { ChangeDetectorRef, Component } from "@angular/core";
import { BasePage } from "../../../shared/datatypes/basePage.data";
import { IContentComponent } from "../contentComponent/IContentComponent.interface";
import { DictionaryService } from "../../../services/dictionary.service";

@Component({
    selector: "whois-lookup",
    templateUrl: "./whoisLookup.component.html"
})
export class WhoisLookupComponent extends BasePage implements IContentComponent {
    sideMenu: boolean;
    dictionary: any;

    constructor(private changeRef: ChangeDetectorRef, dictionaryService: DictionaryService) {
        super();

        dictionaryService.getDictionary().subscribe(dictionary => this.dictionary = dictionary);
    }

    setData(data: any): void {
        this.pageTitle = data.pageTitle;
        this.bodyText = data.bodyText;
        this.subHeading = data.subHeading;
        this.sideMenu = data.sideMenu;
        this.changeRef.detectChanges();
    }
}