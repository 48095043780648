import { Component } from "@angular/core";
import { DictionaryService } from "../../../services/dictionary.service";
import { ActivatedRoute } from "@angular/router";
import { Location } from '@angular/common';
import { LanguageService } from "../../../services/language/language.service";
import { ErrorPageComponent } from "../errorPage.component";

@Component({
    templateUrl: "./pageNotFound.component.html"
})
export class PageNotFoundComponent extends ErrorPageComponent {
    constructor(dictionaryService: DictionaryService, route: ActivatedRoute, location: Location, public languageService: LanguageService) {
        super(dictionaryService, route, location, languageService)
    }
}