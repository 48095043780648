import { Component } from "@angular/core";
import { ApiService } from "../../../services/API/api.interface.service";
import { LanguageService } from "../../../services/language/language.service";
import { NewsItem } from "./newsItem.interface";
import { DictionaryService } from "../../../services/dictionary.service";

@Component({
    selector: "news-panel",
    templateUrl: "./newsPanel.component.html"
})
export class NewsPanelComponent {

    newsUrl: string;
    newsRows: NewsItem[];
    dictionary: any;
    isLoading = true;

    constructor(private api: ApiService, private languageService: LanguageService, dictionaryService: DictionaryService) {
        dictionaryService.getDictionary().subscribe(d => this.dictionary = d);
        languageService.getLanguage().subscribe(lang => {
            this.newsUrl = `http://www.mynewsdesk.com/se/tag/nameisp-${lang}/news`;
            this.updateNewsRows(lang);
        });
    }

    updateNewsRows(language: string) {
        this.isLoading = true;
        this.api.newsFeed(language).subscribe(data => {
            this.isLoading = false;
            if (data && data.entries) {
                this.newsRows = data.entries.map((entry: any) => new NewsItem(entry.title, new Date(entry.pubDate), entry.link));
            }
        });
    }

    onClick(item: NewsItem) {
        window.open(item.link, "_blank")
    }
}