import { Component, Input, OnInit } from "@angular/core";
import { Domain, getStateFunction, LoadableDomain } from "../../domain.interface";
import { CurrencyService } from "../../../../services/currency.service";
import { domainStates } from "../../domainStates.constant";
import { ModalService } from "../../../../services/modal.service";
import { WhoisModalComponent } from "../../../../shared/whoisModal/whoisModal.component";


@Component({
    selector: "[search-domains-table-row]",
    templateUrl: "./searchDomainsTableRow.component.html"
})
export class SearchDomainsTableRowComponent implements OnInit {
    @Input()
    public domain?: LoadableDomain;
    public loadedDomain?: Domain;
    public getGlyph: (domain?: Domain) => string;
    public getTextClass: (domain?: Domain) => string;
    public showWhoIs: (domain?: Domain) => boolean;
    public invalidDomain?: boolean = false;

    constructor(public currency: CurrencyService, private modalService: ModalService) {
        this.getGlyph = getStateFunction(domainStates.availabiltyIcon);
        this.getTextClass = getStateFunction(domainStates.availabilityTextClass);
        this.showWhoIs = getStateFunction(domainStates.showWhoIs);
    }

    ngOnInit() {

        if (this.domain) {
            this.domain.domain.subscribe(dom => {
                this.loadedDomain = dom
                this.invalidDomain = this.loadedDomain.invalid;
            }
            );
        }
    }

    openModal() {
        if (!this.loadedDomain) return;
        let modalInputs = {
            modalId: "whois-modal",
            domainName: this.loadedDomain.fullName(),
        }
        this.modalService.openModal(WhoisModalComponent, modalInputs)
    }
}