<td class="domain" [ngClass]="getTextClass(loadedDomain)">
    <i [ngClass]="getGlyph(loadedDomain)"></i>&nbsp;
    <span class="tld">{{domain?.fullName}}</span>
</td>
<td>
    <div class="text-right" *ngIf="loadedDomain && !invalidDomain">
        <span class="price">{{loadedDomain?.price | number:"1.2-2"}} {{currency.current | async}}</span>
    </div>
</td>
<td>
    <div class="text-right" *ngIf="loadedDomain && !invalidDomain">
        <div class="btn-group">
            <button class="whoIs btn btn-link btn-sm hidden-xs" *ngIf="showWhoIs(loadedDomain)" (click)="openModal()">
                <i class="glyph-whois"></i>
            </button>
            <add-to-cart-button [domain]="loadedDomain"></add-to-cart-button>
        </div>
    </div>
    
</td>