var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from "@angular/core";
import { ModalComponent } from "../modal/modal.component";
import { ApiService } from "../../services/API/api.interface.service";
import { DictionaryService } from "../../services/dictionary.service";
import { replaceToken } from "../util";
import { BsModalRef } from "ngx-bootstrap/modal";
var WhoisModalComponent = /** @class */ (function (_super) {
    __extends(WhoisModalComponent, _super);
    function WhoisModalComponent(bsModalRef, api, dictionaryService) {
        var _this = _super.call(this, bsModalRef) || this;
        _this.bsModalRef = bsModalRef;
        _this.api = api;
        _this.loaded = false;
        _this.modalId = "whois-modal";
        dictionaryService.getDictionary().subscribe(function (dictionary) {
            _this.dictionary = dictionary;
        });
        return _this;
    }
    WhoisModalComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.api.whois(this.domainName).subscribe(function (text) {
            _this.loaded = true;
            if (text) {
                text = text.replace(new RegExp('\n', 'g'), "<br />");
            }
            _this.content = text;
            _this.title = replaceToken(_this.dictionary.Whois_Modal_Title, "domainName", _this.domainName);
        });
    };
    WhoisModalComponent.prototype.onClose = function () {
        this.loaded = false;
        this.content = null;
    };
    return WhoisModalComponent;
}(ModalComponent));
export { WhoisModalComponent };
