import { NgModule } from '@angular/core';

import { AppSharedModule } from './app.shared.module';
import { AppComponent } from './components/app/app.component';
import { ApiService } from './services/API/api.interface.service';
import { ApiBrowserService } from './services/API/api.browser.service';
import { ApiBrowserMockService } from './services/API/mock/api.browserMock.service';
import { BrowserTransferStateModule } from "@angular/platform-browser";

const config = (window as any)["config"];

@NgModule({
    imports: [
        AppSharedModule,
        BrowserTransferStateModule
    ],
    providers: [
        // Add browser-only providers here
        //{ provide: ApiService, useClass: ApiBrowserMockService}, //Mock 
        { provide: ApiService, useClass: ApiBrowserService },
        { provide: 'BASE_URL', useFactory: getBaseUrl },
        { provide: "API_URL", useValue: config.API_URL },
        { provide: "CMS_URL", useValue: config.CMS_URL },
        { provide: "CP_URL", useValue: config.CP_URL },
        { provide: "DICTIONARY_URL", useValue: config.DICTIONARY_URL },
        { provide: "FEED_URL", useValue: config.FEED_URL },
        { provide: "ENVIRONMENT", useValue: config.ENVIRONMENT },
    ],
    bootstrap: [AppComponent],
})
export class AppBrowserModule {
}

export function getBaseUrl() {
    return document.getElementsByTagName('base')[0].href;
}