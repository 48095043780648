import { Injectable } from "@angular/core";
import { Meta, MetaDefinition, Title } from "@angular/platform-browser";
import { BasePage } from "../shared/datatypes/basePage.data";
import { DictionaryService } from "./dictionary.service";
import { Router } from "@angular/router";
import { stripHTMLEntities, stripHTMLTags } from "../shared/util";


@Injectable()
export class MetaTagService {
    private dictionary: any;

    constructor(private meta: Meta, private title: Title, private dictionaryService: DictionaryService, private router: Router) {

        this.setOgTag("site_name", "Name ISP")
        this.setOgTag("type", "website")

        this.setMetaTag("viewport", "width=device-width, initial-scale=1.0");
        this.setMetaTag("apple-mobile-web-app-title", "Name ISP")
        this.setMetaTag("application-name", "Name ISP")
        this.setMetaTag("format-detection", "telephone-no")

        this.setTwitterTag("card", "summary")
        this.setTwitterTag("site", "@nameisp")
    }

    public addMetaTags(page: BasePage) {
        if (!page || !page.hasOwnProperty("metaTitle")) return;
        this.dictionaryService.getDictionary().subscribe(dictionary => {
            let metaTitle = this.getMetaTitle(page, dictionary);
            this.title.setTitle(metaTitle);
            this.setOgTag("title", metaTitle)

            let metaDescription = this.getMetaDescription(page, dictionary);
            this.setMetaTag("description", metaDescription)
            this.setOgTag("description", metaDescription);
        })

        this.setOgTag("url", this.router.url)
    }

    private setMetaTag(name: string, content: string) {
        this.addOrUpdateMetaTag({ name, content })
    }

    setTwitterTag(name: string, content: string) {
        this.addOrUpdateMetaTag({ name: "twitter:" + name, content })
    }

    private setOgTag(propertyName: string, content: string) {
        this.addOrUpdateMetaTag({ property: "og:" + propertyName, content }, `property="og:${propertyName}"`)
    }

    private addOrUpdateMetaTag(tag: MetaDefinition, selector?: string,) {
        if (!selector) {
            selector = `name="${tag.name}"`
        }
        if (this.meta.getTag(selector)) {
            this.meta.updateTag(tag, selector)
        } else {
            this.meta.addTag(tag)
        }
    }

    private getMetaTitle(page: BasePage, dictionary: any) {
        if (page.metaTitle) return page.metaTitle;
        if (page.pageTitle) return page.pageTitle + " | Name ISP";
        let defaultTitle = dictionary["Tab_Title_Default"]
        return defaultTitle ? defaultTitle : "Name ISP"
    }

    private getMetaDescription(page: BasePage, dictionary: any) {
        if (page.metaDescription) return page.metaDescription;
        if (page.subHeading) return this.cleanDescription(page.subHeading);
        let defaultDescription = dictionary["Meta_Description"]
        return defaultDescription ? defaultDescription : "Name ISP"
    }

    private cleanDescription = (str: string) => stripHTMLEntities(stripHTMLTags(str));
}