/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./table.component";
var styles_TableComponent = [];
var RenderType_TableComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TableComponent, data: {} });
export { RenderType_TableComponent as RenderType_TableComponent };
export function View_TableComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "table-filter-group tld-table"]], null, null, null, null, null)), i0.ɵncd(null, 0), (_l()(), i0.ɵeld(2, 0, null, null, 3, "div", [["class", "table-container table-responsive table-filter-table"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "table", [["class", "table"]], null, null, null, null, null)), i0.ɵncd(null, 1), i0.ɵncd(null, 2), i0.ɵncd(null, 3)], null, null); }
export function View_TableComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "table-component", [], null, null, null, View_TableComponent_0, RenderType_TableComponent)), i0.ɵdid(1, 49152, null, 0, i1.TableComponent, [], null, null)], null, null); }
var TableComponentNgFactory = i0.ɵccf("table-component", i1.TableComponent, View_TableComponent_Host_0, {}, {}, ["table-controls", "thead", "tbody", "pagination"]);
export { TableComponentNgFactory as TableComponentNgFactory };
