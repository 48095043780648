import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { Injectable } from "@angular/core";
import * as rxjs from "rxjs";
import { LanguageResolver } from "../../../shared/resolvers/language.resolver";
import { LanguageService } from "../../../services/language/language.service";
import { PageService } from "../../../services/page/page.service";
import { Location } from "@angular/common";

@Injectable()
export class ContentCategoryResolver extends LanguageResolver {

    constructor(private pageService: PageService, languageService: LanguageService, protected location: Location, router: Router) {
        super(languageService, router, location);
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        super.resolve(route, state);
        let category = route.paramMap.get("first");
        return rxjs.of(this.pageService.getContentCategory(category))
    }
}