/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./checkoutTableRow.component";
import * as i3 from "../../../services/dictionary.service";
var styles_CheckoutTableRowComponent = [];
var RenderType_CheckoutTableRowComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CheckoutTableRowComponent, data: {} });
export { RenderType_CheckoutTableRowComponent as RenderType_CheckoutTableRowComponent };
export function View_CheckoutTableRowComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i1.DecimalPipe, [i0.LOCALE_ID]), (_l()(), i0.ɵeld(1, 0, null, null, 6, "td", [["class", "max-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 5, "span", [["class", "label"]], null, null, null, null, null)), i0.ɵdid(3, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 1, "i", [], null, null, null, null, null)), i0.ɵdid(5, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, [" ", ""])), (_l()(), i0.ɵeld(8, 0, null, null, 1, "td", [["class", "domain"]], null, null, null, null, null)), (_l()(), i0.ɵted(9, null, [" ", "\n"])), (_l()(), i0.ɵeld(10, 0, null, null, 3, "td", [["class", "text-right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 2, "span", [["class", "price hidden-xs"]], null, null, null, null, null)), (_l()(), i0.ɵted(12, null, ["", ""])), i0.ɵppd(13, 2), (_l()(), i0.ɵeld(14, 0, null, null, 5, "td", [["class", "text-right max-2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 4, "div", [["class", "btn-group"]], null, null, null, null, null)), (_l()(), i0.ɵeld(16, 0, null, null, 3, "button", [["class", "btn btn-danger btn-sm"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onDelete() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(17, 0, null, null, 0, "i", [["class", "glyph-delete glyph-fw"]], null, null, null, null, null)), (_l()(), i0.ɵeld(18, 0, null, null, 1, "span", [["class", "sr-only loadable-button-label"]], null, null, null, null, null)), (_l()(), i0.ɵted(19, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "label"; var currVal_1 = _co.labelClass; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _co.labelGlyph; _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = (_co.dictionary ? _co.dictionary[_co.labelName] : ""); _ck(_v, 7, 0, currVal_3); var currVal_4 = _co.item.item; _ck(_v, 9, 0, currVal_4); var currVal_5 = i0.ɵunv(_v, 12, 0, _ck(_v, 13, 0, i0.ɵnov(_v, 0), _co.item.price, "1.2-2")); _ck(_v, 12, 0, currVal_5); var currVal_6 = ((_co.dictionary == null) ? null : _co.dictionary.Checkout_Delete_Item); _ck(_v, 19, 0, currVal_6); }); }
export function View_CheckoutTableRowComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["checkout-table-row", ""]], null, null, null, View_CheckoutTableRowComponent_0, RenderType_CheckoutTableRowComponent)), i0.ɵdid(1, 114688, null, 0, i2.CheckoutTableRowComponent, [i3.DictionaryService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CheckoutTableRowComponentNgFactory = i0.ɵccf("[checkout-table-row]", i2.CheckoutTableRowComponent, View_CheckoutTableRowComponent_Host_0, { item: "item" }, { itemDeleteEvent: "itemDeleteEvent" }, []);
export { CheckoutTableRowComponentNgFactory as CheckoutTableRowComponentNgFactory };
