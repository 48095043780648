<div class="panel-sidebar col-sm-4">
    <nav class="list-group">
        <div class="list-group-header">
                <h4 class="list-group-item-heading">{{title?.name}}</h4>
            <button class="list-group-nav-toggle" data-toggle="collapse" data-target="#nav-list"> 
                <i class="fa fa-bars"></i> <span class="sr-only">{{title}}</span>
            </button>
        </div>

        <div class="list-group-nav-collapse collapse"  id="nav-list">
            <a *ngFor="let item of links" class="list-group-item" routerLink="{{item?.link}}" [ngClass]="{'active': item?.id === activeMenuItemId}">{{item?.name}}</a>
        </div>
    </nav>
    <!-- <a routerLink="/{{languageService.current}}/contact" class="block block-info hidden-xs">
        <h3 translate="Sidebar_Support_Title">{{dictionary?.Sidebar_Support_Title}}</h3><p>{{dictionary?.Sidebar_Support_Description}}</p>
    </a> --> 
</div>