<table-component>
    <table-controls>
        <div class="row table-header">
            <table-search-tld (filter)="onSearch($event)" class="col-xs-12 col-md-5"></table-search-tld>
            <!-- <switch class="col-xs-12 col-md-3" [label]="dictionary?.Include_VAT"></switch> -->
            <div class="col-xs-12 col-md-7" *ngIf="tldGroups.length > 0">
                <select-component [options]="tldGroups" (onSelect)="onGroupFilter($event)" [label]="dictionary?.Top_Level_Domain_Category" [selected]="tldGroups[0].value"></select-component>
            </div>
        </div>
    </table-controls>
    <thead *ngIf="!noResults">
        <tr>
            <th>{{dictionary?.All_Top_Level_Domains_Table_TLD}}</th>
            <th>{{dictionary?.Top_Level_Domain_Prices_Registration}} ({{currency}})</th>
            <th>{{dictionary?.Top_Level_Domain_Prices_Renewal }} ({{currency}})</th>
            <th>{{dictionary?.Top_Level_Domain_Prices_Transfer}} ({{currency}})</th>
        </tr>
    </thead>
    <tbody [ngClass]="{ 'container-loading': isLoading }">

        <ng-container *ngIf="!noResults">
            <tr *ngFor="let tld of filteredTlds">
                <td>
                    <a routerLink="/{{languageService.current}}/domain-names/top-level-domain/{{tld.name}}">{{tld.name}}</a>
                </td>
                <td>
                    <span>{{tld.registration | number:"1.2-2"}}</span>
                </td>
                <td>
                    <span>{{tld.renew | number:"1.2-2"}}</span>
                </td>
                <td>
                    <span>{{tld.transfer | number:"1.2-2"}}</span>
                </td>
            </tr>
        </ng-container>

        <tr *ngIf="noResults">
            <td>
                <span>{{dictionary?.No_TLDs_Found}}</span>
            </td>
        </tr>
    </tbody>
    <pagination *ngIf="showPagination" (filter)="onPaginate($event)" [settings]="paginationSettings"></pagination>
</table-component>