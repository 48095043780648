import { ChangeDetectorRef, Component } from "@angular/core";
import { ContentCategoryPage } from "./ContentCategoryPage.data";
import { LanguageService } from "../../../services/language/language.service";
import { IContentComponent } from "../contentComponent/IContentComponent.interface";


@Component({
    selector: "content-category",
    templateUrl: "./contentCategory.component.html"
})
export class ContentCategoryComponent implements IContentComponent {
    sideMenu: boolean;

    setData(data: any): void {
        this.contentCategory = data;
        this.sideMenu = false;
        this.changeRef.detectChanges();
    }

    public contentCategory: ContentCategoryPage;

    constructor(private changeRef: ChangeDetectorRef, public languageService: LanguageService) {
    }
}