<div class="container" *ngIf="!isLoading">
    <div class="search-results">
        <search-domains-header [domain]="headerDomain"></search-domains-header> 
        <div class="search-result-promos">
        <search-domains-promo *ngFor="let promo of promos" [domain]="promo"></search-domains-promo> 
        </div>
    </div>
    <div id="search-domain-table">
        <div class="row">
            <search-domains-table class="col-md-6" [header]="dictionary['Topdomain_Group_POPULAR']" [domains]="popular"></search-domains-table>
            <search-domains-table class="col-md-6" [header]="dictionary['Topdomain_Group_RECOMMENDED']" [domains]="recommended"></search-domains-table> 
        </div>

        <div class="row">
            <ng-container *ngFor="let tldGroup of tldGroupsLoaded">
                <search-domains-table class="col-md-12" [header]="dictionary['Topdomain_Group_' + tldGroup.name]" [domains]="tldGroup.data"></search-domains-table> 
            </ng-container>
        </div>
    </div>
    <div class="row">
        <div class="container">
            <ng-container *ngFor="let tldGroup of tldGroups">
                <button [disabled]="tldGroupIsLoaded(tldGroup)"class="btn btn-default btn-space" (click)="loadTldGroup(tldGroup)"><i class="glyph-plus"></i> {{dictionary['Topdomain_Group_' + tldGroup.name]}}</button>
            </ng-container>
        </div>
    </div>
</div>

<div class="loading-lg" *ngIf="isLoading">

</div>