import { Component } from "@angular/core";
import { NamedLink } from "../../../shared/datatypes/namedlink.datatype";
import { MenuItem } from "../../../services/menu/menuItem.data";
import { LanguageService } from "../../../services/language/language.service";
import { MenuService } from "../../../services/menu/menu.service";
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { Page } from "../../../services/page/page.datatype";
import { TopLevelDomainPage } from "./topLevelDomainPage.data";
import { DictionaryService } from "../../../services/dictionary.service";
import { UrlService } from "../../../services/url.service";
import { ApiService } from "../../../services/API/api.interface.service";
import { TLDInfo } from "../../../services/API/tldInfo.data";
import { PriceService } from "../../../services/price/price.service";
import { combineLatest } from "rxjs";
import { CurrencyService } from "../../../services/currency.service";
import { CMSService } from "../../../services/CMS/cms.service";
import { Country } from "../../../shared/datatypes/Country.data";
import { DescriptionList, DescriptionListRow } from "./descriptionList.data";
import { DecimalPipe } from "@angular/common";
import { PageService } from "../../../services/page/page.service";
import { replaceToken } from "../../../shared/util";
import { Title } from "@angular/platform-browser";
import { combineLatest as combineLatestPipe, map, filter } from "rxjs/operators";

@Component({
    templateUrl: "./topLevelDomain.component.html"
})
export class TopLevelDomainComponent {
   
    public menuTitle: NamedLink = { name: "Title", link: "/" };
    public menuLinks: NamedLink[] = [{ name: "Link", link: "/" }];

    public searchDomainName: string = "";

    public page: TopLevelDomainPage | null = null;
    public heading: string;
    public introText: string;
    public bodyText: string;
    public dictionary: any;
    public domain: string;
    public tldInfo: TLDInfo | null = null;
    public tldGroups: string[] = [];
    public regulations: string[] = [];
    public resellerInfo: string[] = [];
    public agreementUrl: string = "";
    public prices: DescriptionListRow[] = [];
    public isPricesLoading: boolean = true;
    public isLoading: boolean = true;


    public resellerData: DescriptionList[] = [];

    public countryFlagClass: string = "";
    public currency: string = "";
    public countryInfo: DescriptionListRow[] | undefined;
    public countryName: string = "";

    public additionalInformation: string | null;
    private apiAdditionalInformation: string | null;

    public breadcrumbs: NamedLink[] = [];
    public tldsOptions: any[] = [];
    public selectedTldOption: any | null;

    constructor(public menuService: MenuService,
                public route: ActivatedRoute,
                public router: Router,
                public dictionaryService: DictionaryService,
                public api: ApiService,
                public cms: CMSService,
                private url: UrlService,
                public priceService: PriceService,
                private currencyService: CurrencyService,
                public languageService: LanguageService,
                public decimalPipe: DecimalPipe,
                public pageservice: PageService,
                private titleService: Title) {
                    this.isLoading = true;

                    this.router.events
                        .pipe(
                            filter(event => event instanceof NavigationEnd))
                        .subscribe(() => {

                        this.isLoading = true;
            
                        this.currencyService.current.subscribe(x => this.currency = x);
                        this.domain = this.route.snapshot.params["domain"];
                        this.selectedTldOption = this.domain;
            
                        let dictionaryObservable = this.dictionaryService.getDictionary();
                        let pageObservable = this.pageservice.getTopLevelDomain(this.domain);
                        let tldInfoObservable = this.api.getTLDInfo(this.domain);
                        let countryObservable = this.cms.getCountryInfo(this.domain);
            
                        combineLatest(dictionaryObservable, pageObservable)
                            .subscribe(([dictionary, page]) => {
                                this.dictionary = dictionary;
                                this.mapPageToProps(page, dictionary)
                            });
            
                        combineLatest(dictionaryObservable, countryObservable)
                            .subscribe(([dictionary, country]) => {
                                if (country) {
                                    if (country.ISO2Code) {
                                        this.countryFlagClass = "flag-" + country.ISO2Code.toLowerCase();
                                    }
            
                                    this.countryName = country.name;
            
                                    this.countryInfo = [
                                        { label: dictionary["Top_Level_Domain_Country_Capital"], data: country.capital },
                                        { label: dictionary["Top_Level_Domain_Country_Language"], data: country.language },
                                        { label: dictionary["Top_Level_Domain_Country_Population"], data: country.population },
                                        { label: dictionary["Top_Level_Domain_Country_Area"], data: country.area },
                                        { label: dictionary["Top_Level_Domain_Country_Timezone"], data: country.timezone },
                                    ]
                                }
                            });
            
            
                        combineLatest(dictionaryObservable, tldInfoObservable, this.currencyService.current)
                            .subscribe(([dictionary, tld, currency]) => {
                                if (!tld) return;
                                this.regulations = this.getRules(tld);
                                this.agreementUrl = tld.agreement ? tld.agreement[0] : "";
            
                                let prices = this.priceService.getPrices(tld, currency);
                                this.prices = prices.map(p => {
                                    return {
                                        label: dictionary[p.name],
                                        data: `${this.decimalPipe.transform(p.value, '1.2-2')} ${this.currency} ${this.getYears(p.years, dictionary)}`
                                    }
                                });
                                this.isPricesLoading = false;
                                this.tldInfo = tld;
                                this.resellerData = this.mapResellerInfo(tld, dictionary);
                                this.apiAdditionalInformation = tld.description ? tld.description[0] : null;
                                if (!this.additionalInformation) {
                                    this.additionalInformation = this.apiAdditionalInformation
                                }

                                this.isLoading = false;
                            });
            
                            this.menuService.getSubMenu(undefined, "domain-names")
                            .pipe(
                                combineLatestPipe(dictionaryObservable)
                            )
                            .subscribe(val => {
                                let [menu, dictionary] = val;
                                if (menu) {
                                    this.menuTitle = this.MenuItemToNamedLink(menu);
                                    this.breadcrumbs = [
                                        { name: menu.pageTitle, link: menu.url },
                                        { name: "Top_Level_Domain_Name", link: "/domain-names/all-top-level-domains" },
                                        { name: "." + this.domain, link: "/" }
                                    ];
                                    this.menuLinks = menu.items.map(x => this.MenuItemToNamedLink(x))
                                }
                            });
            
                        this.api.pricelist()
                        .pipe(
                            map(response => {
                                if(response.tlds){ 
                                    return response.tlds.map((tld: any): any => {
                                        return {
                                            display: tld.tld,
                                            value: tld.tld
                                        }
                                    })
                                }
                            })
                        )
                        .subscribe(tldsOptions => {
                            this.tldsOptions = tldsOptions;
                        })
                    });
        
    }

    private mapPageToProps(page: TopLevelDomainPage | null, dictionary: any) {
        this.heading = "." + this.domain;
        this.introText = dictionary["Top_Level_Domain_Default_Subheader"];
        this.bodyText = dictionary["Top_Level_Domain_Default_Body"];

        if (!page) {
            this.titleService.setTitle(this.domain + " | Name ISP");
            return;
        }

        if (page.heading && page.heading.length > 0) {
            this.heading = page.heading;
        }
        if (page.subHeading && page.subHeading.length > 0) {
            this.introText = page.subHeading;
        }
        if (page.bodyText && page.bodyText.length > 0) {
            this.bodyText = page.bodyText;
        }

        this.additionalInformation = page.additionalInformation
            ? page.additionalInformation
            : this.apiAdditionalInformation
    }

    private MenuItemToNamedLink(menu: MenuItem): NamedLink {
        return { name: menu.pageTitle, link: this.url.addLanguage(menu.url) };
    }

    private getRules(tld: TLDInfo): string[] {
        let result: string[] = [];

        let addRule = (name: string, property: any[]) => {
            if (property) {
                property.forEach(x => {
                    if (x !== "") {
                        result.push(name + x);
                    }
                })
            }
        };

        addRule("Top_Level_Domain_Rules_Country_Connection_", tld.countryconnection);
        addRule("Top_Level_Domain_Rules_Name_Similarity_", tld.namesimilarity);
        addRule("Top_Level_Domain_Rules_Idn_Allowed_", tld.idn);

        return result;
    }

    public getYears(years: number, dictionary: any) {
        if (years === 0) return "";
        if (years === 1) return `(${years} ${dictionary["Top_Level_Domain_Prices_Year"]})`;
        if (years > 1) return `(${years} ${dictionary["Top_Level_Domain_Prices_Years"]})`;
        return ""
    }

    private isPage(obj: any): obj is Page<TopLevelDomainPage> {
        return obj.customProperties !== undefined;
    }

    private isFallbackCountry(obj: any): obj is Country {
        return obj.alpha2Code !== undefined;
    }

    private mapResellerInfo(tld: TLDInfo, dictionary: any): DescriptionList[] {
        let row = (label: string, data: string) => {
            return { label, data }
        };
        let dictionaryReplaceDomain = (dictionaryKey: string): string => replaceToken(dictionary[dictionaryKey], "domain", "." + this.domain);


        let yes = dictionary ? dictionary["Misc_Yes"] : "Yes";
        let no = dictionary ? dictionary["Misc_No"] : "No";

        let first = (arr: string[]) => (arr && arr.length > 0) ? arr[0] : "";
        let firstAsWord = (arr: string[]) =>  firstCharUpperCase(first(arr));
        let firstCharUpperCase = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);
        let yesNo = (arr: string[]) => first(arr) === "1" ? yes : no;
        let status = (s: string) => s === "200" ? dictionary["Misc_Available"] : dictionary["Misc_Unavailable"];
        let authcode = (arr: string[]) => first(arr) === "1" ? dictionary["Top_Level_Domain_Authcode"] : dictionary["Misc_Unavailable"];
        let years = (arr: string[]) => (arr && arr.length > 0) ? (arr.join(", ") + " " + dictionary["Top_Level_Domain_Prices_Years"]) : dictionary["Misc_Unavailable"];
        let trustee = (arr: string[]) => {
            return arr.map((s: string) => {
                switch (s) {
                    case "10":
                        return dictionary["TLD_Reseller_Trustee_Required_Company"];
                    case "20":
                        return dictionary["TLD_Reseller_Trustee_Required_Admin"];
                    case "30":
                        return dictionary["TLD_Reseller_Trustee_Required_Europe"];
                    case "40":
                        return dictionary["TLD_Reseller_Trustee_Required_Trademark"];
                    case "50":
                        return dictionary["TLD_Reseller_Trustee_Required_Trademark_Or_Company"];
                    default:
                        return dictionary["Misc_No"];
                }
            }).join(", ");
        };

        return [
            {
                header: null,
                rows: [
                    row(dictionaryReplaceDomain("Top_Level_Domain_Reseller_API_Documentation"), "http://api3.nameisp.com"),
                    row(dictionaryReplaceDomain("Top_Level_Domain_Reseller_TLDStatus"), status(tld.status)),
                    row(dictionaryReplaceDomain("Top_Level_Domain_Reseller_TLDType"), first(tld.tldtype)),
                    row(dictionaryReplaceDomain("Top_Level_Domain_Reseller_RootServer"), first(tld.root_server)),
                    row(dictionaryReplaceDomain("Top_Level_Domain_Reseller_TMCH"), yesNo(tld.tmch)),
                    row(dictionaryReplaceDomain("Top_Level_Domain_Reseller_ShieldWhois"), yesNo(tld.shieldwhois)),
                    row(dictionaryReplaceDomain("Top_Level_Domain_Reseller_DNSSEC"), yesNo(tld.dnssec)),
                    row(dictionaryReplaceDomain("Top_Level_Domain_Reseller_IDN"), yesNo(tld.idn)),
                    row(dictionaryReplaceDomain("Top_Level_Domain_Reseller_Trustee_Required"), trustee(tld.countryconnection)),
                    row(dictionaryReplaceDomain("Top_Level_Domain_Reseller_Trustee"), yesNo(tld.trustee))
                ]
            },
            {
                header: dictionaryReplaceDomain("Top_Level_Domain_Reseller_Create_Domain_Registration"),
                rows: [
                    //row("Average turnover time", "7 days"),
                    row(dictionaryReplaceDomain("Top_Level_Domain_Reseller_Allowed_Domain_Length"), first(tld.minLen) + " - " + first(tld.maxLen)),
                    row(dictionaryReplaceDomain("Top_Level_Domain_Reseller_Allowed_Registration_Periods"), years(tld.regY))
                ]
            },
            {
                header: dictionaryReplaceDomain("Top_Level_Domain_Reseller_Create_Domain_Transfer"),
                rows: [
                    //row("Average turnover time", "7 days"),
                    row(dictionaryReplaceDomain("Top_Level_Domain_Reseller_Transfer_Method"), firstAsWord(tld.transferMethod)),
                    row(dictionaryReplaceDomain("Top_Level_Domain_Reseller_Transfer_Approval_Method"), firstAsWord(tld.transferVerificationMethod)),
                    row(dictionaryReplaceDomain("Top_Level_Domain_Reseller_Renewal_Included"), yesNo(tld.renewontransfer)),
                ]
            },
            {
                header: dictionaryReplaceDomain("Top_Level_Domain_Reseller_Update_Domain_Renew"),
                rows: [
                    //row("Average turnover time", "1 - 3 days"),
                    row(dictionaryReplaceDomain("Top_Level_Domain_Reseller_Available_Renewal_Periods"), years(tld.renewY)),
                ]
            },
            {
                header: dictionaryReplaceDomain("Top_Level_Domain_Reseller_Update_Domain_DNS"),
                rows: [
                    //row("Average turnover time", "1 - 3 days"),
                    row(dictionaryReplaceDomain("Top_Level_Domain_Reseller_Number_Of_DNS_Hosts_Allowed"), first(tld.minNS) + " - " + first(tld.maxNS)),
                ]
            },
            {
                header: dictionaryReplaceDomain("Top_Level_Domain_Reseller_Update_Domain_Owner_Transfer"),
                rows: [
                    //row("Average turnover time", "1 - 30 days"),
                    row(dictionaryReplaceDomain("Top_Level_Domain_Reseller_Supported_Via_Registrar"), yes)
                ]
            },
            {
                header: dictionaryReplaceDomain("Top_Level_Domain_Reseller_Update_Domain_Contact"),
                rows: [
                    //row("Average turnover time", "1 - 30 days"),
                    row(dictionaryReplaceDomain("Top_Level_Domain_Reseller_Supported_Via_Registrar"), yes)
                ]
            }
        ]
    }


    onDomainSearch() {
        let url = `/${this.languageService.current}/searchdomains?domainname=${this.searchDomainName}`;
        if (!this.searchDomainName.includes(".")) {
            url += "." + this.domain;
        }
        this.router.navigateByUrl(url);
    }

    onSelectTld(tld: string) {
        this.router.navigateByUrl(`/${this.languageService.current}/domain-names/top-level-domain/${tld}`);
    }
}