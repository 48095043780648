export class NewsItem {

    public isRecent: boolean;
    public dateString: string;

    private recentDays = 30;

    constructor(public title: string,
                public date: Date,
                public link: string) {
        let pad = (n: number) => n < 10 ? "0" + n : n;
        let recent = 1000 * 60 * 60 * 24 * this.recentDays;
        this.isRecent = (Date.now() - date.getTime()) < recent;
        this.dateString = date.getFullYear() + "-" + pad(date.getMonth() + 1) + "-" + pad(date.getDate());
    }
}