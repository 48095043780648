var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { LanguageService } from '../../services/language/language.service';
import { Images } from '../../constants/images.constants';
import { DomSanitizer } from '@angular/platform-browser';
import { DictionaryService } from '../../services/dictionary.service';
import { PageService } from '../../services/page/page.service';
import { unFormatPhone } from '../../shared/util';
var FooterComponent = /** @class */ (function () {
    function FooterComponent(languageService, domSanitizer, dictionaryService, pageService, cpUrl) {
        var _this = this;
        this.languageService = languageService;
        this.cpUrl = cpUrl;
        this.siteUrl = "";
        dictionaryService.getDictionary().subscribe(function (x) { return _this.dictionary = x; });
        this.images = __assign({}, Images, { amex: domSanitizer.bypassSecurityTrustUrl(Images.amex), bankgirot: domSanitizer.bypassSecurityTrustUrl(Images.bankgirot) });
        pageService.getFooter().subscribe(function (data) {
            _this.footerData = data.customProperties;
            var phone = data.customProperties.contactPhoneNumber;
            _this.mobilePhoneHref = phone ? unFormatPhone(phone) : "";
        });
    }
    return FooterComponent;
}());
export { FooterComponent };
