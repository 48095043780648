import { Component, Input } from "@angular/core";

@Component({
    selector: "content-component",
    templateUrl: "./content.component.html"
})
export class ContentComponent {
    @Input() pageTitle: string;
    @Input() heading: string;
    @Input() subHeading: string;
    @Input() bodyText: string;

    getHeading() {
        if (this.heading && this.heading.length > 0) {
            return this.heading;
        }
        return this.pageTitle
    }

    ngOnInit() {
        this.subHeading = this.subHeading ? this.subHeading : "";
        this.bodyText = this.bodyText ? this.bodyText : "";
    }
}