import { PopoverComponent } from "../../../../../shared/popover/popover.component";
import { Component } from "@angular/core";
import { DictionaryService } from "../../../../../services/dictionary.service";
import { LanguageService } from "../../../../../services/language/language.service";

@Component({
    selector: "added-to-cart-popover",
    templateUrl: "./addedToCartPopover.component.html"
})
export class AddedToCartPopoverComponent extends PopoverComponent {

    timer: any;

    constructor(dictionaryService: DictionaryService, public languageService: LanguageService) {
        super(dictionaryService);
    }

    open() {
        this.show = true;
        this.active = true;
        if (this.timer && this.timer.data) {
            clearTimeout(this.timer.data.handleId)
        }
        this.timer = setTimeout(() => {
            this.close();
        }, this.showTime);
    }
}