import { Injectable, Type } from "@angular/core";
import { ModalComponent } from "../shared/modal/modal.component";
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

@Injectable()
export class ModalService {

    modalRef: BsModalRef;

    constructor(private modalService: BsModalService) {
    }

    public openModal(component: Type<ModalComponent>, inputs: any) {
        const initialState = inputs;
        this.modalRef = this.modalService.show(component, { initialState });
        // debugger
    }
}