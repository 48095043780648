/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../shared/popover/popover.component.ngfactory";
import * as i4 from "../../../../../shared/popover/popover.component";
import * as i5 from "../../../../../services/dictionary.service";
import * as i6 from "./addedToCartPopover.component";
import * as i7 from "../../../../../services/language/language.service";
var styles_AddedToCartPopoverComponent = [];
var RenderType_AddedToCartPopoverComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AddedToCartPopoverComponent, data: {} });
export { RenderType_AddedToCartPopoverComponent as RenderType_AddedToCartPopoverComponent };
export function View_AddedToCartPopoverComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 8, "popover", [], null, null, null, i3.View_PopoverComponent_0, i3.RenderType_PopoverComponent)), i0.ɵdid(3, 573440, null, 0, i4.PopoverComponent, [i5.DictionaryService], { show: [0, "show"], active: [1, "active"] }, null), (_l()(), i0.ɵeld(4, 0, null, 0, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 3, "h3", [], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 0, "i", [["class", "glyph-success text-success"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, ["", ""])), (_l()(), i0.ɵeld(9, 0, null, 1, 1, "div", [["body", ""]], null, null, null, null, null)), (_l()(), i0.ɵted(10, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = i0.ɵinlineInterpolate(1, "", _co.languageService.current, "/checkout"); _ck(_v, 1, 0, currVal_2); var currVal_3 = _co.show; var currVal_4 = _co.active; _ck(_v, 3, 0, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 1).target; var currVal_1 = i0.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_5 = ((_co.dictionary == null) ? null : _co.dictionary.Checkout_Header_Button_Popup_Title); _ck(_v, 8, 0, currVal_5); var currVal_6 = ((_co.dictionary == null) ? null : _co.dictionary.Checkout_Header_Button_Popup_Description); _ck(_v, 10, 0, currVal_6); }); }
export function View_AddedToCartPopoverComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "added-to-cart-popover", [], null, null, null, View_AddedToCartPopoverComponent_0, RenderType_AddedToCartPopoverComponent)), i0.ɵdid(1, 573440, null, 0, i6.AddedToCartPopoverComponent, [i5.DictionaryService, i7.LanguageService], null, null)], null, null); }
var AddedToCartPopoverComponentNgFactory = i0.ɵccf("added-to-cart-popover", i6.AddedToCartPopoverComponent, View_AddedToCartPopoverComponent_Host_0, { openEvent: "open", show: "show", active: "active" }, {}, []);
export { AddedToCartPopoverComponentNgFactory as AddedToCartPopoverComponentNgFactory };
