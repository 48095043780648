<div class="mega-menu container">
    <div class="public-navigation">
        <div class="public-navigation-body">
            <div class="public-primary-navigation">
                <div class="col-sm-3" *ngFor="let category of menuItems">
                    <a [routerLink]="url.addLanguage(category.url)" (click)="OnClickLink($event)"><h2>{{category.pageTitle}}</h2></a>
                    <ul class="navigation">
                        <li *ngFor="let item of category.items">
                            <a [routerLink]="url.addLanguage(item.url)" (click)="OnClickLink($event)">{{item.pageTitle}}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>