import { Component, Inject } from '@angular/core';
import { LanguageService } from '../../services/language/language.service';
import { Images } from '../../constants/images.constants'
import { DomSanitizer } from '@angular/platform-browser';
import { DictionaryService } from '../../services/dictionary.service';
import { PageService } from '../../services/page/page.service';
import { FooterData } from './footerData.interface';
import { unFormatPhone } from '../../shared/util';

@Component({
    selector: 'site-footer',
    templateUrl: './footer.component.html'
})
export class FooterComponent {
    siteUrl: string = "";
    images: any;

    dictionary: any;
    footerData: FooterData;
    mobilePhoneHref: string;

    constructor(public languageService: LanguageService, domSanitizer: DomSanitizer, dictionaryService: DictionaryService, pageService: PageService, @Inject("CP_URL") public cpUrl: string) {

        dictionaryService.getDictionary().subscribe(x => this.dictionary = x);

        this.images = {
            ...Images,
            amex: domSanitizer.bypassSecurityTrustUrl(Images.amex),
            bankgirot: domSanitizer.bypassSecurityTrustUrl(Images.bankgirot)
        };

        pageService.getFooter().subscribe(data => {
            this.footerData = data.customProperties;
            let phone = data.customProperties.contactPhoneNumber;
            this.mobilePhoneHref = phone ? unFormatPhone(phone) : "";
        })
    }
}
