import { Observable } from "rxjs";

export class Domain {
    constructor(public name: string,
                public tld: string,
                public price: number,
                public priceSEK: number,
                public available?: boolean,
                public inCart?: boolean,
                public invalid?: boolean) {
    }

    public fullName() {
        return this.name + "." + this.tld;
    }
}

export type DomainStates = { available: any, unavailable: any, inCart?: any, transferInCart?: any, loading?: any, invalid?: any };
export const getStateFunction = (state: DomainStates) => {
    return (domain?: Domain) => {
        if (!domain) return state.loading;
        if(domain.available === undefined && domain.invalid === false) {
            return state.loading;
        }
        if (state.invalid !== undefined && domain && domain.invalid) return state.invalid
        if (!domain.available && domain.inCart) {
            return state.transferInCart !== undefined ? state.transferInCart : state.unavailable
        }
        if (domain.inCart) return state.inCart !== undefined ? state.inCart : state.available;
        if (domain.available) return state.available;
        return state.unavailable;
    }
}

export interface LoadableDomain {
    domain: Observable<Domain>;
    name: string;
    tld: string;
    fullName: string;
}

export interface LoadableTldGroup {
    name: string,
    data: LoadableDomain[],
    tldGroupResponse: any
}