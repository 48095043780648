import { Resolve } from "@angular/router/src/interfaces";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { TextContentComponent } from "./textContent/textContent.component";
import { Injectable, Type } from "@angular/core";
import { Observable } from "rxjs";
import { LanguageResolver } from "../../shared/resolvers/language.resolver";
import { LanguageService } from "../../services/language/language.service";
import { ContentCategoryComponent } from "./contentCategory/contentCategory.component";
import { AllTldsComponent } from "./allTlds/allTlds.component";
import { NewTldsComponent } from "./newTlds/newTlds.component";
import { PageService } from "../../services/page/page.service";
import { IContentComponent } from "./contentComponent/IContentComponent.interface";
import { CustomerServiceComponent } from "../content/customerService/customerService.component";
import { WhoisLookupComponent } from "./whoisLookup/whoisLookup.component";
import { PunycodeConverterComponent } from "./punycodeConverter/punycodeConverter.component";
import { Location } from "@angular/common";
import { first, map } from "rxjs/operators";

export interface DynamicContent<T> {
    type: Type<T>;
    data: any;
    id: number
}

@Injectable()
export class ContentResolver extends LanguageResolver implements Resolve<Observable<DynamicContent<IContentComponent>>> {

    constructor(private pageService: PageService, public router: Router, protected location: Location, public languageService: LanguageService) {
        super(languageService, router, location);
    }

    contentTypeToComponentMap: any = {
        "panelItemsPage": ContentCategoryComponent,
        "contentPage": TextContentComponent,
        "allTopLevelDomains": AllTldsComponent,
        "newTopLevelDomains": NewTldsComponent,
        "customerService": CustomerServiceComponent,
        "punycodeConverter": PunycodeConverterComponent,
        "whoisLookup": WhoisLookupComponent
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        super.resolve(route, state);

        let firstParam = route.paramMap.get("first");
        let secondParam = route.paramMap.get("second");

        return this.pageService.getContent(firstParam, secondParam)
            .pipe(
                map(page => {
                    let component = this.contentTypeToComponentMap[page.documentTypeAlias];
                    if (!component) {
                        this.router.navigate([this.languageService.current, "pagenotfound"]);
                        return { type: TextContentComponent, data: {}, id: 0 };
                    }
                    return { type: component, data: page.customProperties, id: page.id }
                }),
                first()
            );
    }
}