import { EventEmitter, Injectable } from "@angular/core";
import { BasketItem } from "./basketItem.interface";
import { ApiService } from "../API/api.interface.service";
import { DomainItemAction } from "../API/enums/api.domainitemaction.enum";
import { ApiStatusCode } from "../API/enums/api.statuscodes.enum";
import { map, flatMap } from 'rxjs/operators';
import { Observable } from "rxjs/internal/Observable";
import { BehaviorSubject } from "rxjs";
import { PriceService } from "../price/price.service";
import { CurrencyService } from "../currency.service";
import { PriceType } from "../price/priceclass.enum";

@Injectable()
export class BasketService {
    public count = 0;
    public addEvent = new EventEmitter<void>();
    private basketData = new BehaviorSubject<any>([]);

    constructor(private api: ApiService, 
                private priceService: PriceService,
                private currencyService: CurrencyService) {

        this.setBasketData();
    }

    addToBasket(baskietItem: BasketItem) {
        this.api.addToBasket(baskietItem).subscribe(response => {
            if(response.code == ApiStatusCode.Success){
                //Success
                this.addEvent.emit();
                this.count++;
            } else {
                this.setBasketData();
            }
        });
    }

    removeFromBasket(name: string) {
        for(var itemid in this.basketData.value.item) {
            if (this.basketData.value.item[itemid].item[0] == name) {

                this.api.removeFromBasket(itemid).subscribe(response => {
                    if(response.code == ApiStatusCode.Success) {
                        //Success
                        this.count--;
                    } else {
                        this.setBasketData();
                    }
                });
            }
        }
    }

    getBasket(): Observable<BasketItem[]> {
        return this.basketData
            .asObservable()
                .pipe(
                    flatMap(data => this.getBasketItemsFromBasketData(data))
                );
    }

    setBasketData() {
        this.api.getBasket()
            .subscribe(response => {
                this.basketData.next(response.parameters);
                this.count = response.parameters.items;
                
            });
    }

    private getBasketItemsFromBasketData(basketData: any): Observable<BasketItem[]> {
        return this.currencyService.current
            .pipe(
                map(currentCurrency => {
                    if (basketData) {

                        let items = new Array<BasketItem>();

                        for (let itemid in basketData.item) {
                            let tldInfo = basketData.item[itemid];

                            let price = 0;
                            if (tldInfo.itemaction === DomainItemAction.Register) {
                                price = this.priceService.getPrice(tldInfo.prices, PriceType.Registration, currentCurrency);

                            } else if (tldInfo.itemaction === DomainItemAction.Transfer) {
                                price = this.priceService.getPrice(tldInfo.prices, PriceType.OwnerChange, currentCurrency);
                            }

                            items.push(new BasketItem(tldInfo.item[0],+itemid ,tldInfo.itemaction, price))
                        }

                        return items ? items : [];
                    }
                    return [];
                })
            );
    }
}