<form (ngSubmit)="submit()" #whoisform="ngForm">
    <div class="input-group input-group-fw">
        <div class="input-prepend">
            <i class="glyph-whois"></i>
        </div>
        <input [(ngModel)]="searchModel" name="whoisSearchModel" type="text" class="domainname form-control" placeholder="{{dictionary?.Whois_Search_Placeholder}}"
            [pattern]="validationPattern" />
        <div class="btn-group input-inner">
            <button type="submit" class="btn" ng-disabled="whoisform.$invalid" ng-click="submit()">
                <i class="fa fa-search"></i>
                <span class="sr-only">{{dictionary?.Index_Search_Button}}</span>
            </button>
        </div>
    </div>
</form>
<whois-popover [open]="openWhoisPopover"></whois-popover>