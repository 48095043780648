import { Component, Inject, PLATFORM_ID, PlatformRef } from "@angular/core";
import { DictionaryService } from "../../services/dictionary.service";
import { DOCUMENT, isPlatformBrowser } from "@angular/common";

@Component({
    selector: "bug-report",
    templateUrl: "./bugReport.component.html"
})
export class BugReportComponent {
    public dictionary: any;

    public isProduction: boolean;

    constructor(dictionaryService: DictionaryService, @Inject(PLATFORM_ID) platformId: PlatformRef, @Inject(DOCUMENT) public document: any, @Inject("ENVIRONMENT") public environment: string) {
        dictionaryService.getDictionary().subscribe(d => this.dictionary = d);

        let isBrowser = isPlatformBrowser(platformId);
        this.isProduction = environment === "production";

        // Inject script on serverside rendering only
        if (!isBrowser && this.isProduction) {
            let s = this.document.createElement("script");
            s.type = "text/javascript";
            s.async = true;
            s.src = 'https://api.usersnap.com/load/' +
                '19b40aa4-ff28-4c02-8d31-246dab4ea77f.js';
            let x = this.document.getElementsByTagName('script')[0];
            (x.parentNode as Node).insertBefore(s, x);
        }
    }

    reportBug(event: Event) {
        event.preventDefault();

        const UserSnap = (window as any).UserSnap;
        if (UserSnap) {
            UserSnap.start();
        }
    }
}