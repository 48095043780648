import { ActivatedRoute, Router } from '@angular/router';
import { HomePage } from './homePage.datatype';
import { LanguageService } from '../../services/language/language.service';
import { Component } from '@angular/core';
import { PageService } from '../../services/page/page.service';
import { CarouselItem } from './main-slider/carouselItem.interface';
import { map } from 'rxjs/operators';

@Component({
    selector: 'home',
    templateUrl: './home.component.html'
})
export class HomeComponent {

    dictionary: any;
    apiTest: any;
    public searchDomainModel: string = "";
    showAllPanelItems = false;
    shownPanelItems = 1;
    model: HomePage;
    private fragment: string;
    public backgroundImage: string;

    public promosliderTlds: any[];
    public carouselItems: CarouselItem[];

    constructor(private route: ActivatedRoute, private router: Router, public languageService: LanguageService, pageService: PageService) {
        this.route.snapshot.data.dictionary.subscribe((x: any) => this.dictionary = x);
        pageService.getHomePage()
            .pipe(
                map(page => page.customProperties)
            )
            .subscribe(page => {
                this.promosliderTlds = page.promosliderTlds.split("\n").map((x: string) => x.trim());
                this.carouselItems = page.mainSlider.filter(x => x != null).map(x => x.customProperties);
                this.model = page;
            });
        route.fragment.subscribe(frag => this.fragment = frag);
    }

    onSubmitSearch() {
        let url = `/${this.languageService.current}/searchDomains?domainname=${this.searchDomainModel}`;
        this.router.navigateByUrl(url);
    }

    getPanelItems() {
        if (!this.model || !this.model.panelItems) {
            return []
        } else {
            let items = this.showAllPanelItems
                ? this.model.panelItems
                : this.model.panelItems.slice(0, this.model.numberOfPanelItemsShown);
            return items.map(p => p.customProperties)
        }
    }
}