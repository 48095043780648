import { Component, Input } from "@angular/core";
import { NewsItem } from "../newsItem.interface";

@Component({
    selector: "[news-panel-row]",
    templateUrl: "./newsPanelRow.component.html"
})
export class NewsPanelRow {
    @Input()
    item: NewsItem;
}