<div class="container">
    <breadcrumb [links]="breadcrumbs"></breadcrumb>
    <div class="panel panel-default has-sidebar">
        <side-menu [title]="menuTitle" [links]="menuLinks"></side-menu>
        <div class="panel-body col-sm-8 page-content">
            <div>
                <header>
                    <div class="row">
                        <div class="col-md-4">
                            <h1>{{heading}}</h1>
                        </div>
                        <div class="col-md-8">
                            <select-component [options]="tldsOptions" (onSelect)="onSelectTld($event)" [selected]="selectedTldOption" [label]="dictionary?.Top_Level_Domain_Dropdown_Label"></select-component>
                        </div>
                    </div>
                </header>
                <div class="box-inner">
                    <div class="intro" [innerHTML]="introText"></div>
                </div>

                <div [innerHTML]="bodyText"></div>

                <div class="panel panel-grey-light">
                    <div class="panel-body">
                        <p>{{dictionary?.Top_Level_Domain_Search_Top?.replace('{domain}', domain)}}</p>
                        <div class="search-widget">
                            <form (ngSubmit)="onDomainSearch()">
                                <div class="form-item compound">
                                    <input [(ngModel)]="searchDomainName" name="searchDomain" type="text" class="domainname form-control" placeholder="{{dictionary?.Top_Level_Domain_Search_Placeholder?.replace('{domain}', domain)}}"
                                    />
                                    <div class="buttons">
                                        <button class="button search blank">
                                            <span class="button-inner">
                                                <span class="button-icon">
                                                    <i class="fa fa-search"></i>
                                                    <span class="sr-only">Search</span>
                                                    <!-- {{dictionary.Index_Search_Button}} -->
                                                </span>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </form>

                        </div>

                        <p>{{dictionary?.Top_Level_Domain_Search_Bottom?.replace('{domain}', domain)}}</p>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-xs-12 col-md-6 price-information">
                    <info-panel [title]="dictionary?.Top_Level_Domain_Prices" [rows]="prices" [isLoading]="isPricesLoading"></info-panel>
                </div>

                <div class="col-xs-12 col-md-6">
                    <info-panel [title]="countryName" [rows]="countryInfo" [flagClass]="countryFlagClass" *ngIf="countryInfo;else noCountry"></info-panel>
                </div>

                <!-- Temporary until we decide what to do here! -->
                <ng-template #noCountry>
                    <panel-item class="description-list-panel">
                        <h2 header class="panel-heading">{{dictionary?.Top_Level_Domain_Generic_TLD}}</h2>
                        <h1 body style="font-size:50px; margin:auto; padding-top:30px; padding-bottom:60px; text-align:center;">.{{domain}}</h1>
                    </panel-item>
                </ng-template>
            </div>

            <div class="row" *ngIf="regulations?.length > 0">
                <div class="col-md-12">
                    <h2>{{ dictionary?.Top_Level_Domain_Regulations }}</h2>
                    <div class="data-text-box">
                            <ul class="list-group">
                                <li class="list-group-item" *ngIf="agreementUrl">
                                    <i class="list-dot fa fa-circle"></i>
                                    <a class="link-external" [href]="agreementUrl" target="_blank">{{ dictionary?.Top_Level_Domain_Registrant_Agreement }}</a>
                                </li>
                                <li class="list-group-item" *ngFor="let item of regulations">
                                    <i class="list-dot fa fa-circle"></i> {{ dictionary[item] }}
                                </li>
                            </ul>
                        </div>
                </div>
            </div>

            <div class="row" *ngIf="additionalInformation">
                <div class="col-md-12">
                    <panel-item>
                        <h2 header class="panel-heading">{{dictionary?.Top_Level_Domain_Additional_Information}}</h2>
                        <div body [innerHtml]="additionalInformation"></div>
                    </panel-item>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <reseller-information [header]="'.' + domain.toUpperCase() + ' ' + dictionary?.Top_Level_Domain_Reseller_Information" [sections]="resellerData"></reseller-information>
                </div>
            </div>
        </div>
    </div>