<div id="promoslider" class="promo-slider">
    <ul id="test" class="promo-tld-list">
        <li class="promo-tld" *ngFor="let tld of promoTlds">
            <a routerLink="/{{languageService.current}}/domain-names/top-level-domain/{{tld.domain}}">
                <span class="promo-tld-title">.{{tld.domain}}</span>
                <span class="promo-tld-price" *ngIf="tld.priceIsLoading">{{dictionary?.Loading_Text}}</span>
                <span class="promo-tld-price" *ngIf="!tld.priceIsLoading">{{tld.price | number:'1.2-2'}} {{currency}}</span>
            </a>
        </li>
        <li class="promo-tld hidden-xs hidden-sm">
            <a routerLink="/{{languageService.current}}/domain-names/all-top-level-domains">
                <span class="promo-tld-more" [innerHTML]="dictionary?.Index_Promo_View_All_Tlds"></span>
                <span class="promo-tld-price">&nbsp;</span>
            </a>
        </li>
    </ul>
</div>