var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef } from "@angular/core";
import { BasePage } from "../../../shared/datatypes/basePage.data";
import { DictionaryService } from "../../../services/dictionary.service";
import { NgForm } from "@angular/forms";
import { simpleDomainPattern } from "../../../shared/validationPatterns.constants";
import { ActivatedRoute, Router } from "@angular/router";
import { LanguageService } from "../../../services/language/language.service";
// import * as punyCode from "punycode";
var punyCode = require("punycode");
var PunycodeConverterComponent = /** @class */ (function (_super) {
    __extends(PunycodeConverterComponent, _super);
    function PunycodeConverterComponent(changeRef, dictionaryService, languageService, route, router) {
        var _this = _super.call(this) || this;
        _this.changeRef = changeRef;
        _this.dictionaryService = dictionaryService;
        _this.languageService = languageService;
        _this.route = route;
        _this.router = router;
        _this.validationPattern = simpleDomainPattern;
        dictionaryService.getDictionary().subscribe(function (dictionary) { return _this.dictionary = dictionary; });
        route.queryParams.subscribe(function (params) {
            var query = params["query"];
            if (query) {
                _this.searchModel = query;
                _this.convert(query);
            }
        });
        return _this;
    }
    PunycodeConverterComponent.prototype.setData = function (data) {
        this.pageTitle = data.pageTitle;
        this.bodyText = data.bodyText;
        this.subHeading = data.subHeading;
        this.sideMenu = data.sideMenu;
        this.changeRef.detectChanges();
    };
    PunycodeConverterComponent.prototype.convert = function (query) {
        if (query.substr(0, 4) === "xn--") {
            this.result = punyCode.toUnicode(query);
        }
        else {
            this.result = punyCode.toASCII(query);
        }
    };
    PunycodeConverterComponent.prototype.submit = function () {
        if (!this.form.invalid) {
            var query = this.searchModel.toLowerCase();
            var currentUrl = this.router.url.split("?", 2)[0];
            var url = currentUrl + "?query=" + encodeURIComponent(query);
            this.router.navigateByUrl(url);
        }
    };
    PunycodeConverterComponent.prototype.searchDomain = function () {
        var url = "/" + this.languageService.current + "/searchdomains?domainname=" + encodeURIComponent(this.result);
        this.router.navigateByUrl(url);
    };
    return PunycodeConverterComponent;
}(BasePage));
export { PunycodeConverterComponent };
