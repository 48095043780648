<form (ngSubmit)="onSubmitSearch()" #whoisform="ngForm">
    <div class="input-group">
        <div class="input-prepend">
            <i [ngClass]="selectedOption.glyph"></i>
        </div>
        <input [(ngModel)]="model" name="searchDomain" type="text" class="form-control" placeholder="{{ getTranslation(selectedOption.placeholder) }}"
            [pattern]="whoisPattern" [autocomplete]="selectedOption.id === Search.WhoIs ? 'off' : ''">
        <button class="button search blank" type="submit">
            <span class="button-inner">
                <span class="button-icon">
                    <i class="fa fa-search"></i>
                    <span class="sr-only">{{dictionary?.Index_Search_Button}}</span>
                </span>
            </span>
        </button>
        <div (clickOutside)="clickOutside()">
            <button type="button" class="button alt-search blank" (click)="toggleOptions()">
                <i class="fa fa-angle-down"></i>
            </button>
            <ul class="spec-search-list" *ngIf="showOptions">
                <li *ngFor="let option of searchOptions">
                    <i class="fa fa-caret-right" [ngClass]="isActive(option)"></i>
                    <a (click)="setOption(option)">{{ getTranslation(option.label) }}</a>
                </li>
            </ul>
        </div>
        <whois-popover [open]="openWhoisPopover"></whois-popover>
    </div>
</form>