/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./panelItem.component";
var styles_PanelItemComponent = [];
var RenderType_PanelItemComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PanelItemComponent, data: {} });
export { RenderType_PanelItemComponent as RenderType_PanelItemComponent };
export function View_PanelItemComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "article", [["class", "panel panel-info"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "panel-container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "header", [["class", "panel-header"]], null, null, null, null, null)), i0.ɵncd(null, 0), (_l()(), i0.ɵeld(4, 0, null, null, 1, "div", [["class", "panel-body"]], null, null, null, null, null)), i0.ɵncd(null, 1), (_l()(), i0.ɵeld(6, 0, null, null, 1, "footer", [["class", "panel-footer"]], null, null, null, null, null)), i0.ɵncd(null, 2)], null, null); }
export function View_PanelItemComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "panel-item", [], null, null, null, View_PanelItemComponent_0, RenderType_PanelItemComponent)), i0.ɵdid(1, 49152, null, 0, i1.PanelItemComponent, [], null, null)], null, null); }
var PanelItemComponentNgFactory = i0.ɵccf("panel-item", i1.PanelItemComponent, View_PanelItemComponent_Host_0, {}, {}, ["[header]", "[body]", "[footer]"]);
export { PanelItemComponentNgFactory as PanelItemComponentNgFactory };
