/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "../../tables/baseTable/table.component.ngfactory";
import * as i4 from "../../tables/baseTable/table.component";
import * as i5 from "./newTldsTable.component";
import * as i6 from "../../../services/language/language.service";
import * as i7 from "../../../services/dictionary.service";
var styles_NewTLDsTableComponent = [];
var RenderType_NewTLDsTableComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NewTLDsTableComponent, data: {} });
export { RenderType_NewTLDsTableComponent as RenderType_NewTLDsTableComponent };
function View_NewTLDsTableComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵted(2, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = i0.ɵinlineInterpolate(2, "/", _co.languageService.current, "/domain-names/top-level-domain/", _v.parent.context.$implicit.tld, ""); _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).target; var currVal_1 = i0.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = _v.parent.context.$implicit.tld_utf8; _ck(_v, 2, 0, currVal_3); }); }
function View_NewTLDsTableComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.tld_utf8; _ck(_v, 1, 0, currVal_0); }); }
function View_NewTLDsTableComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "tr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "td", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NewTLDsTableComponent_2)), i0.ɵdid(3, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NewTLDsTableComponent_3)), i0.ɵdid(5, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, [" ", " "]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.showLink; _ck(_v, 3, 0, currVal_0); var currVal_1 = !_v.context.$implicit.showLink; _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.date; _ck(_v, 7, 0, currVal_2); }); }
export function View_NewTLDsTableComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""])), (_l()(), i0.ɵeld(2, 0, null, null, 12, "table-component", [], null, null, null, i3.View_TableComponent_0, i3.RenderType_TableComponent)), i0.ɵdid(3, 49152, null, 0, i4.TableComponent, [], null, null), (_l()(), i0.ɵeld(4, 0, null, 1, 5, "thead", [], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 4, "tr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, [" ", " "])), (_l()(), i0.ɵeld(8, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i0.ɵted(9, null, [" ", " "])), (_l()(), i0.ɵeld(10, 0, null, 2, 4, "tbody", [], null, null, null, null, null)), i0.ɵdid(11, 278528, null, 0, i2.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), i0.ɵpod(12, { "container-loading": 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NewTLDsTableComponent_1)), i0.ɵdid(14, 802816, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _ck(_v, 12, 0, _co.isLoading); _ck(_v, 11, 0, currVal_3); var currVal_4 = _co.list; _ck(_v, 14, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.header; _ck(_v, 1, 0, currVal_0); var currVal_1 = ((_co.dictionary == null) ? null : _co.dictionary.NEW_TOP_LEVEL_DOMAINS_HEADER_TLD); _ck(_v, 7, 0, currVal_1); var currVal_2 = ((_co.dictionary == null) ? null : _co.dictionary.NEW_TOP_LEVEL_DOMAINS_HEADER_LAUNCH); _ck(_v, 9, 0, currVal_2); }); }
export function View_NewTLDsTableComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "new-tlds-table", [], null, null, null, View_NewTLDsTableComponent_0, RenderType_NewTLDsTableComponent)), i0.ɵdid(1, 49152, null, 0, i5.NewTLDsTableComponent, [i6.LanguageService, i7.DictionaryService], null, null)], null, null); }
var NewTLDsTableComponentNgFactory = i0.ɵccf("new-tlds-table", i5.NewTLDsTableComponent, View_NewTLDsTableComponent_Host_0, { header: "header", list: "list", isLoading: "isLoading" }, {}, []);
export { NewTLDsTableComponentNgFactory as NewTLDsTableComponentNgFactory };
