<div class="container">
    <breadcrumb [links]="breadcrumbs"></breadcrumb>
    <div class="panel panel-default has-sidebar">
        <div class="col-md-4 panel-body checkout-side" [innerHTML]="page?.sideContent">
        </div>
        <div class="col-md-8 panel-body page-content">
            <h1>{{dictionary?.Checkout_Title}}</h1>

            <div class="panel panel-primary">
                <div class="panel-header clearfix">
                    <h2 class="panel-heading pull-left">{{dictionary?.Checkout_Summary}}</h2>
                    <div class="btn-group pull-right gap-half" *ngIf="items.length > 0">
                        <button type="button" class="btn btn-link btn-md text-danger" (click)="onDeleteAll()">
                            <i class="glyph-delete glyph-fw"></i>
                            <span>{{dictionary?.Checkout_Delete_All}}</span>
                        </button>
                    </div>
                </div>
                <div class="table-container">
                    <table class="table table-condensed cart-summary-table">
                        <tbody>
                            
                            <tr *ngIf="isLoading" >
                                <td colspan="4"><div class="loading-lg"></div></td>
                            </tr>
                            
                            <ng-container *ngIf="!isLoading">
                                <tr *ngFor="let item of items" checkout-table-row [item]="item" (itemDeleteEvent)="onItemDelete($event)"></tr>
                                <tr *ngIf="items.length == 0">
                                    <td colspan="4">{{dictionary?.Checkout_Cart_Is_Empty}}</td>
                                </tr>
                            </ng-container>

                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row checkout-footer">
                <div class="col-sm-6 col-sm-offset-6 text-right">
                    <p class="checkout-total">
                        <span>{{dictionary?.Checkout_Total_Price}}</span>
                        <span class="big-number"> {{totalPrice}} {{selectedCurrency}}</span>
                    </p>
                    <p class="checkout-total">
                        <span class="number">{{totalVatText}}</span>
                    </p>

                    <button class="btn btn-success btn-lg btn-block pull-right loadable-button" [ngClass]="{'disabled': items.length === 0}" (click)="onCheckout()" [innerHtml]="dictionary?.Checkout_Button"></button>
                </div>
            </div>
        </div>
    </div>
</div>