<div class="grid-more-btn" [hidden]="expanded">
    <button class="btn btn-link btn-lg" (click)="expand()">
        <span class="grid-more-btn-label">
        <span>{{dictionary?.Panel_Items_Show_More}}</span>
        <i class="fa fa-chevron-down"></i>
        </span>
    </button>
</div>

<div class="grid-more-btn" [hidden]="!expanded">
    <button class="btn btn-link btn-lg" (click)="collapse()">
        <span class="grid-more-btn-label">
        <span>{{dictionary?.Panel_Items_Show_Less}}</span>
        <i class="fa fa-chevron-up"></i>
        </span>
    </button>
</div>