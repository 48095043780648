var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { LanguageService } from "../../../../services/language/language.service";
import { Localization } from "../../../../services/language/localization.model";
import { Router } from "@angular/router";
import { CurrencyService } from "../../../../services/currency.service";
import { DictionaryService } from "../../../../services/dictionary.service";
var LocalizationMenu = /** @class */ (function () {
    function LocalizationMenu(languageService, router, currencyService, dictionaryService) {
        var _this = this;
        this.languageService = languageService;
        this.router = router;
        this.currencyService = currencyService;
        this.showLocalizationMenu = false;
        this.currencies = [
            { name: 'Svenska kronor', checked: false, code: 'SEK' },
            { name: 'Euro', checked: false, code: 'EUR' },
            { name: 'American Dollar', checked: false, code: 'USD' }
        ];
        dictionaryService.getDictionary().subscribe(function (dictionary) { return _this.dictionary = dictionary; });
        this.languages = languageService.languages.map(function (lang) {
            return __assign({}, lang, { checked: false });
        });
        languageService.getLanguage().subscribe(function (lang) {
            _this.selectedLanguage = _this.languages.find(function (x) { return x.code === lang; });
            if (!_this.selectedLanguage) {
                _this.selectedLanguage = _this.languages[0];
            }
            _this.languages.forEach(function (x) {
                x.checked = x.code === _this.selectedLanguage.code;
            });
        });
        currencyService.current.subscribe(function (currency) {
            _this.selectedCurrency = _this.currencies.find(function (x) { return x.code === currency; });
            _this.selectedCurrency.checked = true;
        });
    }
    LocalizationMenu.prototype.clickOutside = function () {
        this.showLocalizationMenu = false;
    };
    LocalizationMenu.prototype.ToggleLocalizationMenu = function () {
        this.showLocalizationMenu = !this.showLocalizationMenu;
    };
    LocalizationMenu.prototype.ToogleChoosenLanguage = function (language) {
        if (language.code === this.selectedLanguage.code)
            return;
        this.selectedLanguage.checked = false;
        var newUrl = this.router.url.replace(this.selectedLanguage.code, language.code);
        this.router.navigateByUrl(newUrl);
    };
    LocalizationMenu.prototype.ToggleChosenCurrency = function (currency) {
        if (this.selectedCurrency) {
            this.selectedCurrency.checked = false;
        }
        currency.checked = true;
        this.selectedCurrency = currency;
        this.currencyService.set(currency.code);
    };
    return LocalizationMenu;
}());
export { LocalizationMenu };
var LocalizationCheckable = /** @class */ (function (_super) {
    __extends(LocalizationCheckable, _super);
    function LocalizationCheckable() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return LocalizationCheckable;
}(Localization));
