import { Component, Inject } from "@angular/core";
import { BasketItem } from "../../services/basket/basketItem.interface";
import { BasketService } from "../../services/basket/basket.service";
import { PriceService } from "../../services/price/price.service";
import { DictionaryService } from "../../services/dictionary.service";
import { Page } from "../../services/page/page.datatype";
import { precisionRoundWithZeroes } from "../../shared/util";
import { CheckoutPage } from "./checkoutPage.interface";
import { PageService } from "../../services/page/page.service";
import { NamedLink } from "../../shared/datatypes/namedlink.datatype";
import { CurrencyService } from "../../services/currency.service";

@Component({
    templateUrl: "./checkout.component.html"
})
export class CheckoutComponent {

    page?: CheckoutPage;
    items: BasketItem[] = [];
    totalPrice: string;
    vatPrice: string;
    vatRate: number;
    selectedCurrency: string;

    dictionary: any;
    totalVatText: string;

    breadcrumbs: NamedLink[];
    isLoading:boolean;

    constructor(public basketService: BasketService,
                currencyService: CurrencyService,
                priceService: PriceService,
                dictionaryService: DictionaryService,
                @Inject("CP_URL") public CP_URL: string,
                pageService: PageService) {
        dictionaryService.getDictionary().subscribe(dictionary => {
            this.dictionary = dictionary
            this.updateTotalVatText()
            this.breadcrumbs = [{ name: "Checkout", link: "" }]
        })

        pageService.getContent(null, "checkout").subscribe((page: Page<CheckoutPage>) => {
            this.page = page.customProperties;
        })

        basketService.getBasket().subscribe(basketItems => {
            this.items = basketItems;

            this.vatRate = priceService.vatRate;
            this.updateTotalPrice();
            this.isLoading = false;
        });

        currencyService.current.subscribe(currency => {
            this.selectedCurrency = currency;
        });
    }
    ngOnInit() {
        this.isLoading = true;
        this.basketService.setBasketData(); 
    }
    
    updateTotalVatText() {
        if (this.dictionary) {
            this.totalVatText = this.dictionary.Checkout_Total_Vat
            .replace("{{totalVat}}", this.vatPrice)
            .replace("{{currency}}", this.selectedCurrency)
        }
    }

    updateTotalPrice() {
        let totalPrice = this.items.reduce((total: number, item: BasketItem) => total + item.price, 0)

        this.totalPrice = precisionRoundWithZeroes(totalPrice, 2)
        this.vatPrice = precisionRoundWithZeroes(this.vatRate * totalPrice, 2)
        this.updateTotalVatText()
    }

    onItemDelete(name: string) {
        this.items = this.items.filter(item => item.item !== name)
        this.basketService.removeFromBasket(name);
        this.updateTotalPrice()
    }

    onDeleteAll() {
        for(let item of this.items){
            this.basketService.removeFromBasket(item.item);
        }

        this.items = []
        this.updateTotalPrice()
    }

    onCheckout() {
        window.location.href = this.CP_URL + '/checkout'
    }
}