var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AfterViewInit, OnInit } from "@angular/core";
import { ModalComponent } from "../../../shared/modal/modal.component";
import { DictionaryService } from "../../../services/dictionary.service";
import { BasketService } from "../../../services/basket/basket.service";
import { BsModalRef } from "ngx-bootstrap/modal";
import { DomainItemAction } from "../../../services/API/enums/api.domainitemaction.enum";
var TransferDomainModalComponent = /** @class */ (function (_super) {
    __extends(TransferDomainModalComponent, _super);
    // private tooltipelement: any;
    function TransferDomainModalComponent(bsModalRef, dictionaryService, basketService) {
        var _this = _super.call(this, bsModalRef) || this;
        _this.bsModalRef = bsModalRef;
        _this.basketService = basketService;
        _this.modalId = "transfer-domain-modal";
        dictionaryService.getDictionary().subscribe(function (dictionary) {
            _this.dictionary = dictionary;
            // if(this.tooltipelement){
            //     //Solves the weird double tooltip issue when changing languages (see https://github.com/twbs/bootstrap/issues/15359)
            //     this.tooltipelement.tooltip("destroy");
            // }
        });
        return _this;
    }
    TransferDomainModalComponent.prototype.ngOnInit = function () {
        this.title = this.dictionary.Transfer_Domain_Title_Text + " " + this.domain.fullName();
    };
    TransferDomainModalComponent.prototype.ngAfterViewInit = function () {
        // this.tooltipelement = $("#info-tooltip");
        // this.tooltipelement.tooltip();
    };
    TransferDomainModalComponent.prototype.submit = function () {
        this.basketService.addToBasket({
            item: this.domain.fullName(),
            itemid: 0,
            itemaction: DomainItemAction.Transfer,
            price: this.price,
            auth: this.auth
        });
        this.domain.inCart = true;
        this.close();
        // debugger
    };
    return TransferDomainModalComponent;
}(ModalComponent));
export { TransferDomainModalComponent };
