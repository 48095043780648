/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./row/searchDomainsTableRow.component.ngfactory";
import * as i2 from "./row/searchDomainsTableRow.component";
import * as i3 from "../../../services/currency.service";
import * as i4 from "../../../services/modal.service";
import * as i5 from "@angular/common";
import * as i6 from "./searchDomainsTable.component";
var styles_SearchDomainsTableComponent = [];
var RenderType_SearchDomainsTableComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SearchDomainsTableComponent, data: {} });
export { RenderType_SearchDomainsTableComponent as RenderType_SearchDomainsTableComponent };
function View_SearchDomainsTableComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tr", [["search-domains-table-row", ""]], null, null, null, i1.View_SearchDomainsTableRowComponent_0, i1.RenderType_SearchDomainsTableRowComponent)), i0.ɵdid(1, 114688, null, 0, i2.SearchDomainsTableRowComponent, [i3.CurrencyService, i4.ModalService], { domain: [0, "domain"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
function View_SearchDomainsTableComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SearchDomainsTableComponent_2)), i0.ɵdid(2, 802816, null, 0, i5.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.domains; _ck(_v, 2, 0, currVal_0); }, null); }
function View_SearchDomainsTableComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["class", "loading-lg"]], null, null, null, null, null))], null, null); }
export function View_SearchDomainsTableComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "panel panel-primary"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "panel-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "h2", [["class", "panel-heading"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), (_l()(), i0.ɵeld(4, 0, null, null, 6, "div", [["class", "table-container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 5, "table", [["class", "table table-condensed search-domains-table"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SearchDomainsTableComponent_1)), i0.ɵdid(7, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SearchDomainsTableComponent_3)), i0.ɵdid(9, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(10, 0, null, null, 0, "tfoot", [], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.domains.length > 0); _ck(_v, 7, 0, currVal_1); var currVal_2 = (_co.domains.length === 0); _ck(_v, 9, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.header; _ck(_v, 3, 0, currVal_0); }); }
export function View_SearchDomainsTableComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "search-domains-table", [], null, null, null, View_SearchDomainsTableComponent_0, RenderType_SearchDomainsTableComponent)), i0.ɵdid(1, 49152, null, 0, i6.SearchDomainsTableComponent, [], null, null)], null, null); }
var SearchDomainsTableComponentNgFactory = i0.ɵccf("search-domains-table", i6.SearchDomainsTableComponent, View_SearchDomainsTableComponent_Host_0, { header: "header", domains: "domains" }, {}, []);
export { SearchDomainsTableComponentNgFactory as SearchDomainsTableComponentNgFactory };
