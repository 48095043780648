/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../panelItem/panelItem.component.ngfactory";
import * as i2 from "../../panelItem/panelItem.component";
import * as i3 from "../../whoisSearch/whoisSearch.component.ngfactory";
import * as i4 from "../../whoisSearch/whoisSearch.component";
import * as i5 from "../../../services/dictionary.service";
import * as i6 from "../../../services/API/api.interface.service";
import * as i7 from "../../../services/modal.service";
import * as i8 from "./whoIsPanel.component";
var styles_WhoIsPanel = [];
var RenderType_WhoIsPanel = i0.ɵcrt({ encapsulation: 2, styles: styles_WhoIsPanel, data: {} });
export { RenderType_WhoIsPanel as RenderType_WhoIsPanel };
export function View_WhoIsPanel_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "panel-item", [["class", "panel-item"]], null, null, null, i1.View_PanelItemComponent_0, i1.RenderType_PanelItemComponent)), i0.ɵdid(1, 49152, null, 0, i2.PanelItemComponent, [], null, null), (_l()(), i0.ɵeld(2, 0, null, 0, 1, "h2", [["class", "panel-heading"], ["header", ""]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), (_l()(), i0.ɵeld(4, 0, null, 1, 0, "p", [["body", ""]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, 2, 3, "div", [["class", "search-widget"], ["footer", ""]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 2, "div", [["class", "form-item compound"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "whois-search", [], null, null, null, i3.View_WhoisSearchComponent_0, i3.RenderType_WhoisSearchComponent)), i0.ɵdid(8, 49152, null, 0, i4.WhoisSearchComponent, [i5.DictionaryService, i6.ApiService, i7.ModalService], null, null)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.dictionary == null) ? null : _co.dictionary.Whois_Title); _ck(_v, 3, 0, currVal_0); var currVal_1 = ((_co.dictionary == null) ? null : _co.dictionary.Whois_Body); _ck(_v, 4, 0, currVal_1); }); }
export function View_WhoIsPanel_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "who-is-panel", [], null, null, null, View_WhoIsPanel_0, RenderType_WhoIsPanel)), i0.ɵdid(1, 49152, null, 0, i8.WhoIsPanel, [i5.DictionaryService, i6.ApiService, i7.ModalService], null, null)], null, null); }
var WhoIsPanelNgFactory = i0.ɵccf("who-is-panel", i8.WhoIsPanel, View_WhoIsPanel_Host_0, {}, {}, []);
export { WhoIsPanelNgFactory as WhoIsPanelNgFactory };
