<div role="dialog" class="popover popover-alert cart-confirmation bottom fade" [ngClass]="{in: show, hide: !active}">
    <div class="arrow"></div>
    <div class="popover-inner">
        <div class="popover-title">
            <button type="button" class="close" (click)="close($event)">&times;</button>
            <ng-content select="[header]"></ng-content>
        </div>
        <div class="popover-content">
            <ng-content select="[body]"></ng-content>
        </div>
    </div>
</div>