import { Component, Input } from "@angular/core";
import { NewTLD } from "./newTLD.interface";
import { LanguageService } from "../../../services/language/language.service";
import { DictionaryService } from "../../../services/dictionary.service";

@Component({
    selector: "new-tlds-table",
    templateUrl: "./newTldsTable.component.html"
})
export class NewTLDsTableComponent {
    @Input()
    public header: string;
    @Input()
    public list: NewTLD[];
    @Input()
    isLoading: boolean = true;

    dictionary: any;

    constructor(public languageService: LanguageService, dictionaryService: DictionaryService) {
        dictionaryService.getDictionary().subscribe(dictionary => this.dictionary = dictionary)
    }
}