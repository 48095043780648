import { Component, Input } from "@angular/core";
import { NamedLink } from "../../shared/datatypes/namedlink.datatype";
import { DictionaryService } from "../../services/dictionary.service";
import { LanguageService } from "../../services/language/language.service";


@Component({
    selector: "side-menu",
    templateUrl: "./sideMenu.component.html"
})
export class SideMenuComponent {
    @Input()
    title: NamedLink;
    @Input()
    links: NamedLink[];

    @Input()
    activeMenuItemId = -1;

    public dictionary: any;

    constructor(ds: DictionaryService, public languageService: LanguageService) {
        ds.getDictionary().subscribe(x => this.dictionary = x);
    }
}