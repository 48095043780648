import { AfterViewInit, Component, OnInit } from "@angular/core";
import { ModalComponent } from "../../../shared/modal/modal.component";
import { DictionaryService } from "../../../services/dictionary.service";
import { BasketService } from "../../../services/basket/basket.service";
import { Domain } from "../domain.interface";
import { BsModalRef } from "ngx-bootstrap/modal";
import { DomainItemAction } from "../../../services/API/enums/api.domainitemaction.enum";

@Component({
    templateUrl: "./transferDomainModal.component.html"
})
export class TransferDomainModalComponent extends ModalComponent implements OnInit, AfterViewInit {
    domain: Domain;
    price: number;

    auth: string;
    title: string;
    public dictionary: any;

    // private tooltipelement: any;

    constructor(public bsModalRef: BsModalRef, dictionaryService: DictionaryService, private basketService: BasketService) {
        super(bsModalRef);
        this.modalId = "transfer-domain-modal";
        dictionaryService.getDictionary().subscribe(dictionary => {
            this.dictionary = dictionary
            // if(this.tooltipelement){
            //     //Solves the weird double tooltip issue when changing languages (see https://github.com/twbs/bootstrap/issues/15359)
            //     this.tooltipelement.tooltip("destroy");
            // }
        })
    }

    ngOnInit() {
        this.title = `${this.dictionary.Transfer_Domain_Title_Text} ${this.domain.fullName()}`
    }

    ngAfterViewInit() {
        // this.tooltipelement = $("#info-tooltip");
        // this.tooltipelement.tooltip();
    }

    submit() {
        this.basketService.addToBasket({
            item: this.domain.fullName(),
            itemid: 0,
            itemaction: DomainItemAction.Transfer,
            price: this.price,
            auth: this.auth
        })
        this.domain.inCart = true;
        this.close();
        // debugger
    }
}