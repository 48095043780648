<td class="max-3">
    <span class="label" [ngClass]="labelClass">
        <i [ngClass]="labelGlyph"></i>
        <span> {{dictionary ? dictionary[labelName] : ""}}</span>
    </span>
</td>

<td class="domain">
    {{item.item}}
</td>
<td class="text-right">
    <span class="price hidden-xs">{{item.price| number:"1.2-2"}}</span>
</td>

<td class="text-right max-2">
    <div class="btn-group">
        <button type="button" class="btn btn-danger btn-sm" (click)="onDelete()">
            <i class="glyph-delete glyph-fw"></i>
            <span class="sr-only loadable-button-label">{{dictionary?.Checkout_Delete_Item}}</span>
        </button>
    </div>
</td>