import { AfterViewInit, Component, Inject, Input, OnChanges, PLATFORM_ID, PlatformRef } from "@angular/core";
import { CarouselItem } from "./carouselItem.interface";
import { LanguageService } from "../../../services/language/language.service";
import { DOCUMENT, isPlatformBrowser } from "@angular/common";
import { addClass, isNumericString, removeClass } from "../../../shared/util";
import * as Color from "color";

// TODO: Add touch event listeners for mobile swiping // Maia (2018-09-03)
@Component({
    selector: "main-slider",
    templateUrl: "./main-slider.component.html"
})
export class MainSliderComponent implements AfterViewInit, OnChanges {

    @Input()
    items: CarouselItem[];

    isBrowser: boolean;

    defaultButtonColorHighlightFactor = 0.12;

    activeSlideIndex = 0;
    animateTimeout: any;
    isAnimating = false;

    timerInterval = 10000;
    timerIntervalId: any;
    isTimerStopped = false;

    constructor(public languageService: LanguageService, @Inject(DOCUMENT) public document: any, @Inject(PLATFORM_ID) platformId: PlatformRef) {
        this.isBrowser = isPlatformBrowser(platformId);
    }

    ngOnChanges(changes: any): void {
        if (changes.items) {
            if (changes.items.firstChange) {
                this.items[0].active = true;
                this.prepareItems();
            } else {
                this.items[this.activeSlideIndex].active = true;
                if (this.isBrowser) {
                    this.resetItemAnimations();
                    this.startTimer();
                }
            }
        }
    }

    ngAfterViewInit(): void {
        this.addStylesheet();

        if (this.isBrowser) {
            this.startTimer();
        }
    }

    private prepareItems() {
        if (this.items) {
            this.items.forEach((item, index) => {
                if (!item.mobileBackgroundImage) item.mobileBackgroundImage = item.backgroundImage;
                if (!item.mobileContent) item.mobileContent = item.content;
                if (!item.mobileButtonLink) item.mobileButtonLink = item.buttonLink;
                if (!item.mobileButtonText) item.mobileButtonText = item.buttonText;
            });
        }
    }

    private prepareStylesheet() {
        let stylesheet: any = {};

        this.items.forEach((item, index) => {
            if (item.buttonColor && !item.buttonHighlightColor) {
                item.buttonHighlightColor = this.getHighlightColor(item.buttonColor);
            }
            if (item.mobileButtonColor && !item.mobileButtonHighlightColor) {
                item.mobileButtonHighlightColor = this.getHighlightColor(item.mobileButtonColor);
            }

            stylesheet[".slide-" + index] = {
                ".item-desktop": {
                    "background-image": "url(" + item.backgroundImage + ")",
                    ".carousel-caption": {
                        "text-align": item.alignment
                    },
                    ".box": {
                        "background-color": item.boxBackgroundColor,
                        "max-width": isNumericString(item.maxWidth) ? item.maxWidth + "px" : item.maxWidth,
                    },
                    ".carousel-button": {
                        "text-align": item.buttonAlignment
                    },
                    ".carousel-button a": {
                        "color": item.buttonTextColor,
                        "background-color": item.buttonColor,
                    },
                    ".carousel-button a:hover, .carousel-button a:focus": {
                        "background-color": item.buttonHighlightColor,
                    }
                },
                ".item-mobile": {
                    ".carousel-button": {
                        "text-align": item.buttonAlignment
                    },
                    ".carousel-button a": {
                        "color": (item.mobileButtonTextColor || item.buttonTextColor),
                        "background-color": (item.mobileButtonColor || item.buttonColor),
                    },
                    ".carousel-button a:hover, .carousel-button a:focus": {
                        "background-color": (item.mobileButtonHighlightColor || item.buttonHighlightColor)
                    }
                }
            }
        });

        return stylesheet;
    }

    private addStylesheet() {
        let stylesheet = this.prepareStylesheet();

        if (!this.document.getElementById('main-slider-style')) {
            let style = this.document.createElement('style');
            style.type = 'text/css';
            style.setAttribute('id', 'main-slider-style');

            style.innerHTML = this.extractCss(stylesheet) + "\n" + this.getImagePreloadHackCss();

            this.document.getElementsByTagName("head")[0].appendChild(style);
        }
    }

    private extractCss(stylesheet: any, parentName?: string): string {
        let css: string[] = [];
        let childCss: string[] = [];
        parentName = parentName ? parentName : "";

        for (let prop in stylesheet) {
            if (stylesheet.hasOwnProperty(prop)) {
                if (typeof stylesheet[prop] == "object") {
                    let selector = parentName ? (parentName + " " + prop) : prop;
                    childCss.push(this.extractCss(stylesheet[prop], selector));
                } else {
                    if (stylesheet[prop] !== "null") {
                        css.push("  " + prop + ": " + stylesheet[prop] + ";")
                    }
                }
            }
        }

        if (css.length > 0) {
            css.unshift(parentName + " {");
            css.push("}");
        }
        return css.concat(childCss).join("\n");
    }

    private getImagePreloadHackCss(): string {
        let css = `@media screen and (min-width: 767px) {"
  .carousel-slider::after {"
    position: absolute; width:0; height:0; overflow:hidden; z-index:-1;";
    content:`;
        this.items.forEach((item) => {
            css += " url(" + item.backgroundImage + ")";
        });

        css += "\n  }\n}";
        return css;
    }

    private getHighlightColor(baseColor: string): string {
        let color = Color(baseColor);
        let factor = this.defaultButtonColorHighlightFactor;
        color = color.isLight() ? color.darken(factor) : color.lighten(factor);
        return color.rgb().string();
    }

    private resetItemAnimations() {
        this.isAnimating = false;
        this.items.forEach((slide, i) => {
            let element = this.document.getElementById("slide-" + i);
            removeClass(element, "next");
            removeClass(element, "prev");
            removeClass(element, "left");
            removeClass(element, "right");
        });
    };

    private startTimer(enable?: boolean) {
        if (enable) this.isTimerStopped = false;
        if (!this.isTimerStopped) {
            this.clearTimer();
            this.timerIntervalId = setInterval(() => {
                this.next(true);
            }, this.timerInterval);
        }
    }

    stopTimer() {
        this.isTimerStopped = true;
        this.clearTimer();
    }

    private clearTimer() {
        clearInterval(this.timerIntervalId);
    }

    private forceReflow(element: HTMLElement) {
        return element.offsetWidth;
    }

    select(newIndex: number, direction?: string, skipTimerReset?: boolean) {
        if (!skipTimerReset) this.startTimer();

        let oldIndex = this.activeSlideIndex;

        if (!direction) direction = oldIndex < newIndex ? "next" : "prev";

        // This prevents first load from animating to itself
        if (oldIndex != newIndex && !this.isAnimating) {
            this.activeSlideIndex = newIndex;

            let nextElement = this.document.getElementById("slide-" + newIndex);
            let prevElement = this.document.getElementById("slide-" + oldIndex);

            if (!nextElement || !prevElement) return;

            if (this.animateTimeout) {
                clearTimeout(this.animateTimeout);
                this.animateTimeout = null;
                this.resetItemAnimations();
            }

            if (direction == "next") {
                addClass(nextElement, "next");
                this.forceReflow(nextElement);
                addClass(nextElement, "left");
                addClass(prevElement, "left");
            } else {
                addClass(nextElement, "prev");
                this.forceReflow(nextElement);
                addClass(nextElement, "right");
                addClass(prevElement, "right");
            }

            this.isAnimating = true;
            this.animateTimeout = setTimeout(() => {
                this.resetItemAnimations();
                this.items[oldIndex].active = false;
                this.items[newIndex].active = true;
            }, 800);
        }
    }

    next(skipTimerReset?: boolean) {
        let nextIndex = this.activeSlideIndex + 1;
        if (nextIndex >= this.items.length) {
            nextIndex = 0;
        }
        this.select(nextIndex, "next", skipTimerReset);
    }

    prev(skipTimerReset?: boolean) {
        let prevIndex = this.activeSlideIndex - 1;
        if (prevIndex < 0) {
            prevIndex = this.items.length - 1;
        }
        this.select(prevIndex, "prev", skipTimerReset);
    }

}