import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { WhoisModalComponent } from "./whoisModal/whoisModal.component";
import { ModalComponent } from "./modal/modal.component";
import { ClickOutsideDirective } from "./directives/clickOutside.directive";
import { DynamicRouterLinkDirective } from "./directives/dynamicRouterLink.directive";
import { PopoverComponent } from "./popover/popover.component";
import { WhoisPopoverComponent } from "./whoisPopover/whoisPopover.component";

@NgModule({
    declarations: [
        WhoisModalComponent,
        ModalComponent,
        ClickOutsideDirective,
        DynamicRouterLinkDirective,
        PopoverComponent,
        WhoisPopoverComponent
    ],
    entryComponents: [
        WhoisModalComponent,
        ModalComponent,
    ],
    imports: [
        CommonModule
    ],
    exports: [
        WhoisModalComponent,
        ModalComponent,
        ClickOutsideDirective,
        DynamicRouterLinkDirective,
        PopoverComponent,
        WhoisPopoverComponent
    ]
})
export class SharedModule {
}