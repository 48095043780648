<article class="panel panel-info">
    <div class="panel-container">
        <header class="panel-header">
            <ng-content select="[header]"></ng-content>
        </header>
        <div class="panel-body">
            <ng-content select="[body]"></ng-content>
        </div>
    </div>
    <footer class="panel-footer"><ng-content select="[footer]"></ng-content></footer>
</article>