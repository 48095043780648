import { Domain } from "./domain.interface";
import { BasketService } from "../../services/basket/basket.service";
import { ModalService } from "../../services/modal.service";
import { TransferDomainModalComponent } from "./transferDomainModal/transferDomainModal.component";
import { DomainItemAction } from "../../services/API/enums/api.domainitemaction.enum";

export const domainStates = {
    addToCartButtonIcon: {
        available: "glyph-add-to-cart",
        inCart: "glyph-delete",
        unavailable: "glyph-transfer",
        transferInCart: "glyph-delete",
        loading: "glyph-spinner"
    },
    addToCartButtonClass: {
        available: "btn-success",
        inCart: "btn-danger",
        unavailable: "btn-warning",
        transferInCart: "btn-danger"
    },
    addToCartButtonTextKey: {
        available: "Add_To_Cart_Button",
        inCart: "Remove_From_Cart_Button",
        unavailable: "Search_Domains_Transfer",
        transferInCart: "Remove_From_Cart_Button"
    },
    availabiltyIcon: {
        available: "glyph-available",
        unavailable: "glyph-unavailable",
        loading: "glyph-spinner",
        invalid: "glyph-invalid"
    },
    availabilityTextClass: {
        available: "text-available",
        unavailable: "text-unavailable",
        loading: "loading text-default",
        invalid: "text-error"
    },
    availabilityPanelClass: {
        available: "panel-success",
        unavailable: "panel-danger-dark",
        loading: "panel-primary",
        invalid: "panel-invalid"
    },
    promoClass: {
        available: "available",
        unavailable: "unavailable",
        inCart: "available selected",
        transferInCart: "unavailable selected",
        loading: "promo-loading"
    },
    addToCartOnClick: (basketService: BasketService, modalService: ModalService) => {
        return {
            available: (domain: Domain, price: number) => basketService.addToBasket({
                item: domain.fullName(),
                itemid: 0,
                itemaction: DomainItemAction.Register,
                price
            }),
            inCart: (domain: Domain) => basketService.removeFromBasket(domain.fullName()),
            transferInCart: (domain: Domain) => basketService.removeFromBasket(domain.fullName()),
            unavailable: (domain: Domain, price: number) => modalService.openModal(TransferDomainModalComponent, {
                domain,
                price
            })
        }
    },
    showWhoIs: { available: false, unavailable: true, transferInCart: false, inCart: false }
};