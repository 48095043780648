import { NgModule } from "@angular/core";
import { ShoppingCartButtonComponent } from "./headerPrimary/shoppingCartButton/shoppingCartButton.component";
import { SiteHeaderComponent } from "./siteHeader.component";
import { HeaderPrimaryComponent } from "./headerPrimary/headerPrimary.component";
import { HeaderTopComponent } from "./headerTop/headerTop.component";
import { MegaMenuComponent } from "./megaMenu/megaMenu.component";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { LocalizationMenu } from "./headerTop/localizationMenu/localizationMenu.component";
import { HeaderSearchComponent } from "./headerPrimary/search/headerSearch.component";
import { SharedModule } from "../../shared/shared.module";
import { AddedToCartPopoverComponent } from "./headerPrimary/shoppingCartButton/addedToCartPopover/addedToCartPopover.component";

@NgModule({
    declarations: [
        ShoppingCartButtonComponent,
        SiteHeaderComponent,
        HeaderPrimaryComponent,
        HeaderTopComponent,
        MegaMenuComponent,
        LocalizationMenu,
        HeaderSearchComponent,
        AddedToCartPopoverComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        SharedModule
    ],
    exports: [
        SiteHeaderComponent
    ]
})
export class HeaderModule {

}