/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./panelList.component";
var styles_PanelListComponent = [];
var RenderType_PanelListComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PanelListComponent, data: {} });
export { RenderType_PanelListComponent as RenderType_PanelListComponent };
export function View_PanelListComponent_0(_l) { return i0.ɵvid(0, [i0.ɵncd(null, 0)], null, null); }
export function View_PanelListComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "panel-list", [], null, null, null, View_PanelListComponent_0, RenderType_PanelListComponent)), i0.ɵdid(1, 49152, null, 0, i1.PanelListComponent, [], null, null)], null, null); }
var PanelListComponentNgFactory = i0.ɵccf("panel-list", i1.PanelListComponent, View_PanelListComponent_Host_0, {}, {}, ["*"]);
export { PanelListComponentNgFactory as PanelListComponentNgFactory };
