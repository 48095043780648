var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { PopoverComponent } from "../../../../../shared/popover/popover.component";
import { DictionaryService } from "../../../../../services/dictionary.service";
import { LanguageService } from "../../../../../services/language/language.service";
var AddedToCartPopoverComponent = /** @class */ (function (_super) {
    __extends(AddedToCartPopoverComponent, _super);
    function AddedToCartPopoverComponent(dictionaryService, languageService) {
        var _this = _super.call(this, dictionaryService) || this;
        _this.languageService = languageService;
        return _this;
    }
    AddedToCartPopoverComponent.prototype.open = function () {
        var _this = this;
        this.show = true;
        this.active = true;
        if (this.timer && this.timer.data) {
            clearTimeout(this.timer.data.handleId);
        }
        this.timer = setTimeout(function () {
            _this.close();
        }, this.showTime);
    };
    return AddedToCartPopoverComponent;
}(PopoverComponent));
export { AddedToCartPopoverComponent };
