<modal [modalId]="modalId" [title]="title" (closeEvent)="closeEvent.emit($event)">
    <ng-container body>
        <p [innerHTML]="dictionary?.Transfer_Domain_Information_Text"></p>
        <form (ngSubmit)="submit()">
            <label for="auth">{{dictionary?.Transfer_Domain_Authcode_Text}}
                <span class="info-note" id="info-tooltip" placement="right" tooltip="{{dictionary?.Transfer_Domain_Authcode_Information_Text}}">
                    <i class="glyph-info"></i>
                </span>
            </label>
            <input id="auth" class="form-control" [(ngModel)]="auth" name="auth"/>  
        </form>
    </ng-container>
    <ng-container footer>
        <button type="button" class="btn btn-primary" (click)="submit()">
            <i class="glyph-cart"></i>
            <span>{{dictionary?.Transfer_Domain_Add_To_Cart_Button}}</span>
        </button>
        <button type="button" class="btn btn-default" (click)="close()">{{dictionary?.Modal_Close_Button}}</button>
    </ng-container>
</modal>