import { Component, EventEmitter, Input, OnChanges, SimpleChanges } from "@angular/core";
import { DictionaryService } from "../../services/dictionary.service";

@Component({
    selector: "popover",
    templateUrl: "./popover.component.html"
})
export class PopoverComponent implements OnChanges {

    @Input("open")
    openEvent: EventEmitter<void>
    @Input()
    show = false;
    @Input()
    active = false;

    showTime = 2000;
    activeTime = 100;

    dictionary: any;

    constructor(dictionaryService: DictionaryService) {
        dictionaryService.getDictionary().subscribe((dictionary) => this.dictionary = dictionary)
    }

    ngOnChanges(changes: SimpleChanges): void {
        let open = changes["openEvent"];
        if (open && open.currentValue) {
            this.openEvent.subscribe(() => {
                this.open();
            })
        }
    }

    open() {
        this.show = true;
        this.active = true;
        setTimeout(() => {
            this.close();
        }, this.showTime);
    }

    close(event?: Event) {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        this.show = false;
        setTimeout(() => this.active = false, this.activeTime);
    }
}