import { Component, EventEmitter, Output } from "@angular/core";
import { DictionaryService } from "../../../services/dictionary.service";

@Component({
    selector: "table-search",
    templateUrl: "./tableSearch.component.html"
})
export class TableSearchComponent {
    @Output()
    public filter = new EventEmitter<Function>()

    public search: string;
    public dictionary: any;

    constructor(dictionaryService: DictionaryService) {
        dictionaryService.getDictionary().subscribe(d => {
            this.dictionary = d;
        })
    }

    onSearch(search: string) {
        this.filter.emit(this.filterFunction(search));
    }

    filterFunction(search: string) {
        return (list: any[]) => {
            if (search === "") {
                return list;
            } else {
                return list.filter(elem => elem.name.indexOf(search) >= 0)
            }
        }
    }
}