<content-component [pageTitle]="pageTitle" [heading]="heading" [subHeading]="subHeading" [bodyText]="bodyText">
    <div class="widget">
        <form (ngSubmit)="submit()" #punycodeform="ngForm">
            <div class="input-group input-group-fw">
                <div class="input-prepend">
                    <i class="glyph-whois"></i>
                </div>
                <input [(ngModel)]="searchModel" name="whoisSearchModel" type="text" class="domainname form-control" placeholder="{{dictionary?.Punycode_Search_Placeholder}}"
                    [pattern]="validationPattern" />
                <div class="btn-group input-inner">
                    <button type="submit" class="btn" [disabled]="form.invalid" (click)="submit()">
                        <i class="fa fa-search"></i>
                        <span class="sr-only">{{dictionary?.Index_Search_Button}}</span>
                    </button>
                </div>
            </div>
        </form>
    </div>

    <div class="input-group input-group-lg" *ngIf="result">
        <span class="input-group-addon input-sm">{{ dictionary?.Misc_Result }}</span>
        <input class="form-control" type="text" [(ngModel)]="result" id="domainname" name="domainname" placeholder="" readonly="readonly"
            value="" />
        <span class="input-inner">
            <button (click)="searchDomain()" class="btn btn-link btn-lg">
                <i class="fa fa-search"></i>
                <span class="sr-only">{{ dictionary?.Puny_Code_Converter_Search_Button }}</span>
            </button>
        </span>
    </div>
</content-component>