import { ChangeDetectorRef, Component, ViewChild } from "@angular/core";
import { BasePage } from "../../../shared/datatypes/basePage.data";
import { IContentComponent } from "../contentComponent/IContentComponent.interface";
import { DictionaryService } from "../../../services/dictionary.service";
import { NgForm } from "@angular/forms";
import { simpleDomainPattern } from "../../../shared/validationPatterns.constants";
import { ActivatedRoute, Router } from "@angular/router";
import { LanguageService } from "../../../services/language/language.service";
// import * as punyCode from "punycode";
const punyCode = require("punycode");

@Component({
    selector: "punycode-converter",
    templateUrl: "./punycodeConverter.component.html"
})
export class PunycodeConverterComponent extends BasePage implements IContentComponent {

    @ViewChild("punycodeform") form: NgForm;

    public validationPattern = simpleDomainPattern;
    public sideMenu: boolean;
    public dictionary: any;

    public searchModel: string;
    public result: string;

    constructor(private changeRef: ChangeDetectorRef,
                private dictionaryService: DictionaryService,
                private languageService: LanguageService,
                private route: ActivatedRoute,
                private router: Router) {
        super();

        dictionaryService.getDictionary().subscribe(dictionary => this.dictionary = dictionary);

        route.queryParams.subscribe(params => {
            let query = params["query"];
            if (query) {
                this.searchModel = query;
                this.convert(query);
            }
        });
    }

    setData(data: any): void {
        this.pageTitle = data.pageTitle;
        this.bodyText = data.bodyText;
        this.subHeading = data.subHeading;
        this.sideMenu = data.sideMenu;
        this.changeRef.detectChanges();
    }

    convert(query: string) {
        if (query.substr(0, 4) === "xn--") {
            this.result = punyCode.toUnicode(query);
        } else {
            this.result = punyCode.toASCII(query);
        }
    }

    submit() {
        if (!this.form.invalid) {
            let query = this.searchModel.toLowerCase();

            let currentUrl = this.router.url.split("?", 2)[0];
            let url = currentUrl + "?query=" + encodeURIComponent(query);
            this.router.navigateByUrl(url);
        }
    }

    searchDomain() {
        let url = `/${this.languageService.current}/searchdomains?domainname=${encodeURIComponent(this.result)}`;
        this.router.navigateByUrl(url);
    }

}