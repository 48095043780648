var config = window["config"];
var ɵ0 = config.API_URL, ɵ1 = config.CMS_URL, ɵ2 = config.CP_URL, ɵ3 = config.DICTIONARY_URL, ɵ4 = config.FEED_URL, ɵ5 = config.ENVIRONMENT;
var AppBrowserModule = /** @class */ (function () {
    function AppBrowserModule() {
    }
    return AppBrowserModule;
}());
export { AppBrowserModule };
export function getBaseUrl() {
    return document.getElementsByTagName('base')[0].href;
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5 };
