import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { combineLatest } from "rxjs/operators";
import { Domain, LoadableDomain } from "./domain.interface";
import { ApiService } from '../../services/API/api.interface.service';
import { CurrencyService } from '../../services/currency.service';
import { BasketService } from '../../services/basket/basket.service';
import { PriceService } from '../../services/price/price.service';
import { BasketItem } from '../../services/basket/basketItem.interface';
import { ApiStatusCode } from '../../services/API/enums/api.statuscodes.enum';
import { PriceType } from '../../services/price/priceclass.enum';
const punyCode = require("punycode");

@Injectable({
  providedIn: 'root'
})
export class SearchDomainsService {

  constructor(public api: ApiService,
    public currencyService: CurrencyService,
    public basketService: BasketService,
    public priceService: PriceService) 
  { }

  fullDomain (domainName: string, tld: string) : string { 
    return domainName + "." + tld;
  } 

  fullDomainPunyCode (domainName: string, tld: string): string { 
    let domainNamePunyCode = punyCode.toASCII(domainName);
    return domainNamePunyCode + "." + tld;
  }

  mapTldSubjects(domainMap: any, tldGrpups: any, domainName:string): LoadableDomain[] {
    return Object
        .keys(tldGrpups)
        .map(key => {
            let tld = tldGrpups[key];
            if (!domainMap[key]) {
                let subject = this.mapDomain(domainName, key, tld);
                let loadable: LoadableDomain = {
                    domain: subject,
                    name: domainName,
                    tld: key,
                    fullName: this.fullDomain(domainName, key)
                }
                domainMap[key] = loadable;
            }
            return domainMap[key];
        })
  }

  mapDomain(domainName: string, tld: string, tldObject: any): ReplaySubject<Domain> {
    let subject = new ReplaySubject<Domain>(1);
    let nameISPInvalidTld = tldObject === 0;

    this.currencyService.current.pipe(combineLatest(this.basketService.getBasket()))
      .subscribe(([currency, basketItems]) => {

        let tldStatus = '';
        if(nameISPInvalidTld){
          tldStatus = 'invalid';
        }

        let domain = this.getDomainObject(domainName, tld, undefined, tldStatus, tldObject, currency, basketItems);
       
        subject.next(domain);
    });
    
    this.api.searchDomains(this.fullDomainPunyCode(domainName, tld))
        .pipe(combineLatest(this.currencyService.current, this.basketService.getBasket()))
        .subscribe(([response, currency, basketItems]) => {
          if(response.code === ApiStatusCode.Success) {
            let domainObject = response.parameters[this.fullDomainPunyCode(domainName, tld)];
            let domainStatus = domainObject ? domainObject.status : response.parameters[0].status;
  
            let domain = this.getDomainObject(domainName, tld, domainObject, domainStatus, tldObject, currency, basketItems)
             
            subject.next(domain);
          }
          if(response.code === ApiStatusCode.Fail) {
            //Error handling
          }
        
        });
    return subject;
  }

  getDomainObject(
    domainName: string, 
    tld: string, 
    domainObject: any, 
    domainStatus: string, 
    prices: any, 
    currency: string, 
    basketItems: BasketItem[]) {

    let status = domainObject ? domainObject.status : domainStatus;
    let available = 
    status === "available" 
      ? true 
      : status === 'unavailable' 
        ? false 
        : undefined
    ;

    let invalid = status === "invalid";

    let price = 0;
    let priceSEK = 0
    if (!invalid) {
        if(!prices){
          return;
        }
        
        if(available === undefined  || available) {
          price = this.priceService.getPrice(prices, PriceType.Registration, currency);
          priceSEK = this.priceService.getPrice(prices, PriceType.Registration, "SEK");

        } else {
          price = this.priceService.getPrice(prices, PriceType.Transfer, currency);
          priceSEK = this.priceService.getPrice(prices, PriceType.Transfer, "SEK");
        }
    }

    let inCart = basketItems.find(dom => dom.item === this.fullDomain(domainName, tld)) ? true : false;
    let domain = new Domain(
        domainName,
        tld,
        price,
        priceSEK,
        available,
        inCart,
        invalid
    );

    return domain;
  }

  getTldGroupsOrder(tldGroups: string[]): string[] {
    let cctlds = tldGroups.filter(function(tldGroup){ 
      return tldGroup.endsWith("-CCTLDS")}
    );

    let tlds = tldGroups.filter(function(tldGroup){ 
      return tldGroup.endsWith("-TLDS")}
    );

    return [...cctlds, ...tlds]
  }
}
