import { ReplaySubject } from 'rxjs';
import { combineLatest } from "rxjs/operators";
import { Domain } from "./domain.interface";
import { ApiService } from '../../services/API/api.interface.service';
import { CurrencyService } from '../../services/currency.service';
import { BasketService } from '../../services/basket/basket.service';
import { PriceService } from '../../services/price/price.service';
import { ApiStatusCode } from '../../services/API/enums/api.statuscodes.enum';
import { PriceType } from '../../services/price/priceclass.enum';
import * as i0 from "@angular/core";
import * as i1 from "../../services/API/api.interface.service";
import * as i2 from "../../services/currency.service";
import * as i3 from "../../services/basket/basket.service";
import * as i4 from "../../services/price/price.service";
var punyCode = require("punycode");
var SearchDomainsService = /** @class */ (function () {
    function SearchDomainsService(api, currencyService, basketService, priceService) {
        this.api = api;
        this.currencyService = currencyService;
        this.basketService = basketService;
        this.priceService = priceService;
    }
    SearchDomainsService.prototype.fullDomain = function (domainName, tld) {
        return domainName + "." + tld;
    };
    SearchDomainsService.prototype.fullDomainPunyCode = function (domainName, tld) {
        var domainNamePunyCode = punyCode.toASCII(domainName);
        return domainNamePunyCode + "." + tld;
    };
    SearchDomainsService.prototype.mapTldSubjects = function (domainMap, tldGrpups, domainName) {
        var _this = this;
        return Object
            .keys(tldGrpups)
            .map(function (key) {
            var tld = tldGrpups[key];
            if (!domainMap[key]) {
                var subject = _this.mapDomain(domainName, key, tld);
                var loadable = {
                    domain: subject,
                    name: domainName,
                    tld: key,
                    fullName: _this.fullDomain(domainName, key)
                };
                domainMap[key] = loadable;
            }
            return domainMap[key];
        });
    };
    SearchDomainsService.prototype.mapDomain = function (domainName, tld, tldObject) {
        var _this = this;
        var subject = new ReplaySubject(1);
        var nameISPInvalidTld = tldObject === 0;
        this.currencyService.current.pipe(combineLatest(this.basketService.getBasket()))
            .subscribe(function (_a) {
            var currency = _a[0], basketItems = _a[1];
            var tldStatus = '';
            if (nameISPInvalidTld) {
                tldStatus = 'invalid';
            }
            var domain = _this.getDomainObject(domainName, tld, undefined, tldStatus, tldObject, currency, basketItems);
            subject.next(domain);
        });
        this.api.searchDomains(this.fullDomainPunyCode(domainName, tld))
            .pipe(combineLatest(this.currencyService.current, this.basketService.getBasket()))
            .subscribe(function (_a) {
            var response = _a[0], currency = _a[1], basketItems = _a[2];
            if (response.code === ApiStatusCode.Success) {
                var domainObject = response.parameters[_this.fullDomainPunyCode(domainName, tld)];
                var domainStatus = domainObject ? domainObject.status : response.parameters[0].status;
                var domain = _this.getDomainObject(domainName, tld, domainObject, domainStatus, tldObject, currency, basketItems);
                subject.next(domain);
            }
            if (response.code === ApiStatusCode.Fail) {
                //Error handling
            }
        });
        return subject;
    };
    SearchDomainsService.prototype.getDomainObject = function (domainName, tld, domainObject, domainStatus, prices, currency, basketItems) {
        var _this = this;
        var status = domainObject ? domainObject.status : domainStatus;
        var available = status === "available"
            ? true
            : status === 'unavailable'
                ? false
                : undefined;
        var invalid = status === "invalid";
        var price = 0;
        var priceSEK = 0;
        if (!invalid) {
            if (!prices) {
                return;
            }
            if (available === undefined || available) {
                price = this.priceService.getPrice(prices, PriceType.Registration, currency);
                priceSEK = this.priceService.getPrice(prices, PriceType.Registration, "SEK");
            }
            else {
                price = this.priceService.getPrice(prices, PriceType.Transfer, currency);
                priceSEK = this.priceService.getPrice(prices, PriceType.Transfer, "SEK");
            }
        }
        var inCart = basketItems.find(function (dom) { return dom.item === _this.fullDomain(domainName, tld); }) ? true : false;
        var domain = new Domain(domainName, tld, price, priceSEK, available, inCart, invalid);
        return domain;
    };
    SearchDomainsService.prototype.getTldGroupsOrder = function (tldGroups) {
        var cctlds = tldGroups.filter(function (tldGroup) {
            return tldGroup.endsWith("-CCTLDS");
        });
        var tlds = tldGroups.filter(function (tldGroup) {
            return tldGroup.endsWith("-TLDS");
        });
        return cctlds.concat(tlds);
    };
    SearchDomainsService.ngInjectableDef = i0.defineInjectable({ factory: function SearchDomainsService_Factory() { return new SearchDomainsService(i0.inject(i1.ApiService), i0.inject(i2.CurrencyService), i0.inject(i3.BasketService), i0.inject(i4.PriceService)); }, token: SearchDomainsService, providedIn: "root" });
    return SearchDomainsService;
}());
export { SearchDomainsService };
