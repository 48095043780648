import * as htmlEntites from "he";

export const precisionRound = (num: number, precision: number): number => {
    var factor = Math.pow(10, precision);
    return Math.round(num * factor) / factor;
}

export const precisionRoundWithZeroes = (num: number, precision: number): string => {
    let rounded = precisionRound(num, precision).toString();
    let dot = rounded.indexOf(".");
    if (dot > -1) {
        let missingZeroes = precision - rounded.slice(dot + 1, rounded.length).length;
        while (missingZeroes > 0) {
            rounded += "0";
            missingZeroes--;
        }
    }
    return rounded;
}

export const replaceToken = (text: string, token: string, replacement: string) => text ? text.replace(`{{${token}}}`, replacement) : "";

export const isNumericString = (str: string) => /^\d+$/.test(str)

export const stripHTMLTags = (str: string) => str.replace(/<[^>]+>/g, '');

export const stripHTMLEntities = (str: string) => htmlEntites.decode(str).replace("&nbsp;", " ");

export const addClass = (el: Element, str: string) => {
    if (!el) return;
    let classString = el.getAttribute("class") || "";
    let classes = classString.split(" ").filter(c => c.length > 0 && c !== str);
    classes.push(str);
    el.setAttribute("class", classes.join(" "));
};

export const removeClass = (el: Element, str: string) => {
    if (!el) return;
    let classString = el.getAttribute("class") || "";
    let classes = classString.split(" ").filter(c => c.length > 0 && c !== str);
    el.setAttribute("class", classes.join(" "));
};

export const unFormatPhone = (phone: string) => phone.replace(/[^+\d]/g, '');