import { Injectable } from "@angular/core";
import { ReplaySubject } from "rxjs";

@Injectable()
export class CurrencyService {
    public current: ReplaySubject<string>

    constructor() {
        this.current = new ReplaySubject(1);
        let currency = "SEK";
        if (typeof localStorage !== "undefined") {
            let saved = localStorage.getItem("currency")
            if (saved) {
                currency = saved;
            }
        }
        this.set(currency);
    }

    public set(value: string) {
        if (typeof localStorage !== "undefined") {
            localStorage.setItem("currency", value);
        }
        this.current.next(value);
    }
}