<div class="site-footer" role="contentinfo" ng-if="isEnabled">
    <div class="footer-siteinfo">
        <div class="container">
            <div class="footer-siteinfo-group">
                <div class="row footer-content-bottom">
                    <div class="col-sm-12 col-md-4 about">
                        <img class="logo" src="{{images.logo}}" alt="Name ISP logo">
                        <img class="icann-logo" src="{{images.icann}}" alt="ICANN accredited registrar">
                        <div [innerHTML]="footerData?.text">

                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-3 contact-us">
                        <div class="header">
                            <h4>{{dictionary?.Footer_Heading_Contact_Us}}</h4>
                        </div>
                        <ul class="call-us">
                            <li>
                                {{dictionary?.Footer_Desc_Contact_Us}}
                            </li>

                            <li class="footer-phone">
                                <span class="hidden-xs hidden-sm">
                                    <i class="fa fa-phone" aria-hidden="true"></i>
                                    {{footerData?.contactPhoneNumber}}
                                </span>
                                <a class="hidden-md hidden-lg" href="tel:{{mobilePhoneHref}}">
                                    <i class="fa fa-phone" aria-hidden="true"></i>
                                    {{footerData?.contactPhoneNumber}}
                                </a>
                            </li>

                            <li>{{footerData?.openingHours}}</li>
                            <li>
                                <a routerLink="/{{languageService.current}}/customer-service">
                                    <i class="fa fa-comment"></i>
                                    {{dictionary?.CustomerService_Title}}
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-3 link-list">
                        <div class="header">
                            <h4>{{dictionary?.Footer_Heading_Quicklinks}}</h4>
                        </div>

                        <ul>
                            <li>
                                <a href="{{cpUrl}}/login">
                                    <i class="glyph-portfolio" aria-hidden="true"></i>
                                    {{dictionary?.Footer_Link_Client_Area}}
                                </a>
                            </li>
                            <li>
                                <a routerLink="{{languageService.current}}/domain-names/all-top-level-domains">
                                    <i class="glyph-domains" aria-hidden="true"></i>
                                    {{dictionary?.Footer_Link_Top_Domain_Information}}
                                </a>
                            </li>
                            <li>
                                <a routerLink="{{languageService.current}}/domain-services/transfer-domains">
                                    <i class="glyph-transfer" aria-hidden="true"></i>
                                    {{dictionary?.Footer_Link_Move_Domains}}
                                </a>
                            </li>
                            <li>
                                <a routerLink="{{languageService.current}}/about/about-us">
                                    <i class="glyph-about icon-btn-icon" aria-hidden="true"></i>
                                    {{dictionary?.Footer_Link_About}}
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-2">
                        <figure class="logo-group footer-payment-methods">
                            <img *ngFor="let img of footerData?.paymentOptions" src="{{img.url}}" />
                        </figure>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="sub-footer">
        <div class="container">
            <nav class="sub-footer-links">
                <ul class="links">
                    <li>
                        <a routerLink="{{languageService.current}}/about/conditions">{{dictionary?.Sub_Footer_Conditions}}</a>
                    </li>
                </ul>

                <ul class="social-links">
                    <li class="twitter">
                        <a href="https://twitter.com/nameisp" target="_blank">
                            <i class="fab fa-twitter"></i>
                            <span>{{dictionary?.Sub_Footer_Twitter}}</span>
                        </a>
                    </li>
                    <li class="facebook">
                        <a href="https://www.facebook.com/nameisp" target="_blank">
                            <i class="fab fa-facebook"></i>
                            <span>{{dictionary?.Sub_Footer_Facebook}}</span>
                        </a>
                    </li>
                </ul>
            </nav>

            <span class="copyright">© {{footerData?.copyright}}</span>
        </div>
    </div>
</div>