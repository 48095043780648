/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../panelItem/panelItem.component.ngfactory";
import * as i2 from "../../../panelItem/panelItem.component";
import * as i3 from "@angular/common";
import * as i4 from "./infoPanel.component";
var styles_InfoPanelComponent = [];
var RenderType_InfoPanelComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_InfoPanelComponent, data: {} });
export { RenderType_InfoPanelComponent as RenderType_InfoPanelComponent };
function View_InfoPanelComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "span", [], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "flag tld-flag ", _co.flagClass, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_InfoPanelComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "dt", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "dd", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.label; _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit.data; _ck(_v, 3, 0, currVal_1); }); }
export function View_InfoPanelComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "panel-item", [["class", "description-list-panel"]], null, null, null, i1.View_PanelItemComponent_0, i1.RenderType_PanelItemComponent)), i0.ɵdid(1, 49152, null, 0, i2.PanelItemComponent, [], null, null), (_l()(), i0.ɵeld(2, 0, null, 0, 4, "div", [["header", ""]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "h2", [["class", "panel-heading"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InfoPanelComponent_1)), i0.ɵdid(6, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(7, 0, null, 1, 4, "dl", [["body", ""]], null, null, null, null, null)), i0.ɵdid(8, 278528, null, 0, i3.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), i0.ɵpod(9, { "container-loading": 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InfoPanelComponent_2)), i0.ɵdid(11, 802816, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.flagClass; _ck(_v, 6, 0, currVal_1); var currVal_2 = _ck(_v, 9, 0, _co.isLoading); _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.rows; _ck(_v, 11, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 4, 0, currVal_0); }); }
export function View_InfoPanelComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "info-panel", [], null, null, null, View_InfoPanelComponent_0, RenderType_InfoPanelComponent)), i0.ɵdid(1, 49152, null, 0, i4.InfoPanelComponent, [], null, null)], null, null); }
var InfoPanelComponentNgFactory = i0.ɵccf("info-panel", i4.InfoPanelComponent, View_InfoPanelComponent_Host_0, { rows: "rows", title: "title", flagClass: "flagClass", isLoading: "isLoading" }, {}, []);
export { InfoPanelComponentNgFactory as InfoPanelComponentNgFactory };
