
<div class="pagination table-pagination">
    <div class="pagination-buttons text-center">
        <div class="btn-group page-numbers">
            <button type="button" class="prev-button" [ngClass]="{'disabled': settings.page === 1}" (click)="prev()">
                <i class="glyph-previous"></i>
                <span class="paging-btn-label">{{dictionary?.Paging_Previous}}</span>
            </button>

                <ng-container *ngFor="let b of buttons">
                    <button type="button" class="first pagination-pages desktop" [disabled]="b.notClickable && b.notClickable()" (click)="buttonClick(b)" [ngClass]="{'active': b.value() === settings.page}" *ngIf="b.show()">
                        {{b.content()}}
                    </button>     
                </ng-container>
     
            <p type="button" class="btn first pagination-pages mobile pagination-page">
                <span>{{settings.page }} of {{ maxPages() }}</span>
            </p>

            <button type="button" class="next-button" [ngClass]="{'disabled': settings.page === maxPages()}" (click)="next()">

                <span class="paging-btn-label">{{dictionary?.Paging_Next}}</span>

                <i class="glyph-next"></i>

            </button>
        </div>
        <span class="static">{{pageText()}}</span>
    </div>
</div>