import { Component, EventEmitter, Input, Output } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";

@Component({
    selector: "modal",
    templateUrl: "./modal.component.html"
})
export class ModalComponent {

    @Input()
    public title: string;
    @Input()
    public modalId: string;

    @Output()
    closeEvent = new EventEmitter<void>()

    constructor(public bsModalRef: BsModalRef) {

    }

    close() {
        this.bsModalRef.hide();
    }
}