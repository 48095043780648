import { ChangeDetectorRef, Component } from "@angular/core";
import { BasePage } from "../../../shared/datatypes/basePage.data";
import { DomSanitizer } from "@angular/platform-browser";
import { IContentComponent } from "../contentComponent/IContentComponent.interface";


@Component({
    templateUrl: "./textContent.component.html"
})
export class TextContentComponent extends BasePage implements IContentComponent {

    public sideMenu: boolean;

    constructor(private changeRef: ChangeDetectorRef, private sanitizer: DomSanitizer) {
        super()
    }

    setData(data: any): void {
        this.pageTitle = data.pageTitle;
        this.bodyText = this.sanitizer.bypassSecurityTrustHtml(data.bodyText) as any;
        this.heading = data.heading;
        this.subHeading = data.subHeading;
        this.sideMenu = data.sideMenu;
        this.changeRef.detectChanges();
    }
}