/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "./promoslider.component";
import * as i4 from "../../../services/language/language.service";
import * as i5 from "../../../services/dictionary.service";
import * as i6 from "../../../services/API/api.interface.service";
import * as i7 from "../../../services/currency.service";
import * as i8 from "../../../services/price/price.service";
var styles_PromoSliderComponent = [];
var RenderType_PromoSliderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PromoSliderComponent, data: {} });
export { RenderType_PromoSliderComponent as RenderType_PromoSliderComponent };
function View_PromoSliderComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "promo-tld-price"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.dictionary == null) ? null : _co.dictionary.Loading_Text); _ck(_v, 1, 0, currVal_0); }); }
function View_PromoSliderComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["class", "promo-tld-price"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", " ", ""])), i0.ɵppd(2, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent.parent, 0), _v.parent.context.$implicit.price, "1.2-2")); var currVal_1 = _co.currency; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_PromoSliderComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "li", [["class", "promo-tld"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 7, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [["class", "promo-tld-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, [".", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PromoSliderComponent_2)), i0.ɵdid(6, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PromoSliderComponent_3)), i0.ɵdid(8, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = i0.ɵinlineInterpolate(2, "/", _co.languageService.current, "/domain-names/top-level-domain/", _v.context.$implicit.domain, ""); _ck(_v, 2, 0, currVal_2); var currVal_4 = _v.context.$implicit.priceIsLoading; _ck(_v, 6, 0, currVal_4); var currVal_5 = !_v.context.$implicit.priceIsLoading; _ck(_v, 8, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).target; var currVal_1 = i0.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = _v.context.$implicit.domain; _ck(_v, 4, 0, currVal_3); }); }
export function View_PromoSliderComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i2.DecimalPipe, [i0.LOCALE_ID]), (_l()(), i0.ɵeld(1, 0, null, null, 9, "div", [["class", "promo-slider"], ["id", "promoslider"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 8, "ul", [["class", "promo-tld-list"], ["id", "test"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PromoSliderComponent_1)), i0.ɵdid(4, 802816, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 5, "li", [["class", "promo-tld hidden-xs hidden-sm"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 4, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 7).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(7, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵeld(8, 0, null, null, 0, "span", [["class", "promo-tld-more"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "span", [["class", "promo-tld-price"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00A0"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.promoTlds; _ck(_v, 4, 0, currVal_0); var currVal_3 = i0.ɵinlineInterpolate(1, "/", _co.languageService.current, "/domain-names/all-top-level-domains"); _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i0.ɵnov(_v, 7).target; var currVal_2 = i0.ɵnov(_v, 7).href; _ck(_v, 6, 0, currVal_1, currVal_2); var currVal_4 = ((_co.dictionary == null) ? null : _co.dictionary.Index_Promo_View_All_Tlds); _ck(_v, 8, 0, currVal_4); }); }
export function View_PromoSliderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "promo-slider", [], null, null, null, View_PromoSliderComponent_0, RenderType_PromoSliderComponent)), i0.ɵdid(1, 573440, null, 0, i3.PromoSliderComponent, [i4.LanguageService, i5.DictionaryService, i6.ApiService, i7.CurrencyService, i8.PriceService], null, null)], null, null); }
var PromoSliderComponentNgFactory = i0.ɵccf("promo-slider", i3.PromoSliderComponent, View_PromoSliderComponent_Host_0, { tlds: "tlds" }, {}, []);
export { PromoSliderComponentNgFactory as PromoSliderComponentNgFactory };
