import { Component, EventEmitter, ViewChild } from "@angular/core";
import { simpleDomainPattern } from "../../shared/validationPatterns.constants";
import { NgForm } from "@angular/forms";
import { DictionaryService } from "../../services/dictionary.service";
import { ApiService } from "../../services/API/api.interface.service";
import { ModalService } from "../../services/modal.service";
import { WhoisModalComponent } from "../../shared/whoisModal/whoisModal.component";

@Component({
    selector: "whois-search",
    templateUrl: "./whoisSearch.component.html"
})
export class WhoisSearchComponent {

    @ViewChild("whoisform") form: NgForm;

    public dictionary: any;
    public searchModel: string;
    public showModal = false;

    public validationPattern = simpleDomainPattern;

    public openWhoisPopover = new EventEmitter<void>()

    constructor(dictionaryService: DictionaryService, private api: ApiService, private modalService: ModalService) {
        dictionaryService.getDictionary().subscribe(dictionary => {
            this.dictionary = dictionary;
        })
    }

    submit() {
        if (this.form.invalid) {
            this.openWhoisPopover.emit()
            return;
        }
        ;
        this.modalService.openModal(WhoisModalComponent, { domainName: this.searchModel })
    }
}