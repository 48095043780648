var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Router } from "@angular/router";
import * as rxjs from "rxjs";
import { LanguageResolver } from "../../../shared/resolvers/language.resolver";
import { LanguageService } from "../../../services/language/language.service";
import { PageService } from "../../../services/page/page.service";
import { Location } from "@angular/common";
var ContentCategoryResolver = /** @class */ (function (_super) {
    __extends(ContentCategoryResolver, _super);
    function ContentCategoryResolver(pageService, languageService, location, router) {
        var _this = _super.call(this, languageService, router, location) || this;
        _this.pageService = pageService;
        _this.location = location;
        return _this;
    }
    ContentCategoryResolver.prototype.resolve = function (route, state) {
        _super.prototype.resolve.call(this, route, state);
        var category = route.paramMap.get("first");
        return rxjs.of(this.pageService.getContentCategory(category));
    };
    return ContentCategoryResolver;
}(LanguageResolver));
export { ContentCategoryResolver };
