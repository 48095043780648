import {
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    SimpleChange,
    ViewChild,
    ViewContainerRef
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NamedLink } from "../../shared/datatypes/namedlink.datatype";
import { MenuItem } from "../../services/menu/menuItem.data";
import { MenuService } from "../../services/menu/menu.service";
import { LanguageService } from "../../services/language/language.service";
import { IContentComponent } from "./contentComponent/IContentComponent.interface";
import { map, mergeMap } from "rxjs/operators";

@Component({
    selector: "content-container",
    templateUrl: "./contentContainer.component.html"
})
export class ContentContainerComponent {

    @ViewChild("container", { read: ViewContainerRef }) container: ViewContainerRef

    public title: NamedLink;
    public links: NamedLink[];
    public activeMenuItemId: number;
    public showSideMenu: boolean;
    content: any;

    constructor(private route: ActivatedRoute,
                private componentFactoryResolver: ComponentFactoryResolver,
                menuService: MenuService,
                private languageService: LanguageService,
                private changeDetection: ChangeDetectorRef) {
        route.data
            .pipe(
                map(data => data.content),
                mergeMap(content => {
                    this.content = content
                    this.activeMenuItemId = content.id;
                    this.showSideMenu = content.data.sideMenu;
                    this.withPanel = content.type.name !== "ContentCategoryComponent";

                    if (this.viewInit) {
                        this.setComponent(content)
                    }
                    return menuService.getSubMenu(content.id);
                })
            )
            .subscribe((menu: MenuItem | null) => {
                if (menu) {
                    this.title = this.MenuItemToNamedLink(menu)
                    this.links = menu.items.map(item => this.MenuItemToNamedLink(item));
                }
            }, (error) => {
                console.log(error)
            });
    }

    withPanel = true;
    viewInit = false;

    ngAfterViewInit() {
        this.setComponent(this.content);
        this.viewInit = true;
    }

    ngOnChanges(changes: SimpleChange) {

    }

    setComponent(content: any) {
        let factory = this.componentFactoryResolver.resolveComponentFactory<IContentComponent>(content.type);
        this.container.clear();
        let component = this.container.createComponent(factory);
        component.instance.setData(content.data);
        this.changeDetection.detectChanges();
    }

    private MenuItemToNamedLink(menu: MenuItem): NamedLink {
        return { name: menu.pageTitle, link: "/" + this.languageService.current + "/" + menu.url, id: menu.id }
    }
}