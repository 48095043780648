import { Component, Input, ChangeDetectorRef } from "@angular/core";
import { LoadableDomain } from "../domain.interface";


@Component({
    selector: "search-domains-table",
    templateUrl: "./searchDomainsTable.component.html"
})
export class SearchDomainsTableComponent {

    constructor(){}

    @Input()
    public header: string = "";

    @Input()
    public domains: LoadableDomain[] = []
}