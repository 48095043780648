import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { MenuService } from "../../services/menu/menu.service";
import { LanguageService } from "../../services/language/language.service";
import { NamedLink } from "../../shared/datatypes/namedlink.datatype";
import { DictionaryService } from "../../services/dictionary.service";

@Component({
    selector: "breadcrumb",
    templateUrl: "./breadcrumb.component.html"
})
export class BreadcrumbComponent implements OnChanges {
    @Input()
    public contentId: number;

    @Input()
    links: NamedLink[] = [];

    public dictionary: any;

    constructor(public languageService: LanguageService, private menuService: MenuService, private dictionaryService: DictionaryService) {
        dictionaryService.getDictionary().subscribe(d => this.dictionary = d);
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (changes["contentId"] && changes["contentId"].currentValue) {
            this.menuService.getBreadCrumbs(changes["contentId"].currentValue).subscribe(breadcrumbs => {
                this.links = breadcrumbs;
            })
        }
    }
}