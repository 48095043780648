import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageService } from '../../../services/language/language.service';
import { DictionaryService } from '../../../services/dictionary.service';

@Component({
    selector: 'header-primary',
    templateUrl: './headerPrimary.component.html'
})
export class HeaderPrimaryComponent {
    public showMegaMenu: boolean = false;
    public dictionary: any;

    constructor(private router: Router, private languageService: LanguageService, dictionaryService: DictionaryService) {
        dictionaryService.getDictionary().subscribe(d => this.dictionary = d);
    }

    ToggleMegaMenu() {
        this.showMegaMenu = !this.showMegaMenu;
    }

    CloseMegaMenu() {
        this.showMegaMenu = false;
    }
}

