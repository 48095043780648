/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./panelExpandButton.component";
import * as i2 from "../../../services/dictionary.service";
var styles_PanelExpandButtonComponent = [];
var RenderType_PanelExpandButtonComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PanelExpandButtonComponent, data: {} });
export { RenderType_PanelExpandButtonComponent as RenderType_PanelExpandButtonComponent };
export function View_PanelExpandButtonComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "grid-more-btn"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "button", [["class", "btn btn-link btn-lg"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.expand() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "span", [["class", "grid-more-btn-label"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), (_l()(), i0.ɵeld(5, 0, null, null, 0, "i", [["class", "fa fa-chevron-down"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 5, "div", [["class", "grid-more-btn"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 4, "button", [["class", "btn btn-link btn-lg"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.collapse() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 3, "span", [["class", "grid-more-btn-label"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(10, null, ["", ""])), (_l()(), i0.ɵeld(11, 0, null, null, 0, "i", [["class", "fa fa-chevron-up"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.expanded; _ck(_v, 0, 0, currVal_0); var currVal_1 = ((_co.dictionary == null) ? null : _co.dictionary.Panel_Items_Show_More); _ck(_v, 4, 0, currVal_1); var currVal_2 = !_co.expanded; _ck(_v, 6, 0, currVal_2); var currVal_3 = ((_co.dictionary == null) ? null : _co.dictionary.Panel_Items_Show_Less); _ck(_v, 10, 0, currVal_3); }); }
export function View_PanelExpandButtonComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "panel-expand-button", [], null, null, null, View_PanelExpandButtonComponent_0, RenderType_PanelExpandButtonComponent)), i0.ɵdid(1, 49152, null, 0, i1.PanelExpandButtonComponent, [i2.DictionaryService], null, null)], null, null); }
var PanelExpandButtonComponentNgFactory = i0.ɵccf("panel-expand-button", i1.PanelExpandButtonComponent, View_PanelExpandButtonComponent_Host_0, {}, { onExpand: "onExpand", onCollapse: "onCollapse" }, []);
export { PanelExpandButtonComponentNgFactory as PanelExpandButtonComponentNgFactory };
