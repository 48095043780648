var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { LanguageResolver } from "../../../shared/resolvers/language.resolver";
import { LanguageService } from "../../../services/language/language.service";
import { Router } from "@angular/router";
import { CMSService } from "../../../services/CMS/cms.service";
import { PageService } from "../../../services/page/page.service";
import { Location } from "@angular/common";
import { tap } from "rxjs/operators";
var TopLevelDomainResolver = /** @class */ (function (_super) {
    __extends(TopLevelDomainResolver, _super);
    function TopLevelDomainResolver(languageService, router, location, cms, pageService) {
        var _this = _super.call(this, languageService, router, location) || this;
        _this.location = location;
        _this.cms = cms;
        _this.pageService = pageService;
        return _this;
    }
    TopLevelDomainResolver.prototype.resolve = function (route, state) {
        var _this = this;
        _super.prototype.resolve.call(this, route, state);
        return this.cms.isValidTLD(route.params["domain"])
            .pipe(tap(function (response) {
            if (!response.valid) {
                _this.router.navigate([_this.languageService.current, "pagenotfound"], { queryParams: { url: state.url } });
            }
        }));
    };
    return TopLevelDomainResolver;
}(LanguageResolver));
export { TopLevelDomainResolver };
