<div class="panel panel-primary">
    <div class="panel-header">
        <h2 class="panel-heading">{{header}}</h2>
    </div>
    <div class="table-container">
        <table class="table table-condensed search-domains-table">

            <tbody *ngIf="domains.length > 0">
                <tr *ngFor="let domain of domains" search-domains-table-row [domain]="domain"></tr>
            </tbody>

            <div *ngIf="domains.length === 0" class="loading-lg"></div>
            
            <tfoot>
            </tfoot>
        </table>
    </div>       
</div>