import { Component, Inject, OnInit, PLATFORM_ID, PlatformRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { isPlatformBrowser, Location } from '@angular/common'
import { LanguageService } from '../../services/language/language.service';

import { setTheme } from 'ngx-bootstrap/utils';

@Component({
    selector: 'app',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

    private lastVisitedUrl: string | undefined;
    private isBrowser: boolean;

    constructor(private router: Router, private location: Location, private languageService: LanguageService, @Inject(PLATFORM_ID) platformId: PlatformRef) {
        this.isBrowser = isPlatformBrowser(platformId);

        setTheme('bs3');
    }

    ngOnInit(): void {
        if (!this.isBrowser) {
            return;
        }

        this.router.events.subscribe((ev: any) => {
            if (ev instanceof NavigationEnd) {
                let path = this.stripLanguageFromUrl(ev.url);

                if (path.includes("customer-service?")) {
                    return; //hack so that it doesn't break the faq scrolling into view
                }

                if (path !== this.lastVisitedUrl) {
                    this.lastVisitedUrl = path;
                    window.scrollTo(0, 0);
                }
            }
        })
    }

    stripLanguageFromUrl(url: string | undefined): string {
        if (url) {
            let languageExp = this.languageService.languages.map(lang => lang.code).join("|");
            let urlStart = new RegExp("^/(" + languageExp + ")/?");
            return url.replace(urlStart, "/");
        }
        return "/";
    }
}
