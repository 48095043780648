import { Observable } from "rxjs";
import { TLDInfo } from "./tldInfo.data";
import { NewTLD } from "../../components/content/newTlds/newTLD.interface";
import { BasketItem } from "../basket/basketItem.interface";

export abstract class ApiService {
    abstract getTLDInfo(tld: string): Observable<TLDInfo | null>;

    abstract searchDomains(domain: string): Observable<any>;

    abstract getGroup(group?: string): Observable<any>;

    abstract getGroups(groups: string[]): Observable<any>;

    abstract pricelist(): Observable<any>

    abstract newsFeed(language: string): Observable<any>

    abstract tldGroups(): Observable<any>

    abstract getDomainPrice(tld: string): Observable<any>

    abstract whois(domain: string): Observable<string | null>;

    abstract getNewTLDs(): Observable<NewTLD[]>;

    abstract addToBasket(basketItem: BasketItem): Observable<any>;

    abstract removeFromBasket(itemid: string): Observable<any>;

    abstract getBasket(): Observable<any>;
}