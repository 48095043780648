/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../sideMenu/sideMenu.component.ngfactory";
import * as i2 from "../sideMenu/sideMenu.component";
import * as i3 from "../../services/dictionary.service";
import * as i4 from "../../services/language/language.service";
import * as i5 from "../breadcrumb/breadcrumb.component.ngfactory";
import * as i6 from "../breadcrumb/breadcrumb.component";
import * as i7 from "../../services/menu/menu.service";
import * as i8 from "@angular/common";
import * as i9 from "./contentContainer.component";
import * as i10 from "@angular/router";
var styles_ContentContainerComponent = [];
var RenderType_ContentContainerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ContentContainerComponent, data: {} });
export { RenderType_ContentContainerComponent as RenderType_ContentContainerComponent };
function View_ContentContainerComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "side-menu", [], null, null, null, i1.View_SideMenuComponent_0, i1.RenderType_SideMenuComponent)), i0.ɵdid(1, 49152, null, 0, i2.SideMenuComponent, [i3.DictionaryService, i4.LanguageService], { title: [0, "title"], links: [1, "links"], activeMenuItemId: [2, "activeMenuItemId"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; var currVal_1 = _co.links; var currVal_2 = _co.activeMenuItemId; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_ContentContainerComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(0, null, null, 0))], null, null); }
export function View_ContentContainerComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { container: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 11, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "breadcrumb", [], null, null, null, i5.View_BreadcrumbComponent_0, i5.RenderType_BreadcrumbComponent)), i0.ɵdid(3, 573440, null, 0, i6.BreadcrumbComponent, [i4.LanguageService, i7.MenuService, i3.DictionaryService], { contentId: [0, "contentId"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 8, "div", [], null, null, null, null, null)), i0.ɵdid(5, 278528, null, 0, i8.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), i0.ɵpod(6, { "panel": 0, "panel-default": 1, "has-sidebar": 2 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ContentContainerComponent_1)), i0.ɵdid(8, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(9, 0, null, null, 3, "div", [["class", "panel-body page-content"]], null, null, null, null, null)), i0.ɵdid(10, 278528, null, 0, i8.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(11, { "col-sm-8": 0 }), (_l()(), i0.ɵand(16777216, [[1, 3], ["container", 2]], null, 0, null, View_ContentContainerComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.content.id; _ck(_v, 3, 0, currVal_0); var currVal_1 = _ck(_v, 6, 0, _co.withPanel, _co.withPanel, _co.showSideMenu); _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.showSideMenu; _ck(_v, 8, 0, currVal_2); var currVal_3 = "panel-body page-content"; var currVal_4 = _ck(_v, 11, 0, _co.showSideMenu); _ck(_v, 10, 0, currVal_3, currVal_4); }, null); }
export function View_ContentContainerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "content-container", [], null, null, null, View_ContentContainerComponent_0, RenderType_ContentContainerComponent)), i0.ɵdid(1, 4767744, null, 0, i9.ContentContainerComponent, [i10.ActivatedRoute, i0.ComponentFactoryResolver, i7.MenuService, i4.LanguageService, i0.ChangeDetectorRef], null, null)], null, null); }
var ContentContainerComponentNgFactory = i0.ɵccf("content-container", i9.ContentContainerComponent, View_ContentContainerComponent_Host_0, {}, {}, []);
export { ContentContainerComponentNgFactory as ContentContainerComponentNgFactory };
