import { Component, EventEmitter } from "@angular/core";
import { BasketService } from "../../../../services/basket/basket.service";
import { DictionaryService } from "../../../../services/dictionary.service";
import { LanguageService } from "../../../../services/language/language.service";

@Component({
    selector: "shopping-cart-button",
    templateUrl: "./shoppingCartButton.component.html"
})
export class ShoppingCartButtonComponent {
    public dictionary: any;

    openPopover: EventEmitter<void>;

    constructor(public basketService: BasketService, dictionaryService: DictionaryService, public languageService: LanguageService) {
        dictionaryService.getDictionary().subscribe(d => this.dictionary = d);
        this.openPopover = basketService.addEvent
    }
}