import { Component } from "@angular/core";
import { TableSearchComponent } from "./tableSearch.component";

@Component({
    selector: "table-search-tld",
    templateUrl: "./tableSearch.component.html"
})
export class TableSearchTldComponent extends TableSearchComponent {

    filterFunction(search: string) {
        return (list: any[]) => {
            if (search === "") {
                return list;
            } else {
                return list
                    .filter(elem => {
                        return search.indexOf(".") === 0
                            ? ("." + elem.name).indexOf(search) >= 0
                            : elem.name.indexOf(search) >= 0
                    })
                    .sort((a, b) => {
                        let s = search.replace(/^\./, "");

                        if (a.name === b.name) return 0;

                        // Exact match
                        if (a.name === s) return -1;
                        if (b.name === s) return 1;

                        // Exact match in any part, starting from the right
                        let aParts = a.name.split(".").reverse();
                        let bParts = b.name.split(".").reverse();
                        let al = aParts.length, bl = bParts.length;
                        for (let i = 0; i < Math.max(al, bl); i++) {
                            let aExact = (i < al && aParts[i] === s);
                            let bExact = (i < bl && bParts[i] === s);
                            if (aExact !== bExact) return aExact ? -1 : 1;
                        }

                        // Sort by part name alphabetically
                        for (let i = 0; i < Math.max(al, bl); i++) {
                            let aa = i < al ? aParts[i] : "";
                            let bb = i < bl ? bParts[i] : "";
                            let compare = aa.localeCompare(bb, { sensitivity: 'base' });
                            if (compare !== 0) return compare;
                        }

                        return 0;
                    })
            }
        }
    }

}