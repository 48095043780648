<div class="carousel slide">

    <ol class="carousel-indicators" *ngIf="items.length > 1">
        <li *ngFor="let item of items; let i = index" (click)="select(i); stopTimer()" [ngClass]="{'active': i === activeSlideIndex}"></li>
    </ol>

    <div class="carousel-inner" role="listbox" (click)="stopTimer()">
        <ng-container *ngFor="let item of items; let i = index">
            <div id="slide-{{i}}" class="item slide-{{i}}" [ngClass]="{'active': item.active}">
                <div class="item-desktop">
                    <div class="carousel-caption">
                        <div class="box">
                            <div *ngIf="item.content" [innerHTML]="item.content | safeHtml"></div>
                            <div class="carousel-button">
                                <a *ngIf="item.buttonText" routerLink="{{'/' + languageService.current + '/' + item.buttonLink}}"
                                   class="btn alt-btn-link">{{item.buttonText}}</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="item-mobile">
                    <img class="carousel-image" src="{{item.mobileBackgroundImage}}">
                    <div class="carousel-caption">
                        <div *ngIf="item.mobileContent" [innerHTML]="item.mobileContent | safeHtml"></div>
                        <div class="carousel-button">
                            <a *ngIf="item.buttonText" routerLink="{{'/' + languageService.current + '/' + item.mobileButtonLink}}"
                               class="btn alt-btn-link">{{item.mobileButtonText}}</a>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>

    <a class="left carousel-control" role="button" (click)="prev(); stopTimer()" *ngIf="items.length > 1">
        <span class="fa fa-chevron-circle-left" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
    </a>
    <a class="right carousel-control" role="button" (click)="next(); stopTimer()"  *ngIf="items.length > 1">
        <span class="fa fa-chevron-circle-right" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
    </a>
</div>