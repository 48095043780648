/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../contentComponent/content.component.ngfactory";
import * as i2 from "../contentComponent/content.component";
import * as i3 from "./allTldsTable.component.ngfactory";
import * as i4 from "./allTldsTable.component";
import * as i5 from "../../../services/API/api.interface.service";
import * as i6 from "../../../services/currency.service";
import * as i7 from "../../../services/language/language.service";
import * as i8 from "../../../services/dictionary.service";
import * as i9 from "./allTlds.component";
var styles_AllTldsComponent = [];
var RenderType_AllTldsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AllTldsComponent, data: {} });
export { RenderType_AllTldsComponent as RenderType_AllTldsComponent };
export function View_AllTldsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "content-component", [], null, null, null, i1.View_ContentComponent_0, i1.RenderType_ContentComponent)), i0.ɵdid(1, 114688, null, 0, i2.ContentComponent, [], { pageTitle: [0, "pageTitle"], heading: [1, "heading"], subHeading: [2, "subHeading"], bodyText: [3, "bodyText"] }, null), (_l()(), i0.ɵeld(2, 0, null, 0, 1, "all-tlds-table", [], null, null, null, i3.View_AllTldsTableComponent_0, i3.RenderType_AllTldsTableComponent)), i0.ɵdid(3, 49152, null, 0, i4.AllTldsTableComponent, [i5.ApiService, i6.CurrencyService, i7.LanguageService, i8.DictionaryService], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.pageTitle; var currVal_1 = _co.heading; var currVal_2 = _co.subHeading; var currVal_3 = _co.bodyText; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_AllTldsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_AllTldsComponent_0, RenderType_AllTldsComponent)), i0.ɵdid(1, 114688, null, 0, i9.AllTldsComponent, [i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AllTldsComponentNgFactory = i0.ɵccf("ng-component", i9.AllTldsComponent, View_AllTldsComponent_Host_0, { pageTitle: "pageTitle", heading: "heading", subHeading: "subHeading", bodyText: "bodyText" }, {}, []);
export { AllTldsComponentNgFactory as AllTldsComponentNgFactory };
