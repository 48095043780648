import { Directive, ElementRef, EventEmitter, HostListener, Output } from "@angular/core";

@Directive({
    selector: "[clickOutside]"
})
export class ClickOutsideDirective {

    @Output("clickOutside") hideCallback: EventEmitter<void> = new EventEmitter();

    @HostListener("document:click", ["$event"])
    clickOutside(event: any) {
        if (!this.elemRef.nativeElement.contains(event.target)) {
            this.hideCallback.emit();
            event.stopPropagation();
        }
    }

    constructor(private elemRef: ElementRef) {
    }
}