/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../contentComponent/content.component.ngfactory";
import * as i2 from "../contentComponent/content.component";
import * as i3 from "./newTldsTable.component.ngfactory";
import * as i4 from "./newTldsTable.component";
import * as i5 from "../../../services/language/language.service";
import * as i6 from "../../../services/dictionary.service";
import * as i7 from "./newTlds.component";
import * as i8 from "../../../services/API/api.interface.service";
import * as i9 from "../../../services/CMS/cms.service";
var styles_NewTldsComponent = [];
var RenderType_NewTldsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NewTldsComponent, data: {} });
export { RenderType_NewTldsComponent as RenderType_NewTldsComponent };
export function View_NewTldsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "content-component", [], null, null, null, i1.View_ContentComponent_0, i1.RenderType_ContentComponent)), i0.ɵdid(1, 114688, null, 0, i2.ContentComponent, [], { pageTitle: [0, "pageTitle"], heading: [1, "heading"], subHeading: [2, "subHeading"], bodyText: [3, "bodyText"] }, null), (_l()(), i0.ɵeld(2, 0, null, 0, 1, "new-tlds-table", [], null, null, null, i3.View_NewTLDsTableComponent_0, i3.RenderType_NewTLDsTableComponent)), i0.ɵdid(3, 49152, null, 0, i4.NewTLDsTableComponent, [i5.LanguageService, i6.DictionaryService], { header: [0, "header"], list: [1, "list"], isLoading: [2, "isLoading"] }, null), (_l()(), i0.ɵeld(4, 0, null, 0, 1, "new-tlds-table", [], null, null, null, i3.View_NewTLDsTableComponent_0, i3.RenderType_NewTLDsTableComponent)), i0.ɵdid(5, 49152, null, 0, i4.NewTLDsTableComponent, [i5.LanguageService, i6.DictionaryService], { header: [0, "header"], list: [1, "list"], isLoading: [2, "isLoading"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.pageTitle; var currVal_1 = _co.heading; var currVal_2 = _co.subHeading; var currVal_3 = _co.bodyText; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = i0.ɵinlineInterpolate(1, "", ((_co.dictionary == null) ? null : _co.dictionary.NEW_TOP_LEVEL_DOMAINS_HEADER_UPCOMING), ""); var currVal_5 = _co.upcomingTLDs; var currVal_6 = _co.isLoading; _ck(_v, 3, 0, currVal_4, currVal_5, currVal_6); var currVal_7 = i0.ɵinlineInterpolate(1, "", ((_co.dictionary == null) ? null : _co.dictionary.NEW_TOP_LEVEL_DOMAINS_HEADER_NEW), ""); var currVal_8 = _co.newTLDs; var currVal_9 = _co.isLoading; _ck(_v, 5, 0, currVal_7, currVal_8, currVal_9); }, null); }
export function View_NewTldsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "new-tlds", [], null, null, null, View_NewTldsComponent_0, RenderType_NewTldsComponent)), i0.ɵdid(1, 49152, null, 0, i7.NewTldsComponent, [i0.ChangeDetectorRef, i8.ApiService, i6.DictionaryService, i9.CMSService], null, null)], null, null); }
var NewTldsComponentNgFactory = i0.ɵccf("new-tlds", i7.NewTldsComponent, View_NewTldsComponent_Host_0, {}, {}, []);
export { NewTldsComponentNgFactory as NewTldsComponentNgFactory };
