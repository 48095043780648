import { ChangeDetectorRef, Component } from "@angular/core";
import { BasePage } from "../../../shared/datatypes/basePage.data";
import { IContentComponent } from "../contentComponent/IContentComponent.interface";
import { ApiService } from "../../../services/API/api.interface.service";
import { DictionaryService } from "../../../services/dictionary.service";
import { NewTLD } from "./newTLD.interface";
import { CMSService } from "../../../services/CMS/cms.service";


@Component({
    selector: "new-tlds",
    templateUrl: "./newTlds.component.html"
})
export class NewTldsComponent extends BasePage implements IContentComponent {
    sideMenu: boolean;
    newTLDs: NewTLD[];
    upcomingTLDs: NewTLD[];
    isLoading = true;
    dictionary: any;

    constructor(private changeRef: ChangeDetectorRef, api: ApiService, dictionaryService: DictionaryService, cms: CMSService) {
        super();

        dictionaryService.getDictionary().subscribe(dictionary => this.dictionary = dictionary)

        this.newTLDs = Array(10).fill({})
        this.upcomingTLDs = Array(10).fill({})

        api.getNewTLDs()
            .subscribe(tlds => {
                tlds.map(domain => {
                    if (!domain.tld_utf8) {
                        domain.tld_utf8 = domain.tld;
                    }
                    return domain;
                })
                cms.getValidTLDList(tlds.map(tld => tld.tld))
                    .subscribe(validTlds => {
                        tlds.map(tld => tld.showLink = validTlds[tld.tld])
                    });
                let today = new Date();
                this.newTLDs = tlds.filter(tld => new Date(tld.date) <= today).sort((tldA, tldB) => tldA.date > tldB.date ? -1 : 1)
                this.upcomingTLDs = tlds.filter(tld => today < new Date(tld.date))
                this.isLoading = false;
            })
    }

    setData(data: any): void {
        this.pageTitle = data.pageTitle;
        this.bodyText = data.bodyText;
        this.subHeading = data.subHeading;
        this.sideMenu = data.sideMenu;
        this.changeRef.detectChanges();
    }
}