import { Component, Input } from "@angular/core";

@Component({
    selector: "link-panel-item",
    templateUrl: "./linkPanelItem.component.html"
})
export class LinkPanelItemComponent {
    @Input()
    public header: string;
    @Input()
    public footerText: string;
    @Input()
    public link: string;
    @Input()
    public body: string;
}