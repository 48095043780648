import { NgModule } from "@angular/core";
import { SearchDomainsComponent } from "./searchDomains.component";
import { SearchDomainsPromoComponent } from "./promo/searchDomainsPromo.component";
import { SearchDomainsTableComponent } from "./table/searchDomainsTable.component";
import { SearchDomainsTableRowComponent } from "./table/row/searchDomainsTableRow.component";
import { CommonModule } from "@angular/common";
import { SearchDomainsHeader } from "./header/searchDomainsHeader.component";
import { AddToCartButton } from "./addToCartButton/addToCartButton.component";
import { SharedModule } from "../../shared/shared.module";
import { TransferDomainModalComponent } from "./transferDomainModal/transferDomainModal.component";
import { FormsModule } from "@angular/forms";

import { TooltipModule } from 'ngx-bootstrap/tooltip';


@NgModule({
    declarations: [
        SearchDomainsComponent,
        SearchDomainsPromoComponent,
        SearchDomainsTableComponent,
        SearchDomainsTableRowComponent,
        SearchDomainsHeader,
        AddToCartButton,
        TransferDomainModalComponent
    ],
    entryComponents: [
        TransferDomainModalComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        TooltipModule
    ],
    exports: [
        SearchDomainsComponent
    ]
})
export class SearchDomainsModule {

}