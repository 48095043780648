/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./tableControls.component";
var styles_TableControlsComponent = [];
var RenderType_TableControlsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TableControlsComponent, data: {} });
export { RenderType_TableControlsComponent as RenderType_TableControlsComponent };
export function View_TableControlsComponent_0(_l) { return i0.ɵvid(0, [i0.ɵncd(null, 0)], null, null); }
export function View_TableControlsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "table-controls", [], null, null, null, View_TableControlsComponent_0, RenderType_TableControlsComponent)), i0.ɵdid(1, 49152, null, 0, i1.TableControlsComponent, [], null, null)], null, null); }
var TableControlsComponentNgFactory = i0.ɵccf("table-controls", i1.TableControlsComponent, View_TableControlsComponent_Host_0, {}, {}, ["*"]);
export { TableControlsComponentNgFactory as TableControlsComponentNgFactory };
