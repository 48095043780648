<div class="col-lg-8 col-md-12">
    <div class="data-grid-news table clickable-rows">
        <table cellspacing="0">
            <caption>
                <h2>{{dictionary?.News_Widget_Title}}</h2>
            </caption>
            <tbody>
                <ng-container *ngIf="!isLoading">
                    <tr *ngFor="let item of newsRows" news-panel-row [item]="item" [ngClass]="{ 'latest-news': item.isRecent }" (click)="onClick(item)"></tr>
                </ng-container>
                <tr *ngIf="isLoading">
                    <td colspan="4" class="container-loading"></td>
                </tr>
            </tbody>
        </table>
        <footer class="panel-footer">
            <a href="{{newsUrl}}" target="_blank" class="btn btn-link pull-right">
                {{dictionary?.News_Widget_News_Url_Text}}&nbsp;<i class="fa fa-external-link"></i>
            </a>
        </footer>
    </div>
</div>