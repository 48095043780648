/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./searchDomainsPromo.component";
import * as i3 from "../../../services/basket/basket.service";
import * as i4 from "../../../services/modal.service";
import * as i5 from "../../../services/currency.service";
import * as i6 from "../../../services/dictionary.service";
var styles_SearchDomainsPromoComponent = [];
var RenderType_SearchDomainsPromoComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SearchDomainsPromoComponent, data: {} });
export { RenderType_SearchDomainsPromoComponent as RenderType_SearchDomainsPromoComponent };
function View_SearchDomainsPromoComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "span", [["class", "price"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", " ", ""])), i0.ɵppd(2, 2), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent, 0), ((_co.loadedDomain == null) ? null : _co.loadedDomain.price), "1.2-2")); var currVal_1 = i0.ɵunv(_v, 1, 1, i0.ɵnov(_v, 3).transform(_co.currency.current)); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_SearchDomainsPromoComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i1.DecimalPipe, [i0.LOCALE_ID]), (_l()(), i0.ɵeld(1, 0, null, null, 10, "button", [["class", "search-result-promo hidden-xs"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClicked() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 6, "div", [["class", "search-result-promo-controls"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "span", [["class", "sld"]], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", ""])), (_l()(), i0.ɵeld(6, 0, null, null, 1, "span", [["class", "tld"]], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SearchDomainsPromoComponent_1)), i0.ɵdid(9, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(10, 0, null, null, 1, "span", [["class", "domain-info"]], null, null, null, null, null)), (_l()(), i0.ɵted(11, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "search-result-promo hidden-xs"; var currVal_1 = _co.getPromoClass(_co.loadedDomain); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_4 = _co.loadedDomain; _ck(_v, 9, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = ((_co.domain == null) ? null : _co.domain.name); _ck(_v, 5, 0, currVal_2); var currVal_3 = ((_co.domain == null) ? null : _co.domain.tld); _ck(_v, 7, 0, currVal_3); var currVal_5 = _co.getAddedToCartText(_co.loadedDomain); _ck(_v, 11, 0, currVal_5); }); }
export function View_SearchDomainsPromoComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "search-domains-promo", [], null, null, null, View_SearchDomainsPromoComponent_0, RenderType_SearchDomainsPromoComponent)), i0.ɵdid(1, 114688, null, 0, i2.SearchDomainsPromoComponent, [i3.BasketService, i4.ModalService, i5.CurrencyService, i6.DictionaryService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SearchDomainsPromoComponentNgFactory = i0.ɵccf("search-domains-promo", i2.SearchDomainsPromoComponent, View_SearchDomainsPromoComponent_Host_0, { domain: "domain" }, {}, []);
export { SearchDomainsPromoComponentNgFactory as SearchDomainsPromoComponentNgFactory };
