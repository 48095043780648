var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef } from "@angular/core";
import { BasePage } from "../../../shared/datatypes/basePage.data";
import { ApiService } from "../../../services/API/api.interface.service";
import { DictionaryService } from "../../../services/dictionary.service";
import { CMSService } from "../../../services/CMS/cms.service";
var NewTldsComponent = /** @class */ (function (_super) {
    __extends(NewTldsComponent, _super);
    function NewTldsComponent(changeRef, api, dictionaryService, cms) {
        var _this = _super.call(this) || this;
        _this.changeRef = changeRef;
        _this.isLoading = true;
        dictionaryService.getDictionary().subscribe(function (dictionary) { return _this.dictionary = dictionary; });
        _this.newTLDs = Array(10).fill({});
        _this.upcomingTLDs = Array(10).fill({});
        api.getNewTLDs()
            .subscribe(function (tlds) {
            tlds.map(function (domain) {
                if (!domain.tld_utf8) {
                    domain.tld_utf8 = domain.tld;
                }
                return domain;
            });
            cms.getValidTLDList(tlds.map(function (tld) { return tld.tld; }))
                .subscribe(function (validTlds) {
                tlds.map(function (tld) { return tld.showLink = validTlds[tld.tld]; });
            });
            var today = new Date();
            _this.newTLDs = tlds.filter(function (tld) { return new Date(tld.date) <= today; }).sort(function (tldA, tldB) { return tldA.date > tldB.date ? -1 : 1; });
            _this.upcomingTLDs = tlds.filter(function (tld) { return today < new Date(tld.date); });
            _this.isLoading = false;
        });
        return _this;
    }
    NewTldsComponent.prototype.setData = function (data) {
        this.pageTitle = data.pageTitle;
        this.bodyText = data.bodyText;
        this.subHeading = data.subHeading;
        this.sideMenu = data.sideMenu;
        this.changeRef.detectChanges();
    };
    return NewTldsComponent;
}(BasePage));
export { NewTldsComponent };
