<div class="breadcrumbs container">
    <span>
        <a routerLink="/{{languageService.current}}">
            <i class="glyph-home"></i>&nbsp;<span>Home</span>
        </a>
    </span>
    <span>
        <ng-container *ngFor="let link of links; let i = index">
            &raquo;
            <a routerLink="/{{languageService.current}}/{{link.link}}" [ngClass]="{'disabled': i === links.length - 1}">
                <span>{{ (dictionary && dictionary.hasOwnProperty(link.name)) ? dictionary[link.name] : link.name}}</span>
            </a>
        </ng-container>
    </span>
</div>