/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../modal/modal.component.ngfactory";
import * as i2 from "../modal/modal.component";
import * as i3 from "ngx-bootstrap/modal/bs-modal-ref.service";
import * as i4 from "@angular/common";
import * as i5 from "./whoisModal.component";
import * as i6 from "../../services/API/api.interface.service";
import * as i7 from "../../services/dictionary.service";
var styles_WhoisModalComponent = [];
var RenderType_WhoisModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_WhoisModalComponent, data: {} });
export { RenderType_WhoisModalComponent as RenderType_WhoisModalComponent };
function View_WhoisModalComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "article", [["class", "layout-compact"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "p", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.content; _ck(_v, 1, 0, currVal_0); }); }
function View_WhoisModalComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "loading-md loading-error"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "div", [["class", "loadable-error"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 4, "div", [["class", "notice"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 3, "div", [["class", "notice-info"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, "i", [["class", "glyph-error notice-icon text-danger"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "h4", [["class", "notice-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.dictionary == null) ? null : _co.dictionary.Error_Text); _ck(_v, 6, 0, currVal_0); }); }
export function View_WhoisModalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "modal", [], null, [[null, "closeEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closeEvent" === en)) {
        var pd_0 = (_co.onClose() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ModalComponent_0, i1.RenderType_ModalComponent)), i0.ɵdid(1, 49152, null, 0, i2.ModalComponent, [i3.BsModalRef], { title: [0, "title"], modalId: [1, "modalId"] }, { closeEvent: "closeEvent" }), (_l()(), i0.ɵeld(2, 0, null, 0, 6, "div", [["body", ""]], null, null, null, null, null)), i0.ɵdid(3, 278528, null, 0, i4.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), i0.ɵpod(4, { "container-loading": 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_WhoisModalComponent_1)), i0.ɵdid(6, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_WhoisModalComponent_2)), i0.ɵdid(8, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(9, 0, null, 1, 1, "button", [["class", "btn btn-default"], ["footer", ""], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(10, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; var currVal_1 = _co.modalId; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _ck(_v, 4, 0, !_co.loaded); _ck(_v, 3, 0, currVal_2); var currVal_3 = (_co.loaded && _co.content); _ck(_v, 6, 0, currVal_3); var currVal_4 = (_co.loaded && !_co.content); _ck(_v, 8, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_5 = ((_co.dictionary == null) ? null : _co.dictionary.Modal_Close_Button); _ck(_v, 10, 0, currVal_5); }); }
export function View_WhoisModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "whois-modal", [], null, null, null, View_WhoisModalComponent_0, RenderType_WhoisModalComponent)), i0.ɵdid(1, 114688, null, 0, i5.WhoisModalComponent, [i3.BsModalRef, i6.ApiService, i7.DictionaryService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WhoisModalComponentNgFactory = i0.ɵccf("whois-modal", i5.WhoisModalComponent, View_WhoisModalComponent_Host_0, { title: "title", modalId: "modalId" }, { closeEvent: "closeEvent" }, []);
export { WhoisModalComponentNgFactory as WhoisModalComponentNgFactory };
