import { ApiService } from "../../../services/API/api.interface.service";
import { CurrencyService } from "../../../services/currency.service";
import { LanguageService } from "../../../services/language/language.service";
import { DictionaryService } from "../../../services/dictionary.service";
import { zip } from "rxjs";
import { combineLatest, map } from "rxjs/operators";
import { PriceListTld } from "./PriceListTLD";
var AllTldsTableComponent = /** @class */ (function () {
    function AllTldsTableComponent(api, currencyService, languageService, dictionaryService) {
        var _this = this;
        this.languageService = languageService;
        this.tlds = [];
        this.filteredTlds = [];
        this.paginationFilter = function (xs) { return xs; };
        this.searchFilter = function (xs) { return xs; };
        this.paginationSettings = { total: 0, page: 1, pageSize: 25 };
        this.currency = "SEK";
        this.tldGroups = [];
        this.groupFilter = function (xs) { return xs; };
        this.isLoading = true;
        this.showPagination = true;
        this.noResults = false;
        this.exchangerates = { USD: 1, EUR: 1 };
        dictionaryService.getDictionary().subscribe(function (d) { return _this.dictionary = d; });
        zip(dictionaryService.getDictionary(), api.tldGroups())
            .subscribe(function (_a) {
            var dictionary = _a[0], result = _a[1];
            _this.tldGroups = result.tldgroups.map(function (groupName) {
                return { display: _this.dictionary["Topdomain_Group_" + groupName] || groupName, value: groupName };
            });
            _this.tldGroups = [{ display: _this.dictionary["Topdomain_Group_ALL"], value: "" }].concat(_this.tldGroups);
        });
        api.pricelist()
            .pipe(combineLatest(currencyService.current), map(function (_a) {
            var response = _a[0], currency = _a[1];
            if (response.tlds) {
                _this.currency = currency;
                var exchangerate_1 = _this.getExchangeRate(currency, response.exchangerates);
                return response.tlds.map(function (tld) {
                    return {
                        name: tld.tld,
                        registration: tld.actions.Registration.price / exchangerate_1,
                        renew: tld.actions.Renew.price / exchangerate_1,
                        transfer: tld.actions.Transfer.price / exchangerate_1,
                        groups: tld.groups,
                        toString: function () {
                            return this.name;
                        }
                    };
                });
            }
        }))
            .subscribe(function (tlds) {
            if (tlds) {
                tlds.sort();
                _this.tlds = tlds;
                _this.filterList();
                _this.isLoading = false;
            }
        });
    }
    AllTldsTableComponent.prototype.getExchangeRate = function (currency, rates) {
        switch (currency) {
            case "SEK":
                return 1;
            case "USD":
                return Number.parseFloat(rates.USD);
            case "EUR":
                return Number.parseFloat(rates.EUR);
            default:
                return 1;
        }
    };
    AllTldsTableComponent.prototype.filterList = function () {
        var list = this.searchFilter(this.tlds);
        list = this.groupFilter(list);
        this.paginationSettings.total = list.length;
        list = this.paginationFilter(list);
        this.noResults = this.paginationSettings.total === 0;
        if (this.paginationSettings.total <= this.paginationSettings.pageSize) {
            this.filteredTlds = list;
            this.showPagination = false;
        }
        else {
            this.filteredTlds = this.padWithEmptyRows(list);
            this.showPagination = true;
        }
    };
    AllTldsTableComponent.prototype.onSearch = function (filter) {
        this.searchFilter = filter;
        this.paginationSettings.page = 1;
        this.filterList();
    };
    AllTldsTableComponent.prototype.onPaginate = function (filter) {
        this.paginationFilter = filter;
        this.filterList();
    };
    AllTldsTableComponent.prototype.onGroupFilter = function (value) {
        this.groupFilter = function (list) {
            return value === ""
                ? list
                : list.filter(function (tld) { return tld.groups && tld.groups.some(function (group) { return group === value; }); });
        };
        this.paginationSettings.page = 1;
        this.filterList();
    };
    AllTldsTableComponent.prototype.padWithEmptyRows = function (list) {
        var missingRows = this.paginationSettings.pageSize - list.length;
        while (missingRows > 0) {
            list.push(new PriceListTld());
            missingRows--;
        }
        return list;
    };
    return AllTldsTableComponent;
}());
export { AllTldsTableComponent };
