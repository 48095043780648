<div class="site-header-primary">
    <div class="container">
        <!-- <div class="row"> -->

            <div class="site-header-row ">
                <nav class="site-menu" id="site-menu">
                    <div class="btn-group nav-button" (clickOutside)="CloseMegaMenu()">
                        <button type="button" class="btn btn-link" [ngClass]="{'active': showMegaMenu}" (click)="ToggleMegaMenu()">
                            <i class="nav-icon fa fa-bars"></i>
                            <span class="sr-only">{{dictionary?.Site_Header_Primary_Navigation_Sr}}</span>
                        </button>
                        <mega-menu [hidden]="!showMegaMenu" (toggle)="CloseMegaMenu()"></mega-menu>
                    </div>
                </nav>
                <header-search class="header-search-field"></header-search>
                <shopping-cart-button class="btn-group nav-button"></shopping-cart-button> 
            </div> 

        <!-- </div> -->
    </div>