var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef } from "@angular/core";
import { BasePage } from "../../../shared/datatypes/basePage.data";
import { DictionaryService } from "../../../services/dictionary.service";
var WhoisLookupComponent = /** @class */ (function (_super) {
    __extends(WhoisLookupComponent, _super);
    function WhoisLookupComponent(changeRef, dictionaryService) {
        var _this = _super.call(this) || this;
        _this.changeRef = changeRef;
        dictionaryService.getDictionary().subscribe(function (dictionary) { return _this.dictionary = dictionary; });
        return _this;
    }
    WhoisLookupComponent.prototype.setData = function (data) {
        this.pageTitle = data.pageTitle;
        this.bodyText = data.bodyText;
        this.subHeading = data.subHeading;
        this.sideMenu = data.sideMenu;
        this.changeRef.detectChanges();
    };
    return WhoisLookupComponent;
}(BasePage));
export { WhoisLookupComponent };
