<modal [modalId]="modalId" [title]="title" (closeEvent)="onClose()">
    <div body [ngClass]="{'container-loading': !loaded}">
        <article class="layout-compact" *ngIf="loaded && content">
            <p [innerHTML]="content"></p>
        </article>
        <div class="loading-md loading-error" *ngIf="loaded && !content">
            <div class="loadable-error">
                <div class="notice">
                    <div class="notice-info">
                        <i class="glyph-error notice-icon text-danger"></i>
                        <h4 class="notice-title">{{dictionary?.Error_Text}}</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <button footer type="button" class="btn btn-default" (click)="close()">{{dictionary?.Modal_Close_Button}}</button>
</modal>