import { LanguageService } from "../../../../services/language/language.service";
import { Localization } from "../../../../services/language/localization.model";
import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { CurrencyService } from "../../../../services/currency.service";
import { DictionaryService } from "../../../../services/dictionary.service";

@Component({
    selector: "localization-menu",
    templateUrl: "./localizationMenu.component.html"
})
export class LocalizationMenu {

    public clickOutside() {
        this.showLocalizationMenu = false;
    }

    public showLocalizationMenu: boolean = false;

    public languages: LocalizationCheckable[];

    public currencies: LocalizationCheckable[] = [
        { name: 'Svenska kronor', checked: false, code: 'SEK' },
        { name: 'Euro', checked: false, code: 'EUR' },
        { name: 'American Dollar', checked: false, code: 'USD' }
    ];

    public selectedLanguage: any;
    public selectedCurrency: any;
    public dictionary: any;

    constructor(public languageService: LanguageService, private router: Router, private currencyService: CurrencyService, dictionaryService: DictionaryService) {

        dictionaryService.getDictionary().subscribe(dictionary => this.dictionary = dictionary);
        this.languages = languageService.languages.map(lang => {
            return { ...lang, checked: false }
        });

        languageService.getLanguage().subscribe(lang => {
            this.selectedLanguage = this.languages.find(x => x.code === lang);
            if (!this.selectedLanguage) {
                this.selectedLanguage = this.languages[0];
            }

            this.languages.forEach(x => {
                x.checked = x.code === this.selectedLanguage.code;
            })
        })

        currencyService.current.subscribe(currency => {
            this.selectedCurrency = this.currencies.find(x => x.code === currency);
            this.selectedCurrency.checked = true;
        })
    }

    public ToggleLocalizationMenu() {
        this.showLocalizationMenu = !this.showLocalizationMenu;
    }

    ToogleChoosenLanguage(language: LocalizationCheckable) {
        if (language.code === this.selectedLanguage.code) return;

        this.selectedLanguage.checked = false;

        let newUrl = this.router.url.replace(this.selectedLanguage.code, language.code);
        this.router.navigateByUrl(newUrl);
    }

    ToggleChosenCurrency(currency: any) {
        if (this.selectedCurrency) {
            this.selectedCurrency.checked = false;
        }

        currency.checked = true;
        this.selectedCurrency = currency;

        this.currencyService.set(currency.code)
    }
}

class LocalizationCheckable extends Localization {
    public checked: boolean;
}