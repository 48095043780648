/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../contentComponent/content.component.ngfactory";
import * as i2 from "../contentComponent/content.component";
import * as i3 from "./textContent.component";
import * as i4 from "@angular/platform-browser";
var styles_TextContentComponent = [];
var RenderType_TextContentComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TextContentComponent, data: {} });
export { RenderType_TextContentComponent as RenderType_TextContentComponent };
export function View_TextContentComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "content-component", [], null, null, null, i1.View_ContentComponent_0, i1.RenderType_ContentComponent)), i0.ɵdid(1, 114688, null, 0, i2.ContentComponent, [], { pageTitle: [0, "pageTitle"], heading: [1, "heading"], subHeading: [2, "subHeading"], bodyText: [3, "bodyText"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.pageTitle; var currVal_1 = _co.heading; var currVal_2 = _co.subHeading; var currVal_3 = _co.bodyText; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_TextContentComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_TextContentComponent_0, RenderType_TextContentComponent)), i0.ɵdid(1, 49152, null, 0, i3.TextContentComponent, [i0.ChangeDetectorRef, i4.DomSanitizer], null, null)], null, null); }
var TextContentComponentNgFactory = i0.ɵccf("ng-component", i3.TextContentComponent, View_TextContentComponent_Host_0, {}, {}, []);
export { TextContentComponentNgFactory as TextContentComponentNgFactory };
