var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Router } from "@angular/router";
import { TextContentComponent } from "./textContent/textContent.component";
import { Type } from "@angular/core";
import { LanguageResolver } from "../../shared/resolvers/language.resolver";
import { LanguageService } from "../../services/language/language.service";
import { ContentCategoryComponent } from "./contentCategory/contentCategory.component";
import { AllTldsComponent } from "./allTlds/allTlds.component";
import { NewTldsComponent } from "./newTlds/newTlds.component";
import { PageService } from "../../services/page/page.service";
import { CustomerServiceComponent } from "../content/customerService/customerService.component";
import { WhoisLookupComponent } from "./whoisLookup/whoisLookup.component";
import { PunycodeConverterComponent } from "./punycodeConverter/punycodeConverter.component";
import { Location } from "@angular/common";
import { first, map } from "rxjs/operators";
var ContentResolver = /** @class */ (function (_super) {
    __extends(ContentResolver, _super);
    function ContentResolver(pageService, router, location, languageService) {
        var _this = _super.call(this, languageService, router, location) || this;
        _this.pageService = pageService;
        _this.router = router;
        _this.location = location;
        _this.languageService = languageService;
        _this.contentTypeToComponentMap = {
            "panelItemsPage": ContentCategoryComponent,
            "contentPage": TextContentComponent,
            "allTopLevelDomains": AllTldsComponent,
            "newTopLevelDomains": NewTldsComponent,
            "customerService": CustomerServiceComponent,
            "punycodeConverter": PunycodeConverterComponent,
            "whoisLookup": WhoisLookupComponent
        };
        return _this;
    }
    ContentResolver.prototype.resolve = function (route, state) {
        var _this = this;
        _super.prototype.resolve.call(this, route, state);
        var firstParam = route.paramMap.get("first");
        var secondParam = route.paramMap.get("second");
        return this.pageService.getContent(firstParam, secondParam)
            .pipe(map(function (page) {
            var component = _this.contentTypeToComponentMap[page.documentTypeAlias];
            if (!component) {
                _this.router.navigate([_this.languageService.current, "pagenotfound"]);
                return { type: TextContentComponent, data: {}, id: 0 };
            }
            return { type: component, data: page.customProperties, id: page.id };
        }), first());
    };
    return ContentResolver;
}(LanguageResolver));
export { ContentResolver };
