<panel-item class="description-list-panel">
    <div header>
        <h2 class="panel-heading">{{title}}</h2> <span *ngIf="flagClass" class="flag tld-flag {{flagClass}}"></span>
    </div>

    <dl body [ngClass]="{'container-loading': isLoading}">
        <ng-template ngFor let-row [ngForOf]="rows">
            <dt>{{row.label}}</dt>
            <dd>{{row.data}}</dd>    
        </ng-template>
    </dl>
</panel-item>