import { Component, Input } from "@angular/core";
import { Domain, getStateFunction } from "../domain.interface";
import { DictionaryService } from "../../../services/dictionary.service";
import { BasketService } from "../../../services/basket/basket.service";
import { domainStates } from "../domainStates.constant";
import { ModalService } from "../../../services/modal.service";

@Component({
    selector: "add-to-cart-button",
    templateUrl: "./addToCartButton.component.html"
})
export class AddToCartButton {
    @Input()
    domain?: Domain;
    dictionary: any;

    getButtonClass: (domain?: Domain) => string;
    getGlyph: (domain?: Domain) => string;
    addToCart: (domain?: Domain) => Function;
    private getTextKeys: (domain?: Domain) => string;

    constructor(dictionaryService: DictionaryService, private basketService: BasketService, private modalService: ModalService) {
        dictionaryService.getDictionary().subscribe(dic => this.dictionary = dic);
        this.getButtonClass = getStateFunction(domainStates.addToCartButtonClass);
        this.getGlyph = getStateFunction(domainStates.addToCartButtonIcon);
        this.addToCart = getStateFunction(domainStates.addToCartOnClick(basketService, modalService));
        this.getTextKeys = getStateFunction(domainStates.addToCartButtonTextKey);
    }

    onClicked() {
        if (!this.domain) return;
        this.addToCart(this.domain)(this.domain, this.domain.priceSEK);
        this.domain.inCart = !this.domain.inCart
    }

    getText() {
        if (!this.dictionary) return;
        return this.dictionary[this.getTextKeys(this.domain)]
    }
}