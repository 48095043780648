import { NgModule } from "@angular/core";
import { TableComponent } from "./baseTable/table.component";
import { PaginationComponent } from "./pagination/pagination.component";
import { TableControlsComponent } from "./tableControls/tableControls.component";
import { FormsModule } from "@angular/forms";
import { TableSearchComponent } from "./tableSearch/tableSearch.component";
import { CommonModule } from "@angular/common";
import { TableSearchTldComponent } from "./tableSearch/tableSearchTld.component";

@NgModule({
    declarations: [
        TableComponent,
        PaginationComponent,
        TableControlsComponent,
        TableSearchComponent,
        TableSearchTldComponent
    ],
    imports: [
        CommonModule,
        FormsModule
    ],
    exports: [
        TableComponent,
        TableControlsComponent,
        TableSearchComponent,
        PaginationComponent,
        TableSearchTldComponent
    ]
})
export class TableModule {
}