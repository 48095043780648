import { Component, Input } from "@angular/core";
import { DescriptionListRow } from "../descriptionList.data";


@Component({
    selector: "info-panel",
    templateUrl: "./infoPanel.component.html"
})
export class InfoPanelComponent {
    @Input()
    public rows: DescriptionListRow[];
    @Input()
    public title: string;
    @Input()
    public flagClass: string;
    @Input()
    public isLoading: boolean;
}