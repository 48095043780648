import { Component } from "@angular/core";
import { DictionaryService } from "../../../services/dictionary.service";
import { ApiService } from "../../../services/API/api.interface.service";
import { ModalService } from "../../../services/modal.service";

@Component({
    selector: "who-is-panel",
    templateUrl: "./whoIsPanel.component.html"
})
export class WhoIsPanel {

    public dictionary: any;

    constructor(dictionaryService: DictionaryService, private api: ApiService, private modalService: ModalService) {
        dictionaryService.getDictionary().subscribe(dictionary => {
            this.dictionary = dictionary;
        })
    }

}