import { Component, EventEmitter, Output } from "@angular/core";
import { DictionaryService } from "../../../services/dictionary.service";

@Component({
    selector: "panel-expand-button",
    templateUrl: "./panelExpandButton.component.html"
})
export class PanelExpandButtonComponent {
    @Output() onExpand = new EventEmitter<void>()
    @Output() onCollapse = new EventEmitter<void>()
    public expanded = false;
    public dictionary: any;

    constructor(dictionaryService: DictionaryService) {
        dictionaryService.getDictionary().subscribe(d => this.dictionary = d);
    }

    expand() {
        this.expanded = true;
        this.onExpand.emit();
    }

    collapse() {
        this.expanded = false;
        this.onCollapse.emit();
    }
}