import { Component, EventEmitter, Output } from "@angular/core";
import { CMSService } from "../../../services/CMS/cms.service";
import { LanguageService } from "../../../services/language/language.service";
import { MenuService } from "../../../services/menu/menu.service";
import { MenuItem } from "../../../services/menu/menuItem.data";
import { UrlService } from "../../../services/url.service";


@Component({
    selector: "mega-menu",
    templateUrl: "./megaMenu.component.html"
})
export class MegaMenuComponent {

    @Output() toggle = new EventEmitter();

    public menuItems: MenuItem[];

    constructor(cms: CMSService, public languageService: LanguageService, menu: MenuService, public url: UrlService) {
        menu.getMainMenu().subscribe(menu => this.menuItems = menu)
    }

    OnClickLink(event: Event) {
        this.toggle.emit();
        event.stopPropagation();
    }
}