import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppBrowserModule } from './app/app.browser.module';

import "./polyfills"
import "./app/assets/images/favicon.png"
import "../node_modules/bootstrap/js/dropdown.js"

const config = (window as any)["config"];

if (config.ENVIRONMENT !== "development") {
  enableProdMode();
}

document.addEventListener("DOMContentLoaded", () => {
    platformBrowserDynamic()
        .bootstrapModule(AppBrowserModule)
        .catch(err => console.log(err));
});
