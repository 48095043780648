var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef } from "@angular/core";
import { BasePage } from "../../../shared/datatypes/basePage.data";
import { DomSanitizer } from "@angular/platform-browser";
var TextContentComponent = /** @class */ (function (_super) {
    __extends(TextContentComponent, _super);
    function TextContentComponent(changeRef, sanitizer) {
        var _this = _super.call(this) || this;
        _this.changeRef = changeRef;
        _this.sanitizer = sanitizer;
        return _this;
    }
    TextContentComponent.prototype.setData = function (data) {
        this.pageTitle = data.pageTitle;
        this.bodyText = this.sanitizer.bypassSecurityTrustHtml(data.bodyText);
        this.heading = data.heading;
        this.subHeading = data.subHeading;
        this.sideMenu = data.sideMenu;
        this.changeRef.detectChanges();
    };
    return TextContentComponent;
}(BasePage));
export { TextContentComponent };
