<div class="data-text-box reseller-information collapsible">
    <h2 [attr.aria-expanded]="!isCollapsed" aria-controls="resellerinfo" (click)="isCollapsed = !isCollapsed"><span>{{header | lowercase}}</span><i class="fa" [ngClass]="{'fa-angle-up': !isCollapsed, 'fa-angle-down': isCollapsed}"></i></h2>
    <div id="resellerinfo" [collapse]="isCollapsed">
        <ng-container *ngFor="let section of sections">
            <h3 *ngIf="section.header">{{section.header}}</h3>
            <ul>
                <li *ngFor="let row of section.rows">
                    <dt>{{row.label}}</dt>
                    <dd>{{row.data}}</dd>
                </li>
            </ul>
        </ng-container>
    </div>
</div>