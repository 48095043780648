/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../popover/popover.component.ngfactory";
import * as i2 from "../popover/popover.component";
import * as i3 from "../../services/dictionary.service";
import * as i4 from "./whoisPopover.component";
var styles_WhoisPopoverComponent = [];
var RenderType_WhoisPopoverComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_WhoisPopoverComponent, data: {} });
export { RenderType_WhoisPopoverComponent as RenderType_WhoisPopoverComponent };
export function View_WhoisPopoverComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "popover", [], null, null, null, i1.View_PopoverComponent_0, i1.RenderType_PopoverComponent)), i0.ɵdid(1, 573440, null, 0, i2.PopoverComponent, [i3.DictionaryService], { openEvent: [0, "openEvent"] }, null), (_l()(), i0.ɵeld(2, 0, null, 0, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 3, "h3", [], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, "i", [["class", "glyph-error text-danger"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), (_l()(), i0.ɵeld(7, 0, null, 1, 1, "div", [["body", ""]], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.openEvent; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.dictionary == null) ? null : _co.dictionary.Whois_Popover_Title); _ck(_v, 6, 0, currVal_1); var currVal_2 = ((_co.dictionary == null) ? null : _co.dictionary.Whois_Popover_Description); _ck(_v, 8, 0, currVal_2); }); }
export function View_WhoisPopoverComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "whois-popover", [], null, null, null, View_WhoisPopoverComponent_0, RenderType_WhoisPopoverComponent)), i0.ɵdid(1, 573440, null, 0, i4.WhoisPopoverComponent, [i3.DictionaryService], null, null)], null, null); }
var WhoisPopoverComponentNgFactory = i0.ɵccf("whois-popover", i4.WhoisPopoverComponent, View_WhoisPopoverComponent_Host_0, { openEvent: "open", show: "show", active: "active" }, {}, []);
export { WhoisPopoverComponentNgFactory as WhoisPopoverComponentNgFactory };
