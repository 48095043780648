import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";

@Component({
    selector: "select-component",
    templateUrl: "./select.component.html"
})
export class SelectComponent {

    @Input()
    public label: string;
    @Input()
    public options: Option[]
    @Input()
    public selected: string;
    @Output()
    public onSelect = new EventEmitter<any>()

    ngOnInit() {
        if(!this.selected && this.options[0]){
            this.selected = this.options[0].value;
        }
    }

    onSelected() {
        this.onSelect.emit(this.selected);
    }
}

export interface Option {
    display: string;
    value: any;
}