import { Injectable } from "@angular/core";
import { LanguageService } from "./language/language.service";

@Injectable()
export class UrlService {
    constructor(private languageService: LanguageService) {
    }

    public buildUrl(...params: (string | null)[]): string {
        let nonNull = params.filter(elem => elem !== null) as string[];
        return nonNull.reduce((acc, curr) => curr ? acc + curr + "/" : acc, "/")
    }

    public addLanguage(url: string): string {
        return `/${this.languageService.current}/${url}`;
    }
}