/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../header/siteHeader.component.ngfactory";
import * as i2 from "../header/siteHeader.component";
import * as i3 from "@angular/router";
import * as i4 from "../bugReport/bugReport.component.ngfactory";
import * as i5 from "../bugReport/bugReport.component";
import * as i6 from "../../services/dictionary.service";
import * as i7 from "@angular/common";
import * as i8 from "../footer/footer.component.ngfactory";
import * as i9 from "../footer/footer.component";
import * as i10 from "../../services/language/language.service";
import * as i11 from "@angular/platform-browser";
import * as i12 from "../../services/page/page.service";
import * as i13 from "./app.component";
var styles_AppComponent = [];
var RenderType_AppComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "site-header", [], null, null, null, i1.View_SiteHeaderComponent_0, i1.RenderType_SiteHeaderComponent)), i0.ɵdid(1, 49152, null, 0, i2.SiteHeaderComponent, [], null, null), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["id", "content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(4, 212992, null, 0, i3.RouterOutlet, [i3.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null), (_l()(), i0.ɵeld(5, 0, null, null, 1, "bug-report", [], null, null, null, i4.View_BugReportComponent_0, i4.RenderType_BugReportComponent)), i0.ɵdid(6, 49152, null, 0, i5.BugReportComponent, [i6.DictionaryService, i0.PLATFORM_ID, i7.DOCUMENT, "ENVIRONMENT"], null, null), (_l()(), i0.ɵeld(7, 0, null, null, 1, "site-footer", [], null, null, null, i8.View_FooterComponent_0, i8.RenderType_FooterComponent)), i0.ɵdid(8, 49152, null, 0, i9.FooterComponent, [i10.LanguageService, i11.DomSanitizer, i6.DictionaryService, i12.PageService, "CP_URL"], null, null)], function (_ck, _v) { _ck(_v, 4, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i0.ɵdid(1, 114688, null, 0, i13.AppComponent, [i3.Router, i7.Location, i10.LanguageService, i0.PLATFORM_ID], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i0.ɵccf("app", i13.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
