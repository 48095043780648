import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { BasketItem } from "../../../services/basket/basketItem.interface";
import { DictionaryService } from "../../../services/dictionary.service";
import { DomainItemAction } from "../../../services/API/enums/api.domainitemaction.enum";

@Component({
    selector: "[checkout-table-row]",
    templateUrl: "./checkoutTableRow.component.html"
})
export class CheckoutTableRowComponent implements OnInit {

    @Input()
    item: BasketItem

    @Output()
    itemDeleteEvent = new EventEmitter<string>()

    labelName: string
    labelGlyph: string
    labelClass: string

    dictionary: any

    constructor(dictionaryService: DictionaryService) {
        dictionaryService.getDictionary().subscribe(d => this.dictionary = d)
    }

    ngOnInit(): void {
        switch (this.item.itemaction) {
            case DomainItemAction.Register:
                this.labelName = "Checkout_New_Domain_Title"
                this.labelGlyph = "glyph-domain"
                this.labelClass = "label-new-domain"
                break;
            case DomainItemAction.Transfer:
                this.labelName = "Checkout_Transfer_Domain_Title"
                this.labelGlyph = "glyph-transfer"
                this.labelClass = "label-transfer"
                break;
        }
    }

    onDelete() {
        this.itemDeleteEvent.emit(this.item.item)
    }
}