import { Inject, Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { forkJoin, Observable, Subject, BehaviorSubject } from "rxjs";
import { TLDInfo, TLDInfoResponse } from "./tldInfo.data";
import { ApiService } from "./api.interface.service";
import { WhoisResponse } from "./responses/whoisResponse.interface";
import { NewTLD } from "../../components/content/newTlds/newTLD.interface";
import { first, map, share } from "rxjs/operators";
import { BasketItem } from "../basket/basketItem.interface";

@Injectable()
export class ApiBrowserService implements ApiService {

    constructor(private http: HttpClient, @Inject("API_URL") private apiUrl: string, @Inject("FEED_URL") private feedUrl: string) {
    }

    private priceListSubject: BehaviorSubject<any> = new BehaviorSubject<any>({});
    private loadingPriceList:boolean = false; 

    public getTLDInfo(tld: string): Observable<TLDInfo | null> {
        let url = this.apiUrl + "/domain/gettldinfo/";
        let params = new HttpParams().set("tld", tld);
        let options = { params };
        return this.http.get<TLDInfoResponse>(url, options)
            .pipe(
                map(response => {
                        return response.tldinfo
                    }
                )
            );
    }

    searchDomains(domain: string): Observable<any> {
        const url = this.apiUrl + "/domain/searchdomain/"
        const body = new HttpParams()
            .set("domainname[]", domain);
        const headers = new HttpHeaders()
            .set("Content-Type", "application/x-www-form-urlencoded");
        return this.http.post(url, body.toString(), { headers });
    }

    getGroup(group?: string): Observable<any> {
        group = group ? group : "";
        let url = this.apiUrl + "/domain/gettldgroups/"
        let params = { group }
        return this.http.get(url, { params });
    }

    getGroups(groups: string[]): Observable<any> {

        let observables = groups.map(name => {
            return this.getGroup(name)
                .pipe(
                    map(response => response.tldgroups[1]), first()
                )
        });

        return forkJoin(...observables);
    }

    pricelist(): Observable<any> {

        var currentPriceList = this.priceListSubject.getValue();
       
        if(!this.loadingPriceList && Object.entries(currentPriceList).length === 0 && currentPriceList.constructor === Object) {
            let url = `${this.feedUrl}/tldlist`;

            this.loadingPriceList = true;
            this.http.get(url).subscribe(response => {
                this.priceListSubject.next(response);
                this.loadingPriceList = false;
            });
        }

        return this.priceListSubject; 
    }

    newsFeed(language: string): Observable<any> {
        let url = `${this.feedUrl}/news/${language}`;
        return this.http.get(url);
    }

    tldGroups(): Observable<any> {
        let url = `${this.feedUrl}/tldgroups`;
        return this.http.get(url);
    }

    getDomainPrice(tld: string): Observable<any> {
        let url = `${this.apiUrl}/economy/pricelist/?print=0&skiprules=1&pricetypes[]=1&pricetypes[]=4&tldname=${tld}`;
        return this.http.get<any>(url)
            .pipe(
                map(response => response.pricelist ? response.pricelist.domains[tld] : null)
            );
    }

    whois(domain: string): Observable<string | null> {
        let url = this.apiUrl + "/domain/whois/";
        const body = new HttpParams()
            .set("domainname", domain);
        const headers = new HttpHeaders()
            .set("Content-Type", "application/x-www-form-urlencoded");
        const options = { headers }

        return this.http.post<WhoisResponse>(url, body, options)
            .pipe(
                map(response => {
                    if (response.parameters) {
                        return response.parameters.whois
                    } else {
                        return null;
                    }
                })
            );
    }

    getNewTLDs(): Observable<NewTLD[]> {
        type Response = { tlds: NewTLD[] };
        let url = this.feedUrl + "/newtlds";
        return this.http
            .get<Response>(url)
            .pipe(
                map(response => response.tlds)
            );
    }

    addToBasket(basketItem: BasketItem): Observable<any> {
        let url = this.apiUrl + "/order/addtobasket/";
        let anonymousOwenerId = "0";

        const body = new HttpParams()
        .set("item[]", basketItem.item)
        .set("itemaction", basketItem.itemaction)
        .set("itemyear", "1")
        .set("ownerid", anonymousOwenerId);

        const headers = new HttpHeaders()
        .set("Content-Type", "application/x-www-form-urlencoded");

        return this.http.post(url, body.toString(), { headers });
    }

    removeFromBasket(itemid: string): Observable<any> {
        let url = this.apiUrl + "/order/removefrombasket/";

        const body = new HttpParams()
        .set("itemid", itemid)

        const headers = new HttpHeaders()
        .set("Content-Type", "application/x-www-form-urlencoded");

        return this.http.post(url, body.toString(), { headers });
    }

    getBasket(): Observable<any> {
        let url = this.apiUrl + "/order/basket/";
        return this.http.get(url);
    }
}