/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../panelItem/linkPanelItem/linkPanelItem.component.ngfactory";
import * as i2 from "../../panelItem/linkPanelItem/linkPanelItem.component";
import * as i3 from "../../panelList/panelList.component.ngfactory";
import * as i4 from "../../panelList/panelList.component";
import * as i5 from "@angular/common";
import * as i6 from "./contentCategory.component";
import * as i7 from "../../../services/language/language.service";
var styles_ContentCategoryComponent = [];
var RenderType_ContentCategoryComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ContentCategoryComponent, data: {} });
export { RenderType_ContentCategoryComponent as RenderType_ContentCategoryComponent };
function View_ContentCategoryComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "link-panel-item", [], null, null, null, i1.View_LinkPanelItemComponent_0, i1.RenderType_LinkPanelItemComponent)), i0.ɵdid(1, 49152, null, 0, i2.LinkPanelItemComponent, [], { header: [0, "header"], footerText: [1, "footerText"], link: [2, "link"], body: [3, "body"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.customProperties.title; var currVal_1 = _v.context.$implicit.customProperties.linkTitle; var currVal_2 = i0.ɵinlineInterpolate(2, "/", _co.languageService.current, "/", _v.context.$implicit.customProperties.link.url, ""); var currVal_3 = _v.context.$implicit.customProperties.body; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_ContentCategoryComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""])), (_l()(), i0.ɵeld(2, 0, null, null, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 3, "panel-list", [["class", "row same-height gap-3"]], null, null, null, i3.View_PanelListComponent_0, i3.RenderType_PanelListComponent)), i0.ɵdid(4, 49152, null, 0, i4.PanelListComponent, [], null, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_ContentCategoryComponent_1)), i0.ɵdid(6, 802816, null, 0, i5.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = ((_co.contentCategory == null) ? null : _co.contentCategory.panelItems); _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.contentCategory == null) ? null : _co.contentCategory.pageTitle); _ck(_v, 1, 0, currVal_0); var currVal_1 = i0.ɵinlineInterpolate(1, "", ((_co.contentCategory == null) ? null : _co.contentCategory.bodyText), ""); _ck(_v, 2, 0, currVal_1); }); }
export function View_ContentCategoryComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "content-category", [], null, null, null, View_ContentCategoryComponent_0, RenderType_ContentCategoryComponent)), i0.ɵdid(1, 49152, null, 0, i6.ContentCategoryComponent, [i0.ChangeDetectorRef, i7.LanguageService], null, null)], null, null); }
var ContentCategoryComponentNgFactory = i0.ɵccf("content-category", i6.ContentCategoryComponent, View_ContentCategoryComponent_Host_0, {}, {}, []);
export { ContentCategoryComponentNgFactory as ContentCategoryComponentNgFactory };
