/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./internalServerError.component";
var styles_InternalServerErrorComponent = [];
var RenderType_InternalServerErrorComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_InternalServerErrorComponent, data: {} });
export { RenderType_InternalServerErrorComponent as RenderType_InternalServerErrorComponent };
export function View_InternalServerErrorComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "hero error-page"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 9, "div", [["class", "inner text-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["500 Internal Server Error"])), (_l()(), i0.ɵeld(4, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Something went wrong!"])), (_l()(), i0.ɵeld(6, 0, null, null, 4, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Try again, or "])), (_l()(), i0.ɵeld(8, 0, null, null, 1, "a", [["href", "mailto:support@nameisp.com "]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["let us know"])), (_l()(), i0.ɵted(-1, null, [". "]))], null, null); }
export function View_InternalServerErrorComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_InternalServerErrorComponent_0, RenderType_InternalServerErrorComponent)), i0.ɵdid(1, 49152, null, 0, i1.InternalServerErrorComponent, [], null, null)], null, null); }
var InternalServerErrorComponentNgFactory = i0.ɵccf("ng-component", i1.InternalServerErrorComponent, View_InternalServerErrorComponent_Host_0, {}, {}, []);
export { InternalServerErrorComponentNgFactory as InternalServerErrorComponentNgFactory };
