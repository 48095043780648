<a routerLink="{{languageService.current}}/checkout">
    <popover [show]="show" [active]="active">
        <ng-container header>
            <h3>
                <i class="glyph-success text-success"></i>
                <span>{{dictionary?.Checkout_Header_Button_Popup_Title}}</span>
            </h3>
        </ng-container>
        <div body>
            {{dictionary?.Checkout_Header_Button_Popup_Description}}
        </div>
    </popover>
</a>