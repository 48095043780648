var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Router } from "@angular/router";
import * as rxjs from "rxjs";
import { DictionaryService } from "../../services/dictionary.service";
import { LanguageResolver } from "./language.resolver";
import { LanguageService } from "../../services/language/language.service";
import { Location } from "@angular/common";
var DictionaryResolver = /** @class */ (function (_super) {
    __extends(DictionaryResolver, _super);
    function DictionaryResolver(dictionaryService, languageService, router, location) {
        var _this = _super.call(this, languageService, router, location) || this;
        _this.dictionaryService = dictionaryService;
        _this.location = location;
        return _this;
    }
    DictionaryResolver.prototype.resolve = function (route, state) {
        _super.prototype.resolve.call(this, route, state);
        return rxjs.of(this.dictionaryService.getDictionary());
    };
    return DictionaryResolver;
}(LanguageResolver));
export { DictionaryResolver };
