<h2>{{header}}</h2>
<table-component>
    <thead>
        <tr>
            <th>
                {{dictionary?.NEW_TOP_LEVEL_DOMAINS_HEADER_TLD}}
            </th>
            <th>
                {{dictionary?.NEW_TOP_LEVEL_DOMAINS_HEADER_LAUNCH}}
            </th>
        </tr>
    </thead>
    <tbody [ngClass]="{'container-loading': isLoading}">
        <tr *ngFor="let row of list">
            <td>
                <a *ngIf="row.showLink" routerLink="/{{languageService.current}}/domain-names/top-level-domain/{{row.tld}}">
                    {{row.tld_utf8}}
                </a>
                <span *ngIf="!row.showLink">{{row.tld_utf8}}</span>
            </td>
            <td>
                {{row.date}}
            </td>
        </tr>
    </tbody>
</table-component>