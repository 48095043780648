/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./headerTop/headerTop.component.ngfactory";
import * as i2 from "./headerTop/headerTop.component";
import * as i3 from "../../services/language/language.service";
import * as i4 from "../../services/dictionary.service";
import * as i5 from "./headerPrimary/headerPrimary.component.ngfactory";
import * as i6 from "./headerPrimary/headerPrimary.component";
import * as i7 from "@angular/router";
import * as i8 from "./siteHeader.component";
var styles_SiteHeaderComponent = [];
var RenderType_SiteHeaderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SiteHeaderComponent, data: {} });
export { RenderType_SiteHeaderComponent as RenderType_SiteHeaderComponent };
export function View_SiteHeaderComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "site-header"], ["role", "banner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "header-top", [], null, null, null, i1.View_HeaderTopComponent_0, i1.RenderType_HeaderTopComponent)), i0.ɵdid(2, 49152, null, 0, i2.HeaderTopComponent, [i3.LanguageService, i4.DictionaryService, "CP_URL"], null, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "header-primary", [], null, null, null, i5.View_HeaderPrimaryComponent_0, i5.RenderType_HeaderPrimaryComponent)), i0.ɵdid(4, 49152, null, 0, i6.HeaderPrimaryComponent, [i7.Router, i3.LanguageService, i4.DictionaryService], null, null)], null, null); }
export function View_SiteHeaderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "site-header", [], null, null, null, View_SiteHeaderComponent_0, RenderType_SiteHeaderComponent)), i0.ɵdid(1, 49152, null, 0, i8.SiteHeaderComponent, [], null, null)], null, null); }
var SiteHeaderComponentNgFactory = i0.ɵccf("site-header", i8.SiteHeaderComponent, View_SiteHeaderComponent_Host_0, {}, {}, []);
export { SiteHeaderComponentNgFactory as SiteHeaderComponentNgFactory };
