/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "./pageNotFound.component";
import * as i4 from "../../../services/dictionary.service";
import * as i5 from "../../../services/language/language.service";
var styles_PageNotFoundComponent = [];
var RenderType_PageNotFoundComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PageNotFoundComponent, data: {} });
export { RenderType_PageNotFoundComponent as RenderType_PageNotFoundComponent };
export function View_PageNotFoundComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "hero error-page"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 9, "div", [["class", "inner text-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), (_l()(), i0.ɵeld(4, 0, null, null, 0, "p", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 5, "p", [], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 4, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 7).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(7, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵeld(8, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(9, null, ["", ""])), (_l()(), i0.ɵted(-1, null, [" \u2192 "]))], function (_ck, _v) { var _co = _v.component; var currVal_4 = i0.ɵinlineInterpolate(1, "/", _co.languageService.current, ""); _ck(_v, 7, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.dictionary == null) ? null : _co.dictionary.Error_404_Title); _ck(_v, 3, 0, currVal_0); var currVal_1 = ((_co.dictionary == null) ? null : _co.dictionary.Error_404_Description); _ck(_v, 4, 0, currVal_1); var currVal_2 = i0.ɵnov(_v, 7).target; var currVal_3 = i0.ɵnov(_v, 7).href; _ck(_v, 6, 0, currVal_2, currVal_3); var currVal_5 = ((_co.dictionary == null) ? null : _co.dictionary.Error_404_Home_Page_Link); _ck(_v, 9, 0, currVal_5); }); }
export function View_PageNotFoundComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_PageNotFoundComponent_0, RenderType_PageNotFoundComponent)), i0.ɵdid(1, 49152, null, 0, i3.PageNotFoundComponent, [i4.DictionaryService, i1.ActivatedRoute, i2.Location, i5.LanguageService], null, null)], null, null); }
var PageNotFoundComponentNgFactory = i0.ɵccf("ng-component", i3.PageNotFoundComponent, View_PageNotFoundComponent_Host_0, {}, {}, []);
export { PageNotFoundComponentNgFactory as PageNotFoundComponentNgFactory };
