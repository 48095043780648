/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@angular/router";
import * as i3 from "./sideMenu.component";
import * as i4 from "../../services/dictionary.service";
import * as i5 from "../../services/language/language.service";
var styles_SideMenuComponent = [];
var RenderType_SideMenuComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SideMenuComponent, data: {} });
export { RenderType_SideMenuComponent as RenderType_SideMenuComponent };
function View_SideMenuComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "a", [["class", "list-group-item"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(2, { "active": 0 }), i0.ɵdid(3, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i1.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵted(4, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = "list-group-item"; var currVal_3 = _ck(_v, 2, 0, (((_v.context.$implicit == null) ? null : _v.context.$implicit.id) === _co.activeMenuItemId)); _ck(_v, 1, 0, currVal_2, currVal_3); var currVal_4 = i0.ɵinlineInterpolate(1, "", ((_v.context.$implicit == null) ? null : _v.context.$implicit.link), ""); _ck(_v, 3, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 3).target; var currVal_1 = i0.ɵnov(_v, 3).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_5 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.name); _ck(_v, 4, 0, currVal_5); }); }
export function View_SideMenuComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "panel-sidebar col-sm-4"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 10, "nav", [["class", "list-group"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 6, "div", [["class", "list-group-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "h4", [["class", "list-group-item-heading"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), (_l()(), i0.ɵeld(5, 0, null, null, 3, "button", [["class", "list-group-nav-toggle"], ["data-target", "#nav-list"], ["data-toggle", "collapse"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 0, "i", [["class", "fa fa-bars"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "span", [["class", "sr-only"]], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, ["", ""])), (_l()(), i0.ɵeld(9, 0, null, null, 2, "div", [["class", "list-group-nav-collapse collapse"], ["id", "nav-list"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SideMenuComponent_1)), i0.ɵdid(11, 802816, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.links; _ck(_v, 11, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.title == null) ? null : _co.title.name); _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.title; _ck(_v, 8, 0, currVal_1); }); }
export function View_SideMenuComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "side-menu", [], null, null, null, View_SideMenuComponent_0, RenderType_SideMenuComponent)), i0.ɵdid(1, 49152, null, 0, i3.SideMenuComponent, [i4.DictionaryService, i5.LanguageService], null, null)], null, null); }
var SideMenuComponentNgFactory = i0.ɵccf("side-menu", i3.SideMenuComponent, View_SideMenuComponent_Host_0, { title: "title", links: "links", activeMenuItemId: "activeMenuItemId" }, {}, []);
export { SideMenuComponentNgFactory as SideMenuComponentNgFactory };
