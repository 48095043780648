import { Inject, Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { Observable } from 'rxjs'
import { Response } from './response.datatype';
import { Page } from '../page/page.datatype';
import { LanguageService } from '../language/language.service';
import { MenuItem } from '../menu/menuItem.data';
import { UrlService } from '../url.service';
import { Country } from '../../shared/datatypes/Country.data';
import { map, mergeMap } from 'rxjs/operators';


export type Translations = any;

@Injectable()
export class CMSService {
    private cmsBaseUrl: string;
    private cmsContentUrl: string;

    constructor(private http: HttpClient,
                @Inject("CMS_URL") public cmsUrl: string,
                @Inject("DICTIONARY_URL") public DICTIONARY_URL: string,
                private languageService: LanguageService,
                private url: UrlService) {
        this.cmsBaseUrl = cmsUrl + "/";
        this.cmsContentUrl = this.cmsBaseUrl + "content/";
    }

    getDictionary(): Observable<{ [index: string]: string }> {
        return this.languageService.getLanguage()
            .pipe(
                mergeMap(language => {
                    let params = new HttpParams().set("language", language);
                    let options = { params };
                    return this.http.get<Translations>(this.DICTIONARY_URL, options).pipe(
                        map(response => response.data)
                    );
                })
            );
    }

    public getMainMenu(): Observable<Response<MenuItem[]>> {
        return this.languageService.getLanguage()
            .pipe(
                mergeMap(language => {
                    let params = new HttpParams().set("language", language);
                    let options = { params };
                    return this.http.get<Response<MenuItem[]>>(this.cmsContentUrl + "mainmenu", options);
                })
            );
    }

    public getCountryInfo(tld: string, language?: string): Observable<Country> {
        return this.languageService.getLanguage()
            .pipe(
                mergeMap(language => {
                    let params = new HttpParams()
                        .set("tld", tld)
                        .set("language", language as any);
                    let options = { params };

                    return this.http.get<Response<Country>>(this.cmsContentUrl + "countryinfo", options)
                }),
                map(response => response.data)
            );
    }

    public isValidTLD(tld: string): Observable<any> {
        var options = { params: { tld } }
        return this.http.get(this.cmsContentUrl + "validtld", options);
    }

    public getValidTLDList(tlds: string[]): Observable<any> {
        var options = { params: { tld: tlds } }
        return this.http.get(this.cmsContentUrl + "validtldlist", options);
    }

    public getPages<T>(id?: any, url?: any, custom?: any): Observable<Response<Page<T>[]>> {
        return this.languageService.getLanguage()
            .pipe(
                mergeMap(language => {
                    let params = new HttpParams()
                        .set("id", id)
                        .set("url", url)
                        .set("language", language)
                        .set("custom", JSON.stringify(custom));
                    let options = { params };
                    return this.http.get<Response<Page<T>[]>>(this.cmsContentUrl + "page", options);
                })
            );
    }
}