import { Router, RouterStateSnapshot } from "@angular/router";
import { Injectable } from "@angular/core";
import * as rxjs from "rxjs";
import { Observable } from "rxjs";
import { DictionaryService } from "../../services/dictionary.service";
import { LanguageResolver } from "./language.resolver";
import { ActivatedRouteSnapshot } from "@angular/router/src/router_state";
import { LanguageService } from "../../services/language/language.service";
import { Location } from "@angular/common";

@Injectable()
export class DictionaryResolver extends LanguageResolver {

    constructor(private dictionaryService: DictionaryService, languageService: LanguageService, router: Router, protected location: Location) {
        super(languageService, router, location);
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        super.resolve(route, state);
        return rxjs.of(this.dictionaryService.getDictionary());
    }
}