import { Injectable } from "@angular/core";
import { LanguageResolver } from "../../../shared/resolvers/language.resolver";
import { LanguageService } from "../../../services/language/language.service";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { CMSService } from "../../../services/CMS/cms.service";
import { PageService } from "../../../services/page/page.service";
import { Location } from "@angular/common";
import { tap } from "rxjs/operators";


@Injectable()
export class TopLevelDomainResolver extends LanguageResolver {
    constructor(languageService: LanguageService, router: Router, protected location: Location, private cms: CMSService, private pageService: PageService) {
        super(languageService, router, location);
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        super.resolve(route, state);

        return this.cms.isValidTLD(route.params["domain"])
            .pipe(
                tap(response => {
                    if (!response.valid) {
                        this.router.navigate([this.languageService.current, "pagenotfound"], { queryParams: { url: state.url } });
                    }
                })
            )
    }
}