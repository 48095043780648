var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { TableSearchComponent } from "./tableSearch.component";
var TableSearchTldComponent = /** @class */ (function (_super) {
    __extends(TableSearchTldComponent, _super);
    function TableSearchTldComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TableSearchTldComponent.prototype.filterFunction = function (search) {
        return function (list) {
            if (search === "") {
                return list;
            }
            else {
                return list
                    .filter(function (elem) {
                    return search.indexOf(".") === 0
                        ? ("." + elem.name).indexOf(search) >= 0
                        : elem.name.indexOf(search) >= 0;
                })
                    .sort(function (a, b) {
                    var s = search.replace(/^\./, "");
                    if (a.name === b.name)
                        return 0;
                    // Exact match
                    if (a.name === s)
                        return -1;
                    if (b.name === s)
                        return 1;
                    // Exact match in any part, starting from the right
                    var aParts = a.name.split(".").reverse();
                    var bParts = b.name.split(".").reverse();
                    var al = aParts.length, bl = bParts.length;
                    for (var i = 0; i < Math.max(al, bl); i++) {
                        var aExact = (i < al && aParts[i] === s);
                        var bExact = (i < bl && bParts[i] === s);
                        if (aExact !== bExact)
                            return aExact ? -1 : 1;
                    }
                    // Sort by part name alphabetically
                    for (var i = 0; i < Math.max(al, bl); i++) {
                        var aa = i < al ? aParts[i] : "";
                        var bb = i < bl ? bParts[i] : "";
                        var compare = aa.localeCompare(bb, { sensitivity: 'base' });
                        if (compare !== 0)
                            return compare;
                    }
                    return 0;
                });
            }
        };
    };
    return TableSearchTldComponent;
}(TableSearchComponent));
export { TableSearchTldComponent };
