import * as logo from '../assets/images/nameisp_logo_red.png';
import * as visa from '../assets/images/paymentImages/visa.png';
import * as mastercard from '../assets/images/paymentImages/mastercard.png';
import * as amex from '../assets/images/paymentImages/amex.png';
import * as paypal from '../assets/images/paymentImages/PP_logo_h.png';
import * as swish from '../assets/images/paymentImages/swish-horizontal-logo.png';
import * as bankgirot from '../assets/images/paymentImages/bankgirot_rod_rgb.png';
import * as icann from '../assets/images/icann-accredited-registrar.png';
import * as phoneIcon from '../assets/images/icons/phone-icon.png';

export const Images = {
    logo,
    visa,
    mastercard,
    amex,
    paypal,
    swish,
    bankgirot,
    icann,
    phoneIcon
}