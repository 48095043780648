<div class="language-picker" (clickOutside)="clickOutside()">
    <a (click)="ToggleLocalizationMenu()">
        <i class="fa fa-globe"></i>
        <span>{{selectedLanguage?.code}} / {{ selectedCurrency.code }}</span>
    </a>
    <div class="nationality-menu" [class.show]="showLocalizationMenu">
        <i class="fa fa-sort-desc" aria-hidden="true">
        </i>
        <div class="language-list" >
            <p>{{dictionary?.Choose_Language}}</p>
            <ul>
                <li *ngFor="let language of languages">
                    <i class="fa fa-check" [class.checked]="language.checked" aria-hidden="true"></i>
                    <a (click)="ToogleChoosenLanguage(language)">{{ language.name }}</a>
                </li>
            </ul>
        </div>
        <div class="currency-list">
            <p>{{dictionary?.Choose_Currency}}</p>
            <ul>
                <li [class.checked]="currency.checked" *ngFor="let currency of currencies">
                    <i class="fa fa-check" [class.checked]="currency.checked" aria-hidden="true"></i>
                    <a (click)="ToggleChosenCurrency(currency)">{{ currency.code }}  {{ currency.name }}</a>
                </li>
            </ul>
        </div>
    </div>
</div>