var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { LanguageResolver } from "../../shared/resolvers/language.resolver";
import { LanguageService } from "../../services/language/language.service";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
var SearchDomainsResolver = /** @class */ (function (_super) {
    __extends(SearchDomainsResolver, _super);
    function SearchDomainsResolver(languageService, router, location) {
        var _this = _super.call(this, languageService, router, location) || this;
        _this.location = location;
        return _this;
    }
    SearchDomainsResolver.prototype.resolve = function (route, state) {
        _super.prototype.resolve.call(this, route, state);
    };
    return SearchDomainsResolver;
}(LanguageResolver));
export { SearchDomainsResolver };
