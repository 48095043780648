<div id="site-header-top" class="site-header-top initial-top-menu  topbar-size-controller">
    <div class="container">
        <div class="row header-top-bar">
            <div class="col-xs-4 col-sm-2 col-md-3 top-header-logo">
                <a routerLink="/{{languageService.current}}" class="logo" alt="NameISP logo">Logo</a>
            </div>

            <div class="col-xs-8 col-sm-10 col-md-9 customer-panel">
                <span class="icann-info">
                    <p>{{dictionary?.Site_Header_Secondary_Not_Authenticated_NameISP_Version}}</p>
                    <p>{{dictionary?.Site_Header_Secondary_Not_Authenticated_NameISP_Description}}</p>
                </span>
                <div class="customer-panel-list">
                    <ul>
                        <li>
                            <a class="customer-support" routerLink="/{{languageService.current}}/customer-service">
                                <i class="fa fa-comment"></i>
                                <span>{{dictionary?.CustomerService_Title}}</span>
                            </a>
                        </li>
                        <li>
                            <a class="create-account" href="{{cpUrl}}/createaccount">
                                <i class="fa fa-user"></i>
                                <span>{{dictionary?.Site_Header_Create_Account}}</span>
                            </a>
                        </li>
                        <li>
                            <a class="customer-account" href="{{cpUrl}}/login">
                                <i class="fa fa-briefcase"></i>
                                <span>{{dictionary?.Site_Header_Control_Panel_Title}}</span>
                            </a>
                        </li>
                    </ul>
                </div>

                <localization-menu></localization-menu>
            </div>
        </div>
    </div>
</div>