/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "ngx-bootstrap/tooltip/tooltip.directive";
import * as i2 from "ngx-bootstrap/component-loader/component-loader.factory";
import * as i3 from "ngx-bootstrap/tooltip/tooltip.config";
import * as i4 from "@angular/common";
import * as i5 from "../addToCartButton/addToCartButton.component.ngfactory";
import * as i6 from "../addToCartButton/addToCartButton.component";
import * as i7 from "../../../services/dictionary.service";
import * as i8 from "../../../services/basket/basket.service";
import * as i9 from "../../../services/modal.service";
import * as i10 from "./searchDomainsHeader.component";
import * as i11 from "../../../services/currency.service";
var styles_SearchDomainsHeader = [];
var RenderType_SearchDomainsHeader = i0.ɵcrt({ encapsulation: 2, styles: styles_SearchDomainsHeader, data: {} });
export { RenderType_SearchDomainsHeader as RenderType_SearchDomainsHeader };
function View_SearchDomainsHeader_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "whois btn-group hidden-xs hidden-sm"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 16777216, null, null, 4, "button", [["class", "btn btn-link btn-lg"], ["tooltip", "Whois Lookup"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openWhoisModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 212992, null, 0, i1.TooltipDirective, [i0.ViewContainerRef, i0.Renderer2, i0.ElementRef, i2.ComponentLoaderFactory, i3.TooltipConfig], { tooltip: [0, "tooltip"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 0, "i", [["class", "glyph-whois"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "span", [["class", "hidden-md hidden-lg"]], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", ""]))], function (_ck, _v) { var currVal_0 = "Whois Lookup"; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.dictionary == null) ? null : _co.dictionary.Search_Domains_Whois); _ck(_v, 5, 0, currVal_1); }); }
function View_SearchDomainsHeader_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["class", "search-result-single-controls"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "span", [["class", "price"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", " ", ""])), i0.ɵppd(3, 2), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(5, 0, null, null, 4, "div", [["class", "btn-group"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SearchDomainsHeader_2)), i0.ɵdid(7, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(8, 0, null, null, 1, "add-to-cart-button", [], null, null, null, i5.View_AddToCartButton_0, i5.RenderType_AddToCartButton)), i0.ɵdid(9, 49152, null, 0, i6.AddToCartButton, [i7.DictionaryService, i8.BasketService, i9.ModalService], { domain: [0, "domain"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.domain.available === false); _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.domain; _ck(_v, 9, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i0.ɵnov(_v.parent, 0), ((_co.domain == null) ? null : _co.domain.price), "1.2-2")); var currVal_1 = i0.ɵunv(_v, 2, 1, i0.ɵnov(_v, 4).transform(_co.currency.current)); _ck(_v, 2, 0, currVal_0, currVal_1); }); }
export function View_SearchDomainsHeader_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i4.DecimalPipe, [i0.LOCALE_ID]), (_l()(), i0.ɵeld(1, 0, null, null, 10, "div", [["class", "panel"]], null, null, null, null, null)), i0.ɵdid(2, 278528, null, 0, i4.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 0, "header", [["class", "panel-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 7, "div", [["class", "panel-body search-result-single"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 4, "h1", [], null, null, null, null, null)), i0.ɵdid(6, 278528, null, 0, i4.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), (_l()(), i0.ɵeld(7, 0, null, null, 1, "i", [], null, null, null, null, null)), i0.ɵdid(8, 278528, null, 0, i4.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), (_l()(), i0.ɵted(9, null, [" ", " "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SearchDomainsHeader_1)), i0.ɵdid(11, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "panel"; var currVal_1 = _co.getPanelClass(_co.domain); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.getTextClass(_co.domain); _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.getGlyph(_co.domain); _ck(_v, 8, 0, currVal_3); var currVal_5 = (_co.domain && !_co.domain.invalid); _ck(_v, 11, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.domainText(); _ck(_v, 9, 0, currVal_4); }); }
export function View_SearchDomainsHeader_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "search-domains-header", [], null, null, null, View_SearchDomainsHeader_0, RenderType_SearchDomainsHeader)), i0.ɵdid(1, 49152, null, 0, i10.SearchDomainsHeader, [i7.DictionaryService, i11.CurrencyService, i9.ModalService], null, null)], null, null); }
var SearchDomainsHeaderNgFactory = i0.ɵccf("search-domains-header", i10.SearchDomainsHeader, View_SearchDomainsHeader_Host_0, { domain: "domain" }, {}, []);
export { SearchDomainsHeaderNgFactory as SearchDomainsHeaderNgFactory };
